export const getDateValueOrUndefined = (value: Date | string | null | undefined): Date | undefined => {
    if (value === null) {
        return undefined;
    }

    if (typeof value === 'string') {
        return new Date(value);
    }

    return value;
};

export const getStringValueOrEmpty = (value: string | null | undefined): string => {
    return value === null || value === undefined ? '' : value;
};

export const removeItem = <T>(array: T[], itemToRemove: T): T[] => array.filter(item => item !== itemToRemove);

/*
 * Returns an array of object. Duplicates will be removed by the given key.
 * */
export const uniqueByKey = <T>(array: T[], key: (item: T) => any): T[] => {
    let seen = new Set();
    return array.filter(item => {
        let k = key(item);
        return seen.has(k) ? false : seen.add(k);
    });
};

export const sortAlphabetical = (a: string, b: string): number => (a > b ? 1 : -1);
