import {Person as ReducerPerson, Personen} from './PersonenReducer';
import {MailAdresse, PdsPerson, PersonDto, Typ as Mailtyp} from '../util/fetch/personen/PersonDto';
import {OfferEngineAngebotDto, Rollentyp} from '../util/fetch/offerengine/OfferEngineAngebotDto';
import {IAppState} from '../app/IAppState';
import {Dispatch} from 'redux';
import {
    AddRolleRequestDto,
    AddRolleResponseDto,
    alleRollenBisher,
    ChangeRolleRequestDto,
    DeletePersonRequestDto,
    Rolleninhaber
} from '../util/fetch/personensuche/PersonRollenDto';
import {
    addRolleHttpRequest,
    deletePersonHttpRequest,
    deleteRolleVPHttpRequest,
    updateMailAdressenHttpRequest
} from '../util/fetch/personen/PersonenController';
import {FrontendResponse} from '../util/fetch/client/FrontendResponse';
import {onFulfilledStoreOffer, storeOfferSync} from '../app/AppAction';
import {onRejectedStoreException} from '../exceptions/ExceptionsAction';
import {UpdateMailAdressenRequestDto} from '../util/fetch/personen/UpdateMailAdressenRequestDto';
import {mapStatePersonToPdsPerson} from './PersonenMapper';
import {maxNumberVPErreicht} from '../util/PersonenHelper';
import {setRemainingVPsMessageAsync} from '../personensuche/PersonensucheAction';
import {updateVersicherungsnehmerEmailSync} from '../bankverbindung/BankverbindungAction';

export const LADE_PERSONEN: string = 'LADE_PERSONEN';
export type LADE_PERSONEN = typeof LADE_PERSONEN;
export const PERSON_SPEICHERN: string = 'PERSON_SPEICHERN';
export type PERSON_SPEICHERN = typeof PERSON_SPEICHERN;
export const ROLLE_HINZUFUEGEN: string = 'ROLLE_HINZUFUEGEN';
export type ROLLE_HINZUFUEGEN = typeof ROLLE_HINZUFUEGEN;
export const ROLLE_LOESCHEN: string = 'ROLLE_LOESCHEN';
export type ROLLE_LOESCHEN = typeof ROLLE_LOESCHEN;
export const PERSON_LOESCHEN: string = 'PERSON_LOESCHEN';
export type PERSON_LOESCHEN = typeof PERSON_LOESCHEN;
export const ZEIGE_LOADINGSPINNER_PERSON: string = 'ZEIGE_LOADINGSPINNER_PERSON';
export type ZEIGE_LOADINGSPINNER_PERSON = typeof ZEIGE_LOADINGSPINNER_PERSON;

export const ZEIGE_MAXNUMBER_VP: string = 'ZEIGE_MAXNUMBER_VP';

export interface ShowPersonLoadingSpinnerAction {
    type: ZEIGE_LOADINGSPINNER_PERSON;
    showSpinner: boolean;
}

export const showPersonLoadingSpinnerSync = (showSpinner: boolean): ShowPersonLoadingSpinnerAction => {
    return {
        type: ZEIGE_LOADINGSPINNER_PERSON,
        showSpinner: showSpinner
    };
};

export interface LoadPersonsAction {
    type: LADE_PERSONEN;
    personen: PdsPerson[];
}

export const loadPersonsSync = (personen: PdsPerson[]): LoadPersonsAction => {
    return {
        type: LADE_PERSONEN,
        personen: personen
    };
};

export interface SpeicherPersonAction {
    type: PERSON_SPEICHERN;
    person: PdsPerson;
}

export const speicherPersonSync = (person: PdsPerson): SpeicherPersonAction => {
    return {
        type: PERSON_SPEICHERN,
        person: person
    };
};

export interface AddRolleAction {
    type: ROLLE_HINZUFUEGEN;
    payload: {
        pdsId: string;
        rollentyp: Rollentyp;
    };
}

export const addRolleSync = (pdsId: string, rollentyp: Rollentyp): AddRolleAction => {
    return {
        type: ROLLE_HINZUFUEGEN,
        payload: {
            pdsId: pdsId,
            rollentyp: rollentyp
        }
    };
};

export interface DeleteRolleAction {
    type: ROLLE_LOESCHEN;
    payload: {
        pdsId: string;
        rollentyp: Rollentyp;
    };
}

export const deleteRolleSync = (pdsId: string, rollentyp: Rollentyp): DeleteRolleAction => {
    return {
        type: ROLLE_LOESCHEN,
        payload: {
            pdsId: pdsId,
            rollentyp: rollentyp
        }
    };
};

export interface DeletePersonAction {
    type: PERSON_LOESCHEN;
    pdsId: string;
}

export const deletePersonSync = (pdsId: string): DeletePersonAction => {
    return {
        type: PERSON_LOESCHEN,
        pdsId: pdsId
    };
};

export interface ShowMaxNumberVPAction {
    type: typeof ZEIGE_MAXNUMBER_VP;
    displayModal: boolean;
}

export const showMaxNumberVPSync = (display: boolean): ShowMaxNumberVPAction => {
    return {
        type: ZEIGE_MAXNUMBER_VP,
        displayModal: display
    };
};

export const addRolleAsync = (personToAddRolle: ReducerPerson, rolleNeu: Rollentyp) => {
    return (dispatch: Dispatch, getState: () => IAppState) => {
        const businessId = getState().basisdaten.businessId;
        const personen: Personen = getState().personen.personen;

        const rollenBisher: Rolleninhaber[] = alleRollenBisher(personen);
        const pdsPerson: PdsPerson = mapStatePersonToPdsPerson(personToAddRolle, businessId);

        if (rolleNeu === Rollentyp.VERSICHERTE_PERSON && maxNumberVPErreicht(getState().personen)) {
            dispatch(showMaxNumberVPSync(true));
            return;
        }

        let waitForResponse: NodeJS.Timeout = setTimeout(() => {
            dispatch(showPersonLoadingSpinnerSync(true));
        }, 300);

        const request: AddRolleRequestDto = {
            rolleninhaberNeu: {
                pdsId: personToAddRolle.pdsId,
                rollentyp: rolleNeu,
                geburtsdatum: personToAddRolle.geburtsdatum
            },
            alleRollenBisher: rollenBisher,
            pdsPerson: pdsPerson
        };

        if (rolleNeu === Rollentyp.VERSICHERUNGSNEHMER) {
            dispatch(addRolleSync(personToAddRolle.pdsId, Rollentyp.VERSICHERUNGSNEHMER));
            const versicherungsnehmerAlt = rollenBisher.find(rolleninhaber => rolleninhaber.rollentyp === Rollentyp.VERSICHERUNGSNEHMER);
            request.rolleninhaberAlt = versicherungsnehmerAlt;
            dispatch(deleteRolleSync(versicherungsnehmerAlt!.pdsId, Rollentyp.VERSICHERUNGSNEHMER));
        }

        return addRolleHttpRequest(businessId, request, dispatch)
            .then((response: FrontendResponse<AddRolleResponseDto>) => {
                clearTimeout(waitForResponse);
                if (response.payload && response.payload.angebot) {
                    const newPerson: PdsPerson = response.payload.pdsPerson;
                    if (newPerson) {
                        dispatch(speicherPersonSync(newPerson));
                    }
                    //clearTimeout(waitForResponse);
                    dispatch(storeOfferSync(response.payload.angebot));
                    //@ts-ignore
                    dispatch(setRemainingVPsMessageAsync());
                }
            })
            .catch((e: Error) => {
                clearTimeout(waitForResponse);
                onRejectedStoreException(e, dispatch, 'PersonenAction.ts -> addRolleAsync');
            })
            .finally(() => {
                dispatch(showPersonLoadingSpinnerSync(false));
            });
    };
};

export const deleteRolleVPAsync = (personPdsIdToDelete: string) => {
    return (dispatch: Dispatch, getState: () => IAppState) => {
        const personen: Personen = getState().personen.personen;

        if (!personen[personPdsIdToDelete].rollen.some(rolle => rolle === Rollentyp.VERSICHERTE_PERSON)) {
            return;
        }

        let waitForResponse: NodeJS.Timeout = setTimeout(() => {
            dispatch(showPersonLoadingSpinnerSync(true));
        }, 300);

        const request: ChangeRolleRequestDto = {
            rolleninhaberAlt: {
                pdsId: personPdsIdToDelete,
                rollentyp: Rollentyp.VERSICHERTE_PERSON,
                geburtsdatum: personen[personPdsIdToDelete].geburtsdatum
            },
            alleRollenBisher: alleRollenBisher(personen)
        };

        dispatch(deleteRolleSync(personPdsIdToDelete, Rollentyp.VERSICHERTE_PERSON));
        //@ts-ignore
        dispatch(setRemainingVPsMessageAsync());

        return deleteRolleVPHttpRequest(getState().basisdaten.businessId, request, dispatch)
            .then((response: FrontendResponse<OfferEngineAngebotDto>) => {
                clearTimeout(waitForResponse);
                onFulfilledStoreOffer(response, dispatch);
            })
            .catch((e: Error) => {
                clearTimeout(waitForResponse);
                onRejectedStoreException(e, dispatch, 'PersonenAction.ts -> deleteRolleVPAsync');
            })
            .finally(() => {
                dispatch(showPersonLoadingSpinnerSync(false));
            });
    };
};

export const deletePersonAsync = (personToDelete: ReducerPerson) => {
    return (dispatch: Dispatch, getState: () => IAppState) => {
        if (personToDelete.rollen && !personToDelete.rollen.includes(Rollentyp.VERSICHERUNGSNEHMER)) {
            let waitForResponse: NodeJS.Timeout = setTimeout(() => {
                dispatch(showPersonLoadingSpinnerSync(true));
            }, 300);
            // personToDelete aus dem ReduxStore löschen
            dispatch(deletePersonSync(personToDelete.pdsId));
            //@ts-ignore
            dispatch(setRemainingVPsMessageAsync());

            const request: DeletePersonRequestDto = {
                pdsId: personToDelete.pdsId,
                alleRollenBisher: alleRollenBisher(getState().personen.personen)
            };

            return deletePersonHttpRequest(getState().basisdaten.businessId, request, dispatch)
                .then((response: FrontendResponse<OfferEngineAngebotDto>) => {
                    clearTimeout(waitForResponse);
                    onFulfilledStoreOffer(response, dispatch);
                })
                .catch((e: Error) => {
                    clearTimeout(waitForResponse);
                    onRejectedStoreException(e, dispatch, 'PersonenAction.ts -> deletePersonAsync');
                })
                .finally(() => {
                    dispatch(showPersonLoadingSpinnerSync(false));
                });
        }
    };
};

export const updateMailAdresseAsync = (versicherungsnehmer: ReducerPerson, mailAdresse: string) => {
    return (dispatch: Dispatch, getState: () => IAppState) => {
        if (versicherungsnehmer) {
            const mailAdressen: MailAdresse[] = versicherungsnehmer.mailAdressen;
            mailAdressen.push({
                adresse: mailAdresse,
                typ: Mailtyp.privat
            });
            const request: UpdateMailAdressenRequestDto = {
                pdsId: versicherungsnehmer.pdsId,
                mailAdressen: mailAdressen
            };
            return updateMailAdressenHttpRequest(getState().basisdaten.businessId, request, dispatch)
                .then((response: FrontendResponse<PersonDto>) => {
                    if(response.payload){
                        dispatch(speicherPersonSync(response.payload.pdsPerson));
                        dispatch(updateVersicherungsnehmerEmailSync(response.payload.pdsPerson.mailAdressen));
                    }
                })
                .catch(e => onRejectedStoreException(e, dispatch, 'PersonenAction.ts -> updateMailAdresseAsync'));
        }
    };
};
