import React from 'react';
import { IAppState } from '../app/IAppState';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import InputRow from '@eg/elements/InputRow';
import Button from '@eg/elements/Button';
import SearchIcon from '@eg/elements/components/Icons/SearchIcon';
import { enterKundennummerSync, getParisKundeAsync, selectKundeAsync, showAddressIsInvalidSync } from './PersonensucheAction';
import { InputEvent, InputKeyboardEvent, UIMouseEvent } from '../util/UiEventTypes';
import { Adresse, ParisKunde } from '../util/fetch/personensuche/PersonensucheDto';
import Card from '@eg/elements/Card';
import { isEmpty } from '../util/validate';
import FormRow from '@eg/elements/FormRow';
import LoaderIcon from '@eg/elements/components/Icons/LoaderIcon';
import Modal from '@eg/elements/Modal';
import Details from '@eg/elements/Details';
import MessageBox from '@eg/elements/MessageBox';
import Benefit from '@eg/elements/Benefit';
import { PERSONENSUCHE_BUTTON_PERSON, PERSONENSUCHE_BUTTON_SUCHEN, PERSONENSUCHE_INPUT_KUNDENNUMMER } from '../util/RanorexIds';
import { openTaxModal } from '../taxmodal/actions';

interface PersonensuchePresentationProps {
    businessId: string;
    kundennummer: string;
    showResponse: boolean;
    kunde: ParisKunde | null;
    errormessage: string;
    showPersonIsLoadingSpinner: boolean;
    showPersonIsSavingSpinner: boolean;
    showAddressIsInvalid: boolean;
    addressIsInvalidMessage: string;
    oberflaecheSperren: boolean;
    remainingVpsMessage: string;
    handleEnterKundennummer: (kundennummer: string) => void;
    handleSubmitSearchParisKunde: (businessId: string, kundennummer: string) => void;
    handleSelectKunde: (businessId: string, kunde: ParisKunde) => void;
    handleResetAddressIsInvalid: (e: UIMouseEvent | undefined) => void;
    handleOpenTaxModal: () => void;
}

export class PersonensuchePresentation extends React.Component<PersonensuchePresentationProps, {}> {
    render() {
        const {
            businessId,
            kundennummer,
            showResponse,
            kunde,
            errormessage,
            showPersonIsLoadingSpinner,
            showPersonIsSavingSpinner,
            showAddressIsInvalid,
            addressIsInvalidMessage,
            oberflaecheSperren,
            remainingVpsMessage,
            handleEnterKundennummer,
            handleSubmitSearchParisKunde,
            handleResetAddressIsInvalid,
            handleOpenTaxModal
        } = this.props;

        return (
            <Details summary={<h2 className="esc_h4">Personensuche</h2>} isInitiallyOpen={true}>
                {!oberflaecheSperren && (
                    <MessageBox type="info" className="personensuche-messageBox">
                        <Benefit>{remainingVpsMessage}</Benefit>
                    </MessageBox>
                )}
                <InputRow
                    label="Kundennummer"
                    value={kundennummer}
                    disabled={oberflaecheSperren}
                    maxLength={8}
                    onChange={(e: InputEvent) => handleEnterKundennummer(e.currentTarget.value)}
                    onKeyPress={(e: InputKeyboardEvent) => e.key === 'Enter' && !isEmpty(e.currentTarget.value) && handleSubmitSearchParisKunde(businessId, e.currentTarget.value)}
                    className="personensuche"
                    ranorex-id={PERSONENSUCHE_INPUT_KUNDENNUMMER}
                />
                <Button
                    variant="primary"
                    iconLeft={SearchIcon}
                    disabled={isEmpty(kundennummer) || oberflaecheSperren}
                    onClick={() => handleSubmitSearchParisKunde(businessId, kundennummer)}
                    ranorex-id={PERSONENSUCHE_BUTTON_SUCHEN}
                >
                    Partner suchen
                </Button>
                {showPersonIsLoadingSpinner && (
                    <FormRow label={'Paris-Kunde wird gesucht'}>
                        <LoaderIcon
                            spinning
                            width={40}
                            height={40}
                            style={{
                                marginTop: 5
                            }}
                        />
                    </FormRow>
                )}
                {showResponse &&
                    (kunde ? (
                        <div className="personensuche-personenanzeige">
                            <Button
                                disabled={oberflaecheSperren}
                                onClick={() => handleOpenTaxModal()}
                                loading={showPersonIsSavingSpinner}
                                autoFocus={true}
                                ranorex-id={PERSONENSUCHE_BUTTON_PERSON}
                            >
                                <div>
                                    <span>
                                        <b>
                                            {kunde.liste[0].person[0].vorname} {kunde.liste[0].person[0].nachname}
                                        </b>
                                    </span>
                                    <span className="float-right">{kunde.kundennummer}</span>
                                </div>
                                <div>
                                    <small>
                                        {getAdresse(kunde).strasse} {getAdresse(kunde).hausnummer} {getAdresse(kunde).hausnummerzusatz}
                                        <br />
                                        {getAdresse(kunde).postleitzahl} {getAdresse(kunde).ort}
                                    </small>
                                </div>
                            </Button>
                        </div>
                    ) : (
                        <div>
                            <Card
                                padding="small"
                                style={{
                                    color: '#bf1528',
                                    marginTop: 20
                                }}
                                accentColor="#bf1528"
                            >
                                <div
                                    style={{
                                        paddingLeft: 20
                                    }}
                                >
                                    {errormessage}
                                </div>
                            </Card>
                        </div>
                    ))}
                <Modal open={showAddressIsInvalid} dismissible backdropDismissesModal onDismiss={handleResetAddressIsInvalid}>
                    {addressIsInvalidMessage && addressIsInvalidMessage.split('\n').map((line: string, index: number) => <p key={line ? line : index}>{line}</p>)}
                </Modal>
            </Details>
        );
    }
}

const getAdresse = (kunde: ParisKunde): Adresse => {
    return kunde.liste[0].adressen.find((adresse: Adresse) => adresse.hauptadresse) || kunde.liste[0].adressen[0];
};

const mapStateToProps = (state: IAppState) => ({
    businessId: state.basisdaten.businessId,
    kundennummer: state.personensuche.kundennummer,
    showResponse: state.personensuche.showResponse,
    kunde: state.personensuche.kunde,
    errormessage: state.personensuche.errormessage,
    showPersonIsLoadingSpinner: state.personensuche.showPersonIsLoadingSpinner,
    showPersonIsSavingSpinner: state.personensuche.showPersonIsSavingSpinner,
    showAddressIsInvalid: state.personensuche.showAddressIsInvalid,
    addressIsInvalidMessage: state.personensuche.addressIsInvalidMessage,
    oberflaecheSperren: state.abschluss.abschlussErfolgreich,
    remainingVpsMessage: state.personensuche.remainingVPsMessage
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    handleEnterKundennummer: (kundennummer: string) => {
        dispatch(enterKundennummerSync(kundennummer));
    },
    handleSubmitSearchParisKunde: (businessId: string, kundennummer: string) => {
        //@ts-ignore
        dispatch(getParisKundeAsync(businessId, kundennummer));
    },
    handleSelectKunde: (businessId: string, kunde: ParisKunde) => {
        //@ts-ignore
        dispatch(selectKundeAsync(businessId, kunde));
    },
    handleResetAddressIsInvalid: (e: UIMouseEvent | undefined) => {
        //@ts-ignore
        dispatch(showAddressIsInvalidSync(false));
    },
    handleOpenTaxModal: () => {
        dispatch(openTaxModal());
    }
});

export const Personensuche = connect(mapStateToProps, mapDispatchToProps)(PersonensuchePresentation);
