import { AnyAction } from 'redux';
import { TAX_MODAL_OPEN } from './actions';

export interface TaxModalState {
    isOpen: boolean;
}

const initState: TaxModalState = {
    isOpen: false
};

const taxModalReducer = (state = initState, action: AnyAction) => {
    const { type } = action;
    const newState = { ...state };
    newState.isOpen = type === TAX_MODAL_OPEN;
    return newState;
};

export default taxModalReducer;
