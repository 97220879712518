import React from 'react';

export class ScrollComponent extends React.Component<{}, {}> {
    componentDidMount(): void {
        window.scrollTo(0, 0);
    }

    render() {
        return <></>;
    }
}
