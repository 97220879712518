import { Personen } from '../personen/PersonenReducer';
import { Rollentyp } from './fetch/offerengine/OfferEngineAngebotDto';
import { getAllePersonenMitRolle } from './PersonenHelper';
import { IAppState } from '../app/IAppState';
import { createSelector } from 'reselect';

const personenSelector = (state: IAppState): Personen => {
    return state.personen.personen;
};

export const versicherungsnehmerPersonSelector = createSelector(personenSelector, (personen: Personen) => {
    return getAllePersonenMitRolle(personen, Rollentyp.VERSICHERUNGSNEHMER).find(p => p !== undefined) || null;
});

export const versichertePersonenSelector = createSelector(personenSelector, (personen: Personen) => {
    return getAllePersonenMitRolle(personen, Rollentyp.VERSICHERTE_PERSON);
});
