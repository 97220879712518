import React from 'react';
import { IAppState } from '../app/IAppState';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import Panel from '@eg/elements/Panel';
import SelectRow from '@eg/elements/SelectRow';
import { createOptionsFromWertebereichAngebot } from '../util/WertebereicheHelper';
import { wertebereichZahlweiseMapping } from './VersicherungsschutzWertebereiche';
import { VersicherungsschutzState } from './VersicherungsschutzReducer';
import Price from '@eg/elements/Price';
import { SelectEvent } from '../util/UiEventTypes';
import PremiumBox from '@eg/elements/PremiumBox';
import Card from '@eg/elements/Card';
import { updateVersicherungsbeginnAsync, updateZahlweiseAsync } from './VersicherungsschutzAction';
import { LvbBeitragstabelle, Zahlweise } from '../util/fetch/offerengine/OfferEngineAngebotDto';
import {
    beitraegeProPersonSelector,
    BeitragProPerson,
    beitragstabelleObereAltersgrenzeSelector,
    beitragstabelleUntereAltersgrenzeSelector,
    gesamtBeitragSelector
} from './VersicherungsschutzSelectors';
import { changeDateFormat } from '../util/DateFormattingHelper';
import MessageBox from '@eg/elements/MessageBox';
import Benefit from '@eg/elements/Benefit';
import {
    getVersicherungsbeginnOptionId,
    VERSICHERUNGSSCHUTZ_GESAMTBEITRAG,
    VERSICHERUNGSSCHUTZ_VERSICHERUNGSBEGINN_SELECTROW,
    VERSICHERUNGSSCHUTZ_ZAHLWEISE_SELECTROW
} from '../util/RanorexIds';

interface VersicherungsschutzPresentationProps extends VersicherungsschutzProps, VersicherungsschutzDispatchProps {}

interface VersicherungsschutzProps extends VersicherungsschutzState {
    businessId: string;
    isHaustarif: boolean;
    isVermittlerAngebot: boolean;
    mitarbeiterrabattLabel: string;
    vermittlerrabattLabel: string;
    gesamtBeitrag: number | undefined;
    beitraegeProPerson: BeitragProPerson[];
    beitragstabelleUntereAltersgrenze: LvbBeitragstabelle | undefined;
    beitragstabelleObereAltersgrenze: LvbBeitragstabelle | undefined;
    oberflaecheSperren: boolean;
}

interface VersicherungsschutzDispatchProps {
    handleUpdateVersicherungsbeginn: (businessId: string, versicherungsbeginn: string, versicherungsId: string) => void;
    handleUpdateZahlweise: (businessId: string, zahlweise: Zahlweise, versicherungsId: string) => void;
}

const showNachlass = (visible: boolean, label: string) =>
    visible ? (
        <div className="esc_grid__wrapper">
            <div className="esc_col esc_col-s-12">
                <li>{label}</li>
            </div>
        </div>
    ) : null;

export class VersicherungsschutzPresentation extends React.Component<VersicherungsschutzPresentationProps> {
    render() {
        const {
            businessId,
            versicherungsId,
            versicherungsbeginn,
            zahlweise,
            wertebereichVersicherungsbeginn,
            wertebereichZahlweise,
            beitragstabelle,
            beitragstabelleUntereAltersgrenze,
            beitragstabelleObereAltersgrenze,
            gesamtBeitrag,
            beitraegeProPerson,
            oberflaecheSperren,
            handleUpdateVersicherungsbeginn,
            handleUpdateZahlweise,
            isHaustarif,
            isVermittlerAngebot,
            mitarbeiterrabattLabel,
            vermittlerrabattLabel
        } = this.props;

        return (
            <div>
                <div className="esc_grid">
                    <div className="esc_grid__wrapper">
                        <div className="esc_col esc_col-s-12">
                            <Panel>
                                <h2 className="esc_h4">Versicherungsschutz</h2>
                            </Panel>
                        </div>
                    </div>
                </div>
                <div className="esc_grid component-content">
                    <div className="esc_grid__wrapper versicherungsschutz">
                        <div className="esc_col esc_col-s-12 esc_col-m-3">
                            <SelectRow
                                label="Versicherungsbeginn"
                                disabled={oberflaecheSperren}
                                value={versicherungsbeginn}
                                onChange={(e: SelectEvent) => handleUpdateVersicherungsbeginn(businessId, e.target.value, versicherungsId)}
                                ranorex-id={VERSICHERUNGSSCHUTZ_VERSICHERUNGSBEGINN_SELECTROW}
                            >
                                {(wertebereichVersicherungsbeginn.werte as string[]).map((wert: string) => (
                                    <option key={wert} value={changeDateFormat(wert)} ranorex-id={getVersicherungsbeginnOptionId(wert)}>
                                        {changeDateFormat(wert)}
                                    </option>
                                ))}
                            </SelectRow>
                        </div>
                        <div className="esc_col esc_col-s-12 esc_col-m-3">
                            <SelectRow
                                label="Zahlweise"
                                disabled={oberflaecheSperren}
                                value={zahlweise}
                                onChange={(e: SelectEvent) => handleUpdateZahlweise(businessId, e.target.value as Zahlweise, versicherungsId)}
                                ranorex-id={VERSICHERUNGSSCHUTZ_ZAHLWEISE_SELECTROW}
                            >
                                {createOptionsFromWertebereichAngebot(wertebereichZahlweise.werte as string[], wertebereichZahlweiseMapping)}
                            </SelectRow>
                        </div>
                        {beitragstabelle.length > 0 && (
                            <div className="esc_col esc_col-m-12 esc_col-l-6">
                                <MessageBox type="info" className="versicherungsschutz-beitragsinfo">
                                    <Benefit label="Monatlicher Beitrag je nach Alter des Kindes:">
                                        {beitragstabelleUntereAltersgrenze && (
                                            <div>
                                                <span>
                                                    {beitragstabelleUntereAltersgrenze.von} bis einschließlich {beitragstabelleUntereAltersgrenze.bis} Jahre:
                                                </span>
                                                <span className="float-right">
                                                    <b>
                                                        <Price value={beitragstabelleUntereAltersgrenze.beitrag} unstyled={true} />
                                                    </b>
                                                </span>
                                            </div>
                                        )}
                                        {beitragstabelleObereAltersgrenze && (
                                            <div>
                                                <span>ab {beitragstabelleObereAltersgrenze.von} Jahren:</span>
                                                <span className="float-right">
                                                    <b>
                                                        <Price value={beitragstabelleObereAltersgrenze.beitrag} unstyled={true} />
                                                    </b>
                                                </span>
                                            </div>
                                        )}
                                    </Benefit>
                                </MessageBox>
                            </div>
                        )}
                        <div className="esc_col esc_col-s-12">
                            <Card label="Beiträge" labelColor="white" accentColor="#71022e">
                                {beitraegeProPerson.map((beitrag: BeitragProPerson) => (
                                    <PremiumBox
                                        className="premiumbox"
                                        key={beitrag.pdsId}
                                        label={beitrag.vorname + ' ' + beitrag.nachname + ' (' + beitrag.geburtsdatum + ')'}
                                        price={<Price value={beitrag.beitrag ? beitrag.beitrag : undefined} />}
                                        isSubtotal
                                    />
                                ))}
                                <PremiumBox
                                    className="premiumbox"
                                    label={<strong>Gesamtbeitrag</strong>}
                                    price={<Price value={gesamtBeitrag} ranorex-id={VERSICHERUNGSSCHUTZ_GESAMTBEITRAG} />}
                                />
                            </Card>
                        </div>
                        {(isHaustarif || isVermittlerAngebot) && gesamtBeitrag && (
                            <div className="esc_col esc_col-s-12">
                                <MessageBox type="info">
                                    {showNachlass(isHaustarif, mitarbeiterrabattLabel)}
                                    {showNachlass(isVermittlerAngebot, vermittlerrabattLabel)}
                                </MessageBox>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: IAppState): VersicherungsschutzProps => ({
    businessId: state.basisdaten.businessId,
    isHaustarif: state.basisdaten.isHaustarif,
    isVermittlerAngebot: state.basisdaten.isVermittlerAngebot,
    mitarbeiterrabattLabel: state.basisdaten.mitarbeiterrabattLabel,
    vermittlerrabattLabel: state.basisdaten.vermittlerrabattLabel,
    versicherungsId: state.versicherungsschutz.versicherungsId,
    versicherungsbeginn: state.versicherungsschutz.versicherungsbeginn,
    zahlweise: state.versicherungsschutz.zahlweise,
    wertebereichVersicherungsbeginn: state.versicherungsschutz.wertebereichVersicherungsbeginn,
    wertebereichZahlweise: state.versicherungsschutz.wertebereichZahlweise,
    beitraege: [],
    beitragstabelle: state.versicherungsschutz.beitragstabelle,
    beitragstabelleUntereAltersgrenze: beitragstabelleUntereAltersgrenzeSelector(state),
    beitragstabelleObereAltersgrenze: beitragstabelleObereAltersgrenzeSelector(state),
    gesamtBeitrag: gesamtBeitragSelector(state),
    beitraegeProPerson: beitraegeProPersonSelector(state),
    oberflaecheSperren: state.abschluss.abschlussErfolgreich
});

const mapDispatchToProps = (dispatch: Dispatch): VersicherungsschutzDispatchProps =>
    bindActionCreators(
        {
            handleUpdateVersicherungsbeginn: updateVersicherungsbeginnAsync,
            handleUpdateZahlweise: updateZahlweiseAsync
        },
        dispatch
    );

export const Versicherungsschutz = connect(mapStateToProps, mapDispatchToProps)(VersicherungsschutzPresentation);
