import React from 'react';
import { connect } from 'react-redux';
import InputRow from '@eg/elements/InputRow';
import { IAppState } from '../app/IAppState';

interface BasisdatenPresentationProps {
    aktionsnummer: string;
}

export function BasisdatenPresentation(props: BasisdatenPresentationProps) {
    return (
        <div className="esc_box">
            <div className="esc_grid">
                <div className="esc_grid__wrapper">
                    <div className="esc_col esc_col-s-6 esc_col-m-4">
                        <InputRow label="Aktionsnummer" readOnly value={props.aktionsnummer} />
                    </div>
                    <div className="esc_col esc_col-s-6 esc_col-m-4">
                        <InputRow label="Verkaufsprodukt" readOnly value="ZAHN-KFO" />
                    </div>
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = (state: IAppState): BasisdatenPresentationProps => ({
    aktionsnummer: state.basisdaten!.aktionsnummer
});

export const Basisdaten = connect(mapStateToProps)(BasisdatenPresentation);
