export const TAX_MODAL_OPEN = 'OPEN_TAX_MODAL';
export const TAX_MODAL_CLOSE = 'CLOSE_TAX_MODAL';

export const openTaxModal = () => ({
    type: TAX_MODAL_OPEN
});

export const closeTaxModal = () => ({
    type: TAX_MODAL_CLOSE
});
