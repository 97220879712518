import Radio from '@eg/elements/Radio';
import React from 'react';
import { WertebereichAngebot } from './fetch/offerengine/OfferEngineAngebotDto';
import { getRadioButtonId, getSelectOptionId } from './RanorexIds';

export interface WertebereichLokal {
    value: string | number | undefined;
    name: string;
}

const getDisplayName = (names: WertebereichLokal[], element: string): string => {
    //
    const found = names.find(x => element === x.value);
    return found ? found.name : element;
};

export const createOptions = (array: WertebereichLokal[]): JSX.Element[] =>
    array.map((element: WertebereichLokal) => (
        <option key={getKey(element)} value={element.value} ranorex-id={getSelectOptionId(element.value)}>
            {element.name}
        </option>
    ));

export const createOptionsFromWertebereichAngebot = (werte: string[], names: WertebereichLokal[] = []): JSX.Element[] =>
    werte.map((element: string) => (
        <option key={element} value={element} ranorex-id={getSelectOptionId(element)}>
            {getDisplayName(names, element)}
        </option>
    ));

export const createRadioButtons = (array: WertebereichLokal[]): JSX.Element[] =>
    array.map((element: WertebereichLokal) => <Radio label={element.name} value={getKey(element)} key={element.value} ranorex-id={getRadioButtonId(element.value)} />);

export const createRadioButtonsFromWertebereichAngebot = (werte: string[], names: WertebereichLokal[] = []): JSX.Element[] =>
    werte.map((element: string) => <Radio label={getDisplayName(names, element)} value={element} key={element} ranorex-id={getRadioButtonId(element)} />);

export const getWertebereichElementName = (array: WertebereichLokal[], value: string | number): string => {
    if (!value) {
        return 'KEIN_WERT';
    }

    const foundElement: WertebereichLokal | undefined = array.find((element: WertebereichLokal) => element.value === value);

    if (foundElement) {
        return foundElement.name;
    }

    return value.toString() + '_FIX_ME';
};

const getKey = (wertebereichElement: WertebereichLokal) => (wertebereichElement.value === undefined ? wertebereichElement.name : wertebereichElement.value);

export const getWertebereichFromAngebot = <T extends WertebereichAngebot>(attribut: string, wertebereiche: WertebereichAngebot[]): T =>
    wertebereiche.find(w => attribut === w.attribut) as T;
