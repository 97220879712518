import { IAppState } from '../app/IAppState';
import { Beitrag, LvbBeitragstabelle } from '../util/fetch/offerengine/OfferEngineAngebotDto';
import { Person } from '../personen/PersonenReducer';
import { createSelector } from 'reselect';
import { datumToString } from '../util/DateFormattingHelper';
import { versichertePersonenSelector } from '../util/Selectors';

export interface BeitragProPerson {
    pdsId: string;
    vorname: string;
    nachname: string;
    geburtsdatum: string;
    beitrag: number | null;
}

const beitraegeSelector = (state: IAppState): Beitrag[] | undefined => {
    return state.versicherungsschutz.beitraege;
};

export const gesamtBeitragSelector = createSelector(beitraegeSelector, (beitraege: Beitrag[] | undefined) => {
    if (!beitraege || beitraege.length === 0) {
        return undefined;
    }

    const gesamtBeitrag: Beitrag | undefined = beitraege.find(
        (beitrag: Beitrag) => beitrag.struktur && beitrag.struktur.versicherungen && !beitrag.struktur.personen && !beitrag.struktur.lvbArt
    );

    if (!gesamtBeitrag) {
        return undefined;
    }

    if (!gesamtBeitrag.bruttobeitragNachZahlweise) {
        return undefined;
    }

    return gesamtBeitrag.bruttobeitragNachZahlweise;
});

const filterEinzelbeitraege = (beitraege: Beitrag[] | undefined): Beitrag[] => {
    if (!beitraege || beitraege.length === 0) {
        return [];
    }

    return beitraege.filter((beitrag: Beitrag) => beitrag.struktur && beitrag.struktur.versicherungen && beitrag.struktur.personen && !beitrag.struktur.lvbArt);
};

export const beitraegeProPersonSelector = createSelector(
    beitraegeSelector,
    versichertePersonenSelector,
    (beitraege: Beitrag[] | undefined, versichertePersonen: Person[]): BeitragProPerson[] => {
        const einzelbeitraege: Beitrag[] = filterEinzelbeitraege(beitraege);
        const beitraegeProPerson: BeitragProPerson[] = [];
        versichertePersonen.forEach((versichertePerson: Person) => {
            const einzelbeitrag: Beitrag | undefined = einzelbeitraege.find(
                (beitrag: Beitrag) => beitrag.struktur.personen === versichertePerson.pdsId && beitrag.struktur.lvbArt === undefined
            );
            if (einzelbeitrag) {
                const beitragProPerson: BeitragProPerson = {
                    pdsId: versichertePerson.pdsId,
                    vorname: versichertePerson.vorname,
                    nachname: versichertePerson.nachname,
                    geburtsdatum: datumToString(versichertePerson.geburtsdatum),
                    beitrag: einzelbeitrag.bruttobeitragNachZahlweise
                };
                beitraegeProPerson.push(beitragProPerson);
            }
        });
        return beitraegeProPerson;
    }
);

const beitragstabelleSelector = (state: IAppState): LvbBeitragstabelle[] => {
    return state.versicherungsschutz.beitragstabelle;
};

export const beitragstabelleUntereAltersgrenzeSelector = createSelector(beitragstabelleSelector, (beitragstabelle: LvbBeitragstabelle[]): LvbBeitragstabelle | undefined => {
    const Altersgrenzen: number[] = beitragstabelle.map((eintrag: LvbBeitragstabelle) => {
        return eintrag.von;
    });
    return beitragstabelle.find((eintrag: LvbBeitragstabelle) => eintrag.von === Math.min(...Altersgrenzen));
});

export const beitragstabelleObereAltersgrenzeSelector = createSelector(beitragstabelleSelector, (beitragstabelle: LvbBeitragstabelle[]): LvbBeitragstabelle | undefined => {
    const Altersgrenzen: number[] = beitragstabelle.map((eintrag: LvbBeitragstabelle) => {
        return eintrag.von;
    });
    return beitragstabelle.find((eintrag: LvbBeitragstabelle) => eintrag.von === Math.max(...Altersgrenzen));
});
