import { Hinweis, OfferEngineAngebot, Verkaufsprozessart, Versandweg, Versicherung } from '../util/fetch/offerengine/OfferEngineAngebotDto';
import { LADE_ANGEBOT, LoadOfferAction } from '../app/AppAction';
import {
    AENDERE_ABSCHLUSS_ERGEBNIS,
    BESTAETIGE_ALLE_HINWEISE,
    BESTAETIGE_HINWEIS,
    CHANGE_VERSANDMAIL,
    CHANGE_VERSANDWEG,
    ChangeAbschlussErgebnisAction,
    ChangeBestaetigeAlleHinweiseAction,
    ChangeHinweisAction,
    ChangeVersandmailAction,
    ChangeVersandwegAction,
    SET_BUTTON_LOADING,
    SetButtonLoadingAction,
    SHOW_ABSCHLUSS_DIALOG,
    ShowAbschlussErgebnisDialogAction
} from './AbschlussAction';
import { ChangeVerkaufsprozessartAction } from '../abschlusswunsch/AbschlusswunschAction';

export const WAEHLE_EMAIL: string = 'Bitte E-Mail wählen';
export const ANDERE_EMAIL: string = 'Andere E-Mail angeben';

export interface AbschlussState {
    verkaufsprozessart: Verkaufsprozessart | null;
    direktAbschluss: DirektabschlussState;
    versandAbschluss: VersandabschlussState;
    versandmail?: string | null;
    abschlussErfolgreich: boolean;
    showAbschlussErfolgreichDialog: boolean;
    abschlussErfolgreichDetails: string;
    disableAbschlussButton: boolean;
    setSpinner: boolean;
}

export interface VersandabschlussState {
    versandweg?: Versandweg | null;
    setButtonLoading: boolean;
}

export interface DirektabschlussState {
    versicherung: Versicherung | null;
    alleHinweiseBestaetigt: boolean;
    setButtonLoading: boolean;
}

const initialAbschlussState: AbschlussState = {
    verkaufsprozessart: null,
    abschlussErfolgreich: false,
    abschlussErfolgreichDetails: '',
    showAbschlussErfolgreichDialog: false,
    versandmail: null,
    disableAbschlussButton: false,
    setSpinner: false,
    direktAbschluss: {
        versicherung: null,
        alleHinweiseBestaetigt: false,
        setButtonLoading: false
    },
    versandAbschluss: {
        versandweg: null,
        setButtonLoading: false
    }
};

export type AllAbschlussActions = LoadOfferAction &
    ChangeHinweisAction &
    ChangeBestaetigeAlleHinweiseAction &
    ChangeVerkaufsprozessartAction &
    ChangeVersandwegAction &
    ChangeVersandmailAction &
    ChangeAbschlussErgebnisAction &
    ShowAbschlussErgebnisDialogAction &
    SetButtonLoadingAction;

export const abschlussReducer = (state: AbschlussState = initialAbschlussState, action: AllAbschlussActions): AbschlussState => {
    switch (action.type) {
        case LADE_ANGEBOT: {
            return {
                ...state,
                verkaufsprozessart: action.angebot.verkaufsprozessart,
                abschlussErfolgreich: getAbschlussErfolgreich(action.angebot),
                direktAbschluss: getDirektabschluss(action.angebot, state.direktAbschluss),
                versandAbschluss: {
                    versandweg: action.angebot.versandweg,
                    setButtonLoading: false
                },
                versandmail: action.angebot.versandmail,
                disableAbschlussButton: disableAbschlussButton(action.angebot)
            };
        }
        case BESTAETIGE_HINWEIS: {
            const hinweise = [...state.direktAbschluss.versicherung!.hinweise];
            hinweise[action.payload.index] = action.payload.hinweis;

            return {
                ...state,
                direktAbschluss: {
                    ...state.direktAbschluss,
                    versicherung: {
                        ...state.direktAbschluss.versicherung!,
                        hinweise
                    }
                }
            };
        }
        case BESTAETIGE_ALLE_HINWEISE: {
            return {
                ...state,
                direktAbschluss: {
                    ...state.direktAbschluss,
                    alleHinweiseBestaetigt: action.alleHinweiseBestaetigt
                }
            };
        }

        case CHANGE_VERSANDWEG: {
            return {
                ...state,
                versandAbschluss: {
                    ...state.versandAbschluss,
                    versandweg: action.versandweg
                }
            };
        }
        case CHANGE_VERSANDMAIL: {
            return {
                ...state,
                disableAbschlussButton: !isEmail(action.versandmail),
                versandmail: action.versandmail
            };
        }
        case SET_BUTTON_LOADING: {
            return {
                ...state,
                setSpinner: action.setButtonLoading,
                versandAbschluss: {
                    ...state.versandAbschluss,
                    setButtonLoading: action.setButtonLoading
                },
                direktAbschluss: {
                    ...state.direktAbschluss,
                    setButtonLoading: action.setButtonLoading
                }
            };
        }
        case AENDERE_ABSCHLUSS_ERGEBNIS: {
            if (action.payload.angebotsnummer) {
                return {
                    ...state,
                    abschlussErfolgreich: action.payload.abschlussErfolgreich,
                    abschlussErfolgreichDetails: action.payload.abschlussErfolgreichDetails
                };
            }
            return {
                ...state,
                abschlussErfolgreich: action.payload.abschlussErfolgreich
            };
        }
        case SHOW_ABSCHLUSS_DIALOG: {
            return {
                ...state,
                showAbschlussErfolgreichDialog: action.showDialog
            };
        }
        default:
            return state;
    }
};

const getDirektabschluss = (angebot: OfferEngineAngebot, direktabschlussState: DirektabschlussState): DirektabschlussState => {
    return {
        ...direktabschlussState,
        versicherung: angebot.versicherungen[0],
        alleHinweiseBestaetigt: alleHinweiseBestaetigt(angebot.versicherungen)
    };
};

export const getAbschlussErfolgreich = (angebot: OfferEngineAngebot): boolean => angebot.businessId !== angebot.angebotsnummer;

export const getGewaehlteVersicherung = (versicherungen: Versicherung[]): Versicherung | null => {
    if (versicherungen === null) {
        return null;
    }

    const [first = null] = versicherungen.filter(v => v.ausgewaehlt);
    return first;
};

const disableAbschlussButton = (angebot: OfferEngineAngebot): boolean =>
    angebot.verkaufsprozessart === Verkaufsprozessart.VOLLANGEBOT && !angebot.versandweg;
// This change was made to fix [DSM-2791], however this is not a pernament solution,
// the field versandmail is no longer present in OE and any reference to it should be removed from the codebase entirely

export const isEmail = (versandmail: string): boolean => !!versandmail && ![ANDERE_EMAIL, WAEHLE_EMAIL].includes(versandmail);

const alleHinweiseBestaetigt = (versicherungen: Versicherung[]): boolean => {
    const versicherung = getGewaehlteVersicherung(versicherungen);

    if (versicherung === null) {
        return false;
    }

    const hinweise = versicherung.hinweise;

    if (!hinweise || hinweise === null || hinweise?.length === 0) {
        return false;
    }

    return hinweise.every((h: Hinweis) => h.bestaetigt);
};
