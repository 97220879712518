export interface Meldung {
    aenderungenUebernommen: boolean;
    code: string;
    schweregrad: Schweregrad;
    tarifierungsrelevant: boolean;
    text: string;
    variantennummern: number[];
}

export enum Schweregrad {
    INFO = 'INFO',
    WARNING = 'WARNING',
    ERROR = 'ERROR',
    SUCCESS = 'SUCCESS'
}
