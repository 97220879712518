import { Dispatch } from 'redux';
import { FrontendResponse } from '../client/FrontendResponse';
import { BankdatenDto } from './BankdatenDto';
import { sendGetRequest, sendPutRequest } from '../client/FetchBaseClient';

export const getBankdatenHttpRequest = (businessId: string, iban: string, dispatch: Dispatch, personId?:string, ): Promise<FrontendResponse<BankdatenDto>> => {
    return sendGetRequest(`/kfo/ibanvalidation/${businessId}/${personId}/?iban=${iban}`, dispatch);
};

export const changeBankdatenHttpRequest = (businessId: string, iban: string, dispatch: Dispatch, personId?:string, paymentId?: string): Promise<FrontendResponse<BankdatenDto>> => {
    return sendPutRequest(`/kfo/ibanvalidation/${businessId}/${personId}/${paymentId}?iban=${iban}`, {}, dispatch);
};
