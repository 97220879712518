import * as React from 'react';

type WarningMessageProps = {
    show: boolean;
};

export const WarningMessage = ({ show }: WarningMessageProps) => {
    if (!show) {
        return null;
    }

    return (
        <p style={{ color: 'red', fontStyle: 'italic' }}>
            Achtung! Bei dieser Vertragskonstellation ab dem 01.01.2022 fällt Versicherungssteuer an. Daher ist dies nicht versicherbar. Bieten Sie eine versicherungssteuerfreie
            Alternative an.
        </p>
    );
};
