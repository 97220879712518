import { PdsPerson } from '../personen/PersonDto';
import { OfferEngineAngebot, Rollentyp } from '../offerengine/OfferEngineAngebotDto';
import { ParisKunde } from './PersonensucheDto';
import { Personen } from '../../../personen/PersonenReducer';

interface AngebotPdsPersonResponseDto {
    angebot: OfferEngineAngebot;
    pdsPerson: PdsPerson;
}

export interface Rolleninhaber {
    pdsId: string;
    rollentyp?: Rollentyp;
    geburtsdatum?: Date;
}

export interface PersonAnlegenRequestDto {
    alleRollenBisher: Rolleninhaber[];
    parisKunde: ParisKunde;
}

export interface PersonAnlegenResponseDto extends AngebotPdsPersonResponseDto {}

export interface DeletePersonRequestDto {
    pdsId: string;
    alleRollenBisher: Rolleninhaber[];
}

export interface ChangeRolleRequestDto {
    rolleninhaberNeu?: Rolleninhaber;
    rolleninhaberAlt?: Rolleninhaber;
    alleRollenBisher: Rolleninhaber[];
}

export interface AddRolleRequestDto extends ChangeRolleRequestDto {
    pdsPerson: PdsPerson;
}

export interface AddRolleResponseDto extends AngebotPdsPersonResponseDto {}

export const alleRollenBisher = (personen: Personen): Rolleninhaber[] => {
    let alleRollenBisherArray: Rolleninhaber[] = [];

    Object.values(personen).forEach(person => {
        person.rollen.forEach(rolle =>
            alleRollenBisherArray.push({
                pdsId: person.pdsId,
                rollentyp: rolle,
                geburtsdatum: person.geburtsdatum
            })
        );
    });
    return alleRollenBisherArray;
};
