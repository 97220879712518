import { IAppState } from '../app/IAppState';
import { Verkaufsprozessart } from '../util/fetch/offerengine/OfferEngineAngebotDto';
import { createSelector } from 'reselect';

export const verkaufsprozessartSelector = (state: IAppState): Verkaufsprozessart | null => state.basisdaten.verkaufsprozessart;

export const isVollangebotSelector = createSelector(
    verkaufsprozessartSelector,
    (verkaufsprozessart: Verkaufsprozessart | null) => verkaufsprozessart === Verkaufsprozessart.VOLLANGEBOT
);

export const verkaufsprozessartSuffixSelector = createSelector(isVollangebotSelector, (isVollangebot: boolean) => (isVollangebot ? '  (optional)' : ''));
