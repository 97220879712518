import { Meldung, Schweregrad } from '../util/fetch/client/MeldungenDto';

export const compareBySchweregrad = (a: Meldung, b: Meldung): number => {
    if (a.schweregrad === Schweregrad.ERROR && b.schweregrad !== Schweregrad.ERROR) {
        return -1;
    }

    if (b.schweregrad === Schweregrad.ERROR && a.schweregrad !== Schweregrad.ERROR) {
        return 1;
    }

    return 0;
};

export const compareByText = (a: Meldung, b: Meldung): number => {
    if (a.text < b.text) {
        return -1;
    }

    if (a.text > b.text) {
        return 1;
    }

    return 0;
};
