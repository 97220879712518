import { WertebereichLokal } from '../util/WertebereicheHelper';
import { Typ } from '../util/fetch/personen/PersonDto';
import { Rollentyp } from '../util/fetch/offerengine/OfferEngineAngebotDto';

export const wertebereichMailTyp: WertebereichLokal[] = [
    {
        value: Typ.privat,
        name: 'privat'
    },
    {
        value: Typ.geschaeftlich,
        name: 'geschäftlich'
    }
];

export const wertebereichRollen: WertebereichLokal[] = [
    {
        value: Rollentyp.VERSICHERUNGSNEHMER,
        name: 'Versicherungsnehmer'
    },
    {
        value: Rollentyp.VERSICHERTE_PERSON,
        name: 'Versicherte Person'
    }
];

export const SonderkennzeichenAktiverKundenlogin: number = 52;
