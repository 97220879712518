export const getDirektabschlussHinweisId = (hinweisart: string): string => 'DIREKTABSCHLUSS_CHECKBOX_' + hinweisart;

export const DIREKTABSCHLUSS_BUTTON: string = 'DIREKTABSCHLUSS_BUTTON';

export const EMAIL_INPUT: string = 'EMAIL_INPUT';

export const EMAIL_SELECTROW: string = 'EMAIL_SELECTROW';

export const getEmailOptionId = (mailadresse: string): string => 'EMAIL_OPTION_' + mailadresse;

export const VERSAND_RADIOGROUP: string = 'VERSAND_RADIOGROUP';

export const VERSAND_BUTTON: string = 'VERSAND_BUTTON';

export const getRadioButtonId = (element: string | number | undefined): string => {
    if (element === undefined) {
        return 'RADIOBUTTON_UNDEFINED';
    }

    return 'RADIOBUTTON_' + element;
};

export const ABSCHLUSS_MODAL: string = 'ABSCHLUSS_MODAL';

export const ABSCHLUSS_MODAL_HEADING: string = 'ABSCHLUSS_MODAL_HEADING';

export const ABSCHLUSS_MODAL_DETAILS: string = 'ABSCHLUSS_MODAL_DETAILS';

export const ABSCHLUSSWUNSCH_RADIOGROUP: string = 'ABSCHLUSSWUNSCH_RADIOGROUP';

export const BANKVERBINDUNG_SELECTROW: string = 'BANKVERBINDUNG_SELECTROW';

export const getBankverbindungOptionId = (entry: string): string => 'BANKVERBINDUNG_OPTION_' + entry;

export const BANKVERBINDUNG_INPUT_IBAN: string = 'BANKVERBINDUNG_INPUT_IBAN';

export const BANKVERBINDUNG_INPUT_BIC: string = 'BANKVERBINDUNG_INPUT_BIC';

export const BANKVERBINDUNG_INPUT_INSTITUT: string = 'BANKVERBINDUNG_INPUT_INSTITUT';

export const BANKVERBINDUNG_SELECTROW_ABBUCHUNG: string = 'BANKVERBINDUNG_SELECTROW_ABBUCHUNG';

export const getSelectOptionId = (element: string | number | undefined): string => {
    if (element === undefined) {
        return 'OPTION_UNDEFINED';
    }

    return 'OPTION_' + element;
};

export const PERSONENSUCHE_INPUT_KUNDENNUMMER: string = 'PERSONENSUCHE_INPUT_KUNDENNUMMER';

export const PERSONENSUCHE_BUTTON_SUCHEN: string = 'PERSONENSUCHE_BUTTON_SUCHEN';

export const PERSONENSUCHE_BUTTON_PERSON: string = 'PERSONENSUCHE_BUTTON_PERSON';

export const VERSICHERUNGSSCHUTZ_VERSICHERUNGSBEGINN_SELECTROW: string = 'VERSICHERUNGSSCHUTZ_VERSICHERUNGSBEGINN_SELECTROW';

export const getVersicherungsbeginnOptionId = (wert: string): string => 'VERSICHERUNGSSCHUTZ_' + wert;

export const VERSICHERUNGSSCHUTZ_ZAHLWEISE_SELECTROW: string = 'VERSICHERUNGSSCHUTZ_ZAHLWEISE_SELECTROW';

export const VERSICHERUNGSSCHUTZ_GESAMTBEITRAG: string = 'VERSICHERUNGSSCHUTZ_GESAMTBEITRAG';
