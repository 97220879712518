import * as React from 'react';
import AutocompleteRow from '@eg/elements/AutocompleteRow';
import Button from '@eg/elements/Button';
import Modal from '@eg/elements/components/Modal';
import ArrowDownIcon from '@eg/elements/components/Icons/ArrowDownIcon';
import FormRow from '@eg/elements/FormRow';
import { relativesList as fullRelativesList } from './relatives-list';
import { useDispatch, useSelector } from 'react-redux';
import { closeTaxModal } from './actions';
import Input from '@eg/elements/Input';
import { WarningMessage } from './warning-messages';
import { IAppState } from '../app/IAppState';
import { selectKundeAsync } from '../personensuche/PersonensucheAction';
import { autoCompleteWithDebounce } from './utils';
import { ParisKunde } from '../util/fetch/personensuche/PersonensucheDto';

const modalStyles = {
    overflow: 'hidden'
};

type TaxModalProps = {
    isOpen: boolean;
};

const TaxModal = ({ isOpen }: TaxModalProps) => {
    const [value, setValue] = React.useState('');
    const [relatives, setRelatives] = React.useState(fullRelativesList);
    const dispatch = useDispatch();
    const businessId = useSelector((state: IAppState): string => state.basisdaten.businessId);
    const kunde = useSelector((state: IAppState): ParisKunde | null => state.personensuche.kunde);
    const onProceed = () => {
        // @ts-ignore
        dispatch(selectKundeAsync(businessId, kunde));
        dispatch(closeTaxModal());
    };
    const onCancel = () => {
        dispatch(closeTaxModal());
    };

    const onClickProceed = () => {
        resetInput();
        // @ts-ignore
        onProceed(businessId, kunde);
    };
    const onClickCancel = () => {
        resetInput();
        onCancel();
    };

    const resetInput = React.useCallback(() => {
        setValue('');
        setRelatives(fullRelativesList);
    }, []);

    const onChange = React.useCallback(
        (val: string) => {
            setValue(val);

            if (!val) {
                setRelatives(fullRelativesList);
            }

            autoCompleteWithDebounce(fullRelativesList, setRelatives)(val);
        },
        // eslint-disable-next-line
        [value, relatives]
    );

    const isProceedBtnActive = getIsProceedBtnActive(value);
    const isPositiveResult = getIsPositiveResult(value);

    return (
        <Modal open={isOpen} viewport="relative" id="tax-modal">
            <div className="tax-modal-container content" style={modalStyles}>
                <AutocompleteRow
                    suggestions={relatives}
                    label="Angehörigenstatus"
                    value={value}
                    onChange={onChange}
                    id="tax-modal_autocomplete"
                    // tslint:disable-next-line no-any
                    renderInput={(props: any, menuId: string) => {
                        return <Input {...props} ref={el => el && props.ref(el.input)} adornmentRight={ArrowDownIcon} aria-owns={menuId} title={value} />;
                    }}
                />

                <WarningMessage show={!isPositiveResult} />

                <FormRow label="">
                    <Button variant="text-link" onClick={onClickCancel}>
                        Abbrechen
                    </Button>

                    {isPositiveResult && (
                        <Button variant="primary" disabled={!isProceedBtnActive} onClick={onClickProceed}>
                            Speichern
                        </Button>
                    )}
                </FormRow>
            </div>
        </Modal>
    );
};

const getIsPositiveResult = (value: string) => !value.toLowerCase().includes('sonstige');

const getIsProceedBtnActive = (value: string) => {
    return fullRelativesList.includes(value) && !value.toLowerCase().includes('sonstige');
};

export default TaxModal;
