import { IUpdateBusinessId, UPDATE_BUSINESS_ID } from './businessIdUpdateAction'

export const initialBusinessIdState: string = '';

function businessIdReducer(state: string = initialBusinessIdState, action: IUpdateBusinessId) {
    switch (action.type) {
        case UPDATE_BUSINESS_ID:
            return action.payload;
        default:
            return state;
    }
}

export default businessIdReducer;
