import { sendDeleteRequest, sendGetRequest, sendPutRequest } from '../client/FetchBaseClient';
import { FrontendResponse } from '../client/FrontendResponse';
import { OfferEngineAngebotDto, OfferEnginePersonDataDto, Rollentyp } from './OfferEngineAngebotDto';
import { Dispatch } from 'redux';
import { OfferEngineBankverbindungRequestDto } from './OfferEngineBankverbindungRequestDto';
import { UpdateVersicherungRequestDto } from './UpdateVersicherungRequestDto';
import { UpdateVerkaufsprozessartRequestDto } from './UpdateVerkaufsprozessartRequestDto';
import { UpdateBeratungsprotokollRequestDto } from './UpdateBeratungsprotokollRequestDto';
import { UpdateAbbuchungstagRequestDto } from './UpdateAbbuchungstagRequestDto';
import { UpdateVersandRequestDto } from './UpdateVersandRequestDto';

export const getAngebotHttpRequest = (businessId: string, dispatch: Dispatch): Promise<FrontendResponse<OfferEngineAngebotDto>> => {
    return sendGetRequest(`/kfo/angebot/${businessId}/`, dispatch);
};

export const updateBankverbindungHttpRequest = (
    businessId: string,
    payload: OfferEngineBankverbindungRequestDto,
    dispatch: Dispatch
): Promise<FrontendResponse<OfferEnginePersonDataDto>> => {
    return sendPutRequest(`/kfo/angebot/${businessId}/update-bankverbindung/`, payload, dispatch);
};

export const updateVersicherungsbeginnHttpRequest = (
    businessId: string,
    payload: UpdateVersicherungRequestDto,
    dispatch: Dispatch
): Promise<FrontendResponse<OfferEngineAngebotDto>> => {
    return sendPutRequest(`/kfo/angebot/${businessId}/update-versicherungsbeginn/`, payload, dispatch);
};

export const updateZahlweiseHttpRequest = (businessId: string, payload: UpdateVersicherungRequestDto, dispatch: Dispatch): Promise<FrontendResponse<OfferEngineAngebotDto>> => {
    return sendPutRequest(`/kfo/angebot/${businessId}/update-zahlweise/`, payload, dispatch);
};

export const deleteRolleHttpRequest = (businessId: string, pdsId: string, rolle: Rollentyp, dispatch: Dispatch): Promise<FrontendResponse<OfferEngineAngebotDto>> => {
    return sendDeleteRequest(`/[TODO]/angebot/${businessId}/rollen/${pdsId}/rollentyp/${rolle}/`, dispatch);
};

export const updateVerkaufsprozessartHttpRequest = (
    businessId: string,
    payload: UpdateVerkaufsprozessartRequestDto,
    dispatch: Dispatch
): Promise<FrontendResponse<OfferEnginePersonDataDto>> => {
    return sendPutRequest(`/kfo/angebot/${businessId}/update-verkaufsprozessart/`, payload, dispatch);
};

export const updateBeratungsprotokollHttpRequest = (
    businessId: string,
    payload: UpdateBeratungsprotokollRequestDto,
    dispatch: Dispatch
): Promise<FrontendResponse<OfferEngineAngebotDto>> => {
    return sendPutRequest(`/kfo/angebot/${businessId}/update-beratungsprotokoll/`, payload, dispatch);
};

export const updateAbbuchungstagHttpRequest = (
    businessId: string,
    payload: UpdateAbbuchungstagRequestDto,
    dispatch: Dispatch
): Promise<FrontendResponse<OfferEngineAngebotDto>> => {
    return sendPutRequest(`/kfo/angebot/${businessId}/update-abbuchungstag/`, payload, dispatch);
};

export const updateVersandHttpRequest = (businessId: string, payload: UpdateVersandRequestDto, dispatch: Dispatch): Promise<FrontendResponse<OfferEngineAngebotDto>> => {
    return sendPutRequest(`/kfo/angebot/${businessId}/update-versand/`, payload, dispatch);
};

export const changeVersandHttpRequest = (businessId: string, personId: string, dispatch: Dispatch, emailId?: string,): Promise<FrontendResponse<OfferEngineAngebotDto>> => {
    return sendPutRequest(`/kfo/personen/${businessId}/${personId}/${emailId}`, {}, dispatch);
};

export const updateBestaetigeDAHinweiseHttpRequest = (
    businessId: string,
    payload: UpdateVersicherungRequestDto,
    dispatch: Dispatch
): Promise<FrontendResponse<OfferEnginePersonDataDto>> => {
    return sendPutRequest(`/kfo/angebot/${businessId}/update-dahinweise/`, payload, dispatch);
};
