/*
 * Copyright der ERGO Direkt . All Rights reserved.
 * Projekt: telefon-kranken-frontend
 * Informationsklassifikation: vertraulich
 * Dateiname: TechnischeFehler.tsx
 * User: miarnedo
 * Zuletzt bearbeitet: 13.03.18 12:53
 */

import * as React from 'react';
import { Provider, THEME } from '@eg/elements/Provider';
import Button from '@eg/elements/Button';
import { LOGIN_ENDPOINT } from './constants'

export interface IUnauthenticatedProps {
    businessId: string;
}
const Unauthenticated = ({ businessId }: IUnauthenticatedProps) => {
    return (
        <Provider theme={THEME.ergoone}>
            <div className="container top-bottom-spacing-20">
                <div style={{ textAlign: 'right' }}>
                    <p>
                        <Button
                            variant="primary"
                            onClick={() => {
                                window.location.replace(`${LOGIN_ENDPOINT}?relayState=${businessId}`);
                            }}
                        >
                            Click to Login
                        </Button>
                    </p>
                </div>
            </div>
        </Provider>
    );
};

export default Unauthenticated;
