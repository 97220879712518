import React from 'react';
import { IAppState } from '../app/IAppState';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import Panel from '@eg/elements/Panel';
import SelectRow from '@eg/elements/SelectRow';
import InputRow from '@eg/elements/InputRow';
import { Person } from '../personen/PersonenReducer';
import { ANDERE_IBAN, BankverbindungState } from './BankverbindungReducer';
import { isValidIban, normalizeIban } from '@eg/elements/utils/validation/iban';
import {
    changeSelectedRowEntrySync,
    IbanVerificationStatus,
    saveNewVersicherungsnehmerSync,
    setzeIbanMeldungSync,
    updateAbbuchungstagAsync,
    updateIbanEntrySync,
    verifyAndUpdateBankverbindungAsync,
    changeBankverbindungAsync
} from './BankverbindungAction';
import CheckIcon from '@eg/elements/components/Icons/CheckIcon';
import DamageIcon from '@eg/elements/components/Icons/DamageIcon';
import LoaderIcon from '@eg/elements/components/Icons/LoaderIcon';
import FormRow from '@eg/elements/FormRow';
import { createOptions, createOptionsFromWertebereichAngebot } from '../util/WertebereicheHelper';
import { AbbuchungstagImMonat, Verkaufsprozessart } from '../util/fetch/offerengine/OfferEngineAngebotDto';
import { InputEvent, SelectEvent } from '../util/UiEventTypes';
import { erlaubteAbbuchungstage } from './BankverbindungWertebereich';
import { versicherungsnehmerPersonSelector } from '../util/Selectors';
import {
    BANKVERBINDUNG_INPUT_BIC,
    BANKVERBINDUNG_INPUT_IBAN,
    BANKVERBINDUNG_INPUT_INSTITUT,
    BANKVERBINDUNG_SELECTROW,
    BANKVERBINDUNG_SELECTROW_ABBUCHUNG,
    getBankverbindungOptionId
} from '../util/RanorexIds';
import { isVollangebotSelector, verkaufsprozessartSelector, verkaufsprozessartSuffixSelector } from './BankverbindungSelector';
import MessageBox from '@eg/elements/MessageBox';
import Benefit from '@eg/elements/Benefit';

interface BankverbindungPresentationProps extends BankverbindungProps, BankverbindungDispatchProps {}

interface BankverbindungProps {
    businessId: string;
    verkaufsprozessart: Verkaufsprozessart | null;
    isVollangebot: boolean;
    verkaufsprozessartSuffix: string;
    versicherungsnehmer: Person | null;
    bankverbindung: BankverbindungState;
    oberflaecheSperren: boolean;
    personId: string;
}

interface BankverbindungDispatchProps {
    handleSaveNewVersicherungsnehmer: (versicherungsnehmer: Person | null) => void;
    handleChangeSelectedRowEntry: (selectedEntry: string, verkaufsprozessart: Verkaufsprozessart | null) => void;
    handleUpdateIban: (iban: string, verkaufsprozessart: Verkaufsprozessart | null) => void;
    handleVerifyAndUpdateBankverbindung: (iban: string) => void;
    handleChangeAbbuchungstag: (businessId: string, abbuchungstag: AbbuchungstagImMonat) => void;
}

export class BankverbindungPresentation extends React.Component<BankverbindungPresentationProps, {}> {
    constructor(props: BankverbindungPresentationProps) {
        super(props);
        this.changeHandler = this.changeHandler.bind(this);
    }

    componentDidUpdate(prevProps: Readonly<BankverbindungPresentationProps>): void {
        if (!prevProps.versicherungsnehmer || !this.props.versicherungsnehmer) {
            return;
        }

        if (prevProps.versicherungsnehmer.kundennummerParis !== this.props.versicherungsnehmer.kundennummerParis) {
            this.props.handleSaveNewVersicherungsnehmer(this.props.versicherungsnehmer);
        }
    }

    async changeHandler(value: string) {
        const iban: string = normalizeIban(value);
        this.props.handleUpdateIban(iban, this.props.verkaufsprozessart);

        if (isValidIban(iban)) {
            this.props.handleVerifyAndUpdateBankverbindung(iban);
        }
    }

    render() {
        const { businessId, bankverbindung, oberflaecheSperren, handleChangeAbbuchungstag, verkaufsprozessart, isVollangebot, verkaufsprozessartSuffix } = this.props;
        return (
            <div>
                <div className="esc_grid">
                    <div className="esc_grid__wrapper">
                        <div className="esc_col esc_col-s-12">
                            <Panel>
                                {
                                    <h2 className="esc_h4">
                                        Bankverbindung
                                        {verkaufsprozessartSuffix}
                                    </h2>
                                }
                            </Panel>
                        </div>
                    </div>
                </div>
                <div className="esc_grid component-content">
                    <div className="esc_grid__wrapper">
                        {isVollangebot && (
                            <div className="esc_col esc_col-s-12">
                                <MessageBox type="info">
                                    <Benefit>
                                        Bei Versand von Angeboten per E-Mail werden die Bankdaten aus Sicherheitsgründen nicht eingedruckt. Die Daten bleiben grundsätzlich im
                                        System erhalten, müssen aber vom Kunden erneut ausgefüllt werden. Ein Angebot kann auch ohne Angabe der Bankdaten erstellt werden.
                                    </Benefit>
                                </MessageBox>
                            </div>
                        )}
                        <div className="esc_col esc_col-s-12">
                            <div>
                                {/* @TODO - removed from spcs
                                {this.props.versicherungsnehmer && (
                                    <InputRow label="Kontoinhaber" disabled={oberflaecheSperren} value={bankverbindung.kontoinhaber} placeholder="Kontoinhaber" readOnly />
                                )} */}
                                {this.props.bankverbindung.selectRowEntries && this.props.bankverbindung.selectRowEntries.length > 1 && (
                                    <SelectRow
                                        label="Bekannte Bankverbindungen"
                                        value={this.props.bankverbindung.selectedRowEntry}
                                        error={''}
                                        onChange={(e: SelectEvent) => this.props.handleChangeSelectedRowEntry(e.target.value, verkaufsprozessart)}
                                        disabled={oberflaecheSperren || bankverbindung.showBankverbindungIsLoadingSpinner}
                                        className="bankverbindung"
                                        ranorex-id={BANKVERBINDUNG_SELECTROW}
                                    >
                                        {this.props.bankverbindung.selectRowEntries.map((entry: string) => (
                                            <option key={entry} label={entry} value={entry} ranorex-id={getBankverbindungOptionId(entry)} />
                                        ))}
                                    </SelectRow>
                                )}
                                {this.props.bankverbindung.selectedRowEntry === ANDERE_IBAN && (
                                    <InputRow
                                        label="IBAN"
                                        placeholder="Bitte IBAN eingeben"
                                        value={bankverbindung.iban}
                                        onChange={(e: InputEvent) => this.changeHandler(e.currentTarget.value)}
                                        disabled={oberflaecheSperren || bankverbindung.isIbanUnderVerification}
                                        adornmentRight={renderIcon(bankverbindung.ibanVerificationStatus)}
                                        error={bankverbindung.ibanVerificationStatus === IbanVerificationStatus.NOT_SUCCESSFULLY_VERIFIED || bankverbindung.iban === ''}
                                        ranorex-id={BANKVERBINDUNG_INPUT_IBAN}
                                    />
                                )}
                                {bankverbindung.showBankverbindungIsLoadingSpinner ? (
                                    <FormRow label="BIC wird ermittelt...">
                                        <LoaderIcon
                                            spinning
                                            width={30}
                                            height={30}
                                            style={{
                                                marginTop: 10
                                            }}
                                        />
                                    </FormRow>
                                ) : (
                                    <InputRow
                                        label="BIC"
                                        value={bankverbindung.bic}
                                        disabled={oberflaecheSperren}
                                        error={bankverbindung.ibanVerificationStatus === IbanVerificationStatus.NOT_SUCCESSFULLY_VERIFIED}
                                        placeholder="Wird automatisch ermittelt, wenn IBAN vorhanden"
                                        readOnly
                                        ranorex-id={BANKVERBINDUNG_INPUT_BIC}
                                    />
                                )}
                                {bankverbindung.showBankverbindungIsLoadingSpinner ? (
                                    <FormRow label="Bank wird ermittelt...">
                                        <LoaderIcon
                                            spinning
                                            width={30}
                                            height={30}
                                            style={{
                                                marginTop: 10
                                            }}
                                        />
                                    </FormRow>
                                ) : (
                                    <InputRow
                                        label="Bank"
                                        disabled={oberflaecheSperren}
                                        value={bankverbindung.bankname}
                                        error={bankverbindung.ibanVerificationStatus === IbanVerificationStatus.NOT_SUCCESSFULLY_VERIFIED}
                                        placeholder="Wird automatisch ermittelt, wenn IBAN vorhanden"
                                        readOnly
                                        ranorex-id={BANKVERBINDUNG_INPUT_INSTITUT}
                                    />
                                )}
                                <SelectRow
                                    label="Abbuchung am"
                                    disabled={oberflaecheSperren}
                                    value={bankverbindung.abbuchungstagImMonat}
                                    onChange={(e: SelectEvent) => handleChangeAbbuchungstag(businessId, e.target.value as AbbuchungstagImMonat)}
                                    ranorex-id={BANKVERBINDUNG_SELECTROW_ABBUCHUNG}
                                >
                                    {bankverbindung.wertebereichAbbuchungstag.werte.length > 0
                                        ? createOptionsFromWertebereichAngebot(bankverbindung.wertebereichAbbuchungstag.werte as string[], erlaubteAbbuchungstage)
                                        : createOptions(erlaubteAbbuchungstage)}
                                </SelectRow>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const renderIcon = (ibanVerificationStatus: IbanVerificationStatus): JSX.Element | undefined => {
    switch (ibanVerificationStatus) {
        case IbanVerificationStatus.SUCCESSFULLY_VERIFIED:
            return (
                <CheckIcon
                    style={{
                        fill: 'green'
                    }}
                />
            );

        /* Question: When will this ever be possibly set?
         *
         * Answer:
         * die ergo-elements prüfen die IBAN nur syntaktisch (blz, kntr,
         * inklusive prüfziffer), aber nicht semantisch (gibt es das Konto
         * wirklich?). Es gibt valide IBANs, für die es kein Bank-Konto gibt
         * z.B. DE36 0000 0000 0000 0000 00
         * dort schmeißt, das BFF dann einen Fehler und wir können anhand des
         * Icons unterscheiden, welcher der zwei Fälle eingetreten ist.
         */
        case IbanVerificationStatus.NOT_SUCCESSFULLY_VERIFIED:
            return (
                <DamageIcon
                    style={{
                        fill: 'red'
                    }}
                />
            );

        default:
            return undefined;
    }
};

const mapStateToProps = (state: IAppState): BankverbindungProps => {
    return {
        businessId: state.basisdaten.businessId,
        verkaufsprozessart: verkaufsprozessartSelector(state),
        isVollangebot: isVollangebotSelector(state),
        verkaufsprozessartSuffix: verkaufsprozessartSuffixSelector(state),
        versicherungsnehmer: versicherungsnehmerPersonSelector(state),
        bankverbindung: state.bankverbindung,
        oberflaecheSperren: state.abschluss.abschlussErfolgreich,
        personId: state.bankverbindung.versicherungsnehmer?.pdsId || '',
    };
};

const mapDispatchToProps = (dispatch: Dispatch): BankverbindungDispatchProps => ({
    handleSaveNewVersicherungsnehmer(versicherungsnehmer: Person | null): void {
        dispatch(saveNewVersicherungsnehmerSync(versicherungsnehmer));
    },
    handleChangeSelectedRowEntry(selectedEntry: string, verkaufsprozessart: Verkaufsprozessart | null): void {
        dispatch(changeSelectedRowEntrySync(selectedEntry, verkaufsprozessart));
        // @ts-ignore
        selectedEntry !== ANDERE_IBAN && dispatch(changeBankverbindungAsync(selectedEntry));
    },
    handleUpdateIban: (iban: string, verkaufsprozessart: Verkaufsprozessart | null): void => {
        dispatch(updateIbanEntrySync(iban));
        dispatch(setzeIbanMeldungSync(iban, verkaufsprozessart));
    },
    handleVerifyAndUpdateBankverbindung: (iban: string): void => {
        // @ts-ignore
        dispatch(verifyAndUpdateBankverbindungAsync(iban));
    },
    handleChangeAbbuchungstag: (businessId: string, abbuchungstag: AbbuchungstagImMonat): void => {
        //@ts-ignore
        dispatch(updateAbbuchungstagAsync(businessId, abbuchungstag));
    }
});

export const Bankverbindung = connect(mapStateToProps, mapDispatchToProps)(BankverbindungPresentation);
