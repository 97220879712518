import { combineReducers } from 'redux';
import { basisdatenReducer } from './basisdaten/BasisdatenReducer';
import { exceptionsReducer } from './exceptions/ExceptionsReducer';
import { meldungenReducer } from './meldungen/MeldungenReducer';
import { personenReducer } from './personen/PersonenReducer';
import { personensucheReducer } from './personensuche/PersonensucheReducer';
import { versicherungsschutzReducer } from './versicherungsschutz/VersicherungsschutzReducer';
import { abschlussReducer } from './abschluss/AbschlussReducer';
import { bankverbindungReducer } from './bankverbindung/BankverbindungReducer';
import taxModalReducer from './taxmodal/TaxModalReducer';
import unauthenticatedReducer from './auth/unauthenticatedReducer'
import businessId from './app/actions/businessIdReducer'
import businessIdReducer from './app/actions/businessIdReducer'

export const combinedReducers = combineReducers({
    basisdaten: basisdatenReducer,
    bankverbindung: bankverbindungReducer,
    exceptions: exceptionsReducer,
    meldungen: meldungenReducer,
    personen: personenReducer,
    personensuche: personensucheReducer,
    taxmodal: taxModalReducer,
    versicherungsschutz: versicherungsschutzReducer,
    abschluss: abschlussReducer,
    unauthenticated: unauthenticatedReducer,
    businessId: businessIdReducer
});
