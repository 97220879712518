import React from 'react';
import { connect } from 'react-redux';
import { IAppState } from '../../app/IAppState';
import { MailAdresse } from '../../util/fetch/personen/PersonDto';
import { ANDERE_EMAIL, isEmail, WAEHLE_EMAIL } from '../AbschlussReducer';
import SelectRow from '@eg/elements/SelectRow';
import EmailInputRow from './EmailInputRow';
import { InputEvent, InputKeyboardEvent, SelectEvent } from '../../util/UiEventTypes';
import { Dispatch } from 'redux';
import { updateVersandmailAsync, changeVersandmailAsync } from '../AbschlussAction';
import { Person } from '../../personen/PersonenReducer';
import { AbschlussDetailInputProps } from '../Abschluss';
import { versicherungsnehmerPersonSelector } from '../../util/Selectors';
import { updateMailAdresseAsync } from '../../personen/PersonenAction';
import { versandmailSelector } from './EmailSelector';
import { EMAIL_SELECTROW, getEmailOptionId } from '../../util/RanorexIds';

interface EmailSelectionPresentationProps extends EmailSelectionOwnProps, EmailSelectionDispatchProps {}

interface EmailSelectionOwnProps {
    versandmail: string;
    versicherungsnehmer: Person | null;
    oberflaecheSperren: boolean;
}

interface EmailSelectionDispatchProps {
    handleOnChangeEmail: (email: string) => void;
    setNewVersandmail: (versicherungsnehmer: Person | null, email: string) => void;
}

interface EmailSelectionPresentationState {
    versandmail: string | undefined;
}

export class EmailSelectionPresentation extends React.Component<EmailSelectionPresentationProps, EmailSelectionPresentationState> {
    constructor(props: EmailSelectionPresentationProps) {
        super(props);
        this.state = {
            versandmail: props.versandmail
        };
    }

    componentDidUpdate(prevProps: Readonly<EmailSelectionPresentationProps>): void {
        if (this.props.versandmail && this.props.versandmail !== prevProps.versandmail && this.props.versandmail !== WAEHLE_EMAIL) {
            this.setState(() => ({
                versandmail: this.props.versandmail
            }));
        }
    }

    changeVersandmail = (event: SelectEvent) => {
        const versandmail: string = event.target.value;
        this.setState(() => ({
            versandmail: versandmail
        }));
        isEmail(versandmail) && this.props.handleOnChangeEmail(versandmail);
    };

    setNewVersandmail = (event: InputEvent | InputKeyboardEvent) => {
        const versandmail: string = event.currentTarget.value;
        this.setState(() => ({
            versandmail: versandmail
        }));
        this.props.setNewVersandmail(this.props.versicherungsnehmer, versandmail);
    };

    render() {
        const { versandmail } = this.state;
        const { oberflaecheSperren, versicherungsnehmer } = this.props;
        const mailAdressenVN: MailAdresse[] = versicherungsnehmer ? versicherungsnehmer.mailAdressen.sort(sortPrivateMailadresseZuerst) : [];

        return (
            <div>
                <p>
                    <b>{emailLabel}</b>
                </p>
                <SelectRow label="E-Mail-Adresse auswählen" value={versandmail} disabled={oberflaecheSperren} onChange={this.changeVersandmail} ranorex-id={EMAIL_SELECTROW}>
                    {versandmail === WAEHLE_EMAIL && <option key={WAEHLE_EMAIL} label={WAEHLE_EMAIL} value={WAEHLE_EMAIL} disabled={true} />}
                    {mailAdressenVN.map((mailadresse: MailAdresse) => (
                        <option key={mailadresse.adresse} label={mailadresse.adresse} value={mailadresse.adresse} ranorex-id={getEmailOptionId(mailadresse.adresse)} />
                    ))}
                    <option key={ANDERE_EMAIL} label={ANDERE_EMAIL} value={ANDERE_EMAIL} ranorex-id={getEmailOptionId(ANDERE_EMAIL)} />
                </SelectRow>
                {versandmail === ANDERE_EMAIL && <EmailInputRow disabled={oberflaecheSperren} label="E-Mail eingeben" onLeave={this.setNewVersandmail} value={''} />}
            </div>
        );
    }
}

const sortPrivateMailadresseZuerst = (a: MailAdresse, b: MailAdresse): number => (a.typ < b.typ ? 1 : -1);

const emailLabel: string = 'Bitte geben Sie eine E-Mail-Adresse für den Versand an';

const mapStateToProps = (state: IAppState, props: AbschlussDetailInputProps): EmailSelectionOwnProps => ({
    versandmail: versandmailSelector(state),
    versicherungsnehmer: versicherungsnehmerPersonSelector(state),
    oberflaecheSperren: props.oberflaecheSperren
});

const mapDispatchToProps = (dispatch: Dispatch): EmailSelectionDispatchProps => ({
    handleOnChangeEmail: (email: string) => {
        //@ts-ignore
        dispatch(changeVersandmailAsync(email));
    },
    setNewVersandmail: (versicherungsnehmer: Person | null, email: string) => {
        //@ts-ignore
        dispatch(updateVersandmailAsync(email));
        //@ts-ignore
        dispatch(updateMailAdresseAsync(versicherungsnehmer, email));
    }
});

export const EmailSelection = connect(mapStateToProps, mapDispatchToProps)(EmailSelectionPresentation);
