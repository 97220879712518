import '@eg/elements/styles/ergo-one/core.css';
import '@eg/elements/styles/ergo-one/styles.css';

import * as React from 'react';
import { applyMiddleware, createStore } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import { render } from 'react-dom';
import { Provider as ReduxProvider } from 'react-redux';
import { Provider as ErgoElementsProvider, THEME } from '@eg/elements/Provider';

import './assets/stylesheets/index.css';
import { App } from './components/app/App';
import { combinedReducers } from './components/CombineReducers';

export const theme = THEME.ergoone;

const store = createStore(combinedReducers, composeWithDevTools(applyMiddleware(thunk)));

render(
    <ReduxProvider store={store}>
        <ErgoElementsProvider theme={theme}>
            <App />
        </ErgoElementsProvider>
    </ReduxProvider>,
    document.getElementById('root')
);
