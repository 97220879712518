import React from 'react';
import { IAppState } from '../app/IAppState';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import Panel from '@eg/elements/Panel';
import { theme } from '../../index';
import { BootstrappedForm } from 'idd-react-telefonie';
import { updateIddIdAsync } from './BeratungsprotokollAction';
import { getUrl } from '../util/fetch/client/FetchBaseClient';
import { Gespraechspartner, Kanalrichtung } from '../util/fetch/offerengine/OfferEngineAngebotDto';

interface BeratungsprotokollPresentationProps extends IddProps, IddDispatchProps {}

class BeratungsprotokollPresentation extends React.Component<BeratungsprotokollPresentationProps, {}> {
    render() {
        const backendEndpoint = 'kfo/beratungsprotokoll';
        const backendUrl = getUrl(`${backendEndpoint}/${this.props.businessId}`);
        const fullname = `${this.props.gespraechspartner.vorname} ${this.props.gespraechspartner.nachname}`;
        let valueMap = new Map().set('gespraechspartner', fullname);

        const mapKanalrichtung = (kanalrichtung: Kanalrichtung): string => {
            switch (kanalrichtung) {
                case Kanalrichtung.EINGEHEND:
                    return 'Inbound';
                case Kanalrichtung.AUSGEHEND:
                    return 'Outbound';
            }
        };

        this.props.kanalrichtung !== null && valueMap.set('eingangskanal', mapKanalrichtung(this.props.kanalrichtung));

        return (
            <div>
                <div className="esc_grid">
                    <div className="esc_grid__wrapper">
                        <div className="esc_col esc_col-s-12" id="header_beratung">
                            <Panel>
                                <h2 className="esc_h4">Beratung</h2>
                            </Panel>
                        </div>
                    </div>
                </div>
                <div className="esc_grid component-content">
                    <div className="esc_grid__wrapper">
                        <div className="esc_col esc_col-s-12">
                            {// Note bn 2019-12-02: Warning 'Failed prop type: t child 't' must be one of type: Radio' in JS console is ok.
                            // Warning is only visible on localhost
                            this.props.businessId && (
                                <BootstrappedForm
                                    submitForm={(iddId: string | null) => {
                                        this.props.onLeaveCallbackIddId(this.props.businessId, iddId);
                                    }}
                                    configuration={{
                                        debugLog: false,
                                        debugFetch: false,
                                        useMock: false,
                                        url: backendUrl,
                                        allowDenial: this.props.allowDenial,
                                        submitButtonLabel: 'Beratungsprotokoll erstellen',
                                        theme: theme,
                                        submitButtonDisabled: this.props.oberflaecheSperren
                                    }}
                                    context={{
                                        kontext: 'IDD_ED_ZAHN',
                                        uuid: '',
                                        sections: [],
                                        values: valueMap
                                    }}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const scrollToHeader = (): void => {
    const header = document && document.getElementById('header_beratung');
    !header && console.warn('Beratungsprotokoll', 'Element header_beratung nicht gefunden');
    header && header.scrollIntoView();
};

interface IddProps {
    iddId: string | null;
    businessId: string;
    gespraechspartner: Gespraechspartner;
    oberflaecheSperren: boolean;
    kanalrichtung: Kanalrichtung | null;
    allowDenial: boolean;
}

const mapStateToProps = (state: IAppState, props: IddOwnProps): IddProps => ({
    iddId: state.basisdaten.beratungsprotokollId,
    businessId: state.basisdaten.businessId,
    gespraechspartner: state.basisdaten.gespraechspartner,
    oberflaecheSperren: state.abschluss.abschlussErfolgreich,
    kanalrichtung: state.basisdaten.kanalrichtung,
    allowDenial: props.allowDenial
});

interface IddDispatchProps {
    onLeaveCallbackIddId: (businessId: string, iddId: string | null) => void;
}

interface IddOwnProps {
    allowDenial: boolean;
}

const mapDispatchToProps = (dispatch: Dispatch): IddDispatchProps => ({
    onLeaveCallbackIddId: (businessId: string, iddId: string | null) => {
        if (iddId === null) {
            scrollToHeader();
        } else {
            // @ts-ignore
            dispatch(updateIddIdAsync(businessId, iddId));
        }
    }
});
export const Beratungsprotokoll = connect(mapStateToProps, mapDispatchToProps)(BeratungsprotokollPresentation);
