import { Dispatch } from 'redux';
import { onFulfilledStoreOffer } from '../app/AppAction';
import { onRejectedStoreException } from '../exceptions/ExceptionsAction';
import { updateVersicherungsbeginnHttpRequest, updateZahlweiseHttpRequest } from '../util/fetch/offerengine/OfferEngineController';
import { changeDateFormat } from '../util/DateFormattingHelper';
import { UpdateVersicherungRequestDto, VersicherungOptionalRequestDto } from '../util/fetch/offerengine/UpdateVersicherungRequestDto';
import { OfferEngineAngebotDto, Zahlweise } from '../util/fetch/offerengine/OfferEngineAngebotDto';
import { FrontendResponse } from '../util/fetch/client/FrontendResponse';
import { createVersicherungenRequest } from '../util/UpdateOfferEngineHelper';

export const AENDERE_VERSICHERUNGSFELD: string = 'AENDERE_VERSICHERUNGSFELD';
export type AENDERE_VERSICHERUNGSFELD = typeof AENDERE_VERSICHERUNGSFELD;

export interface ChangeVersicherungFieldAction {
    type: AENDERE_VERSICHERUNGSFELD;
    payload: {
        fieldName: string;
        fieldValue: string | Date | undefined;
    };
}

export const changeVersicherungFieldSync = (fieldName: string, fieldValue: string | Date | undefined): ChangeVersicherungFieldAction => {
    return {
        type: AENDERE_VERSICHERUNGSFELD,
        payload: {
            fieldName: fieldName,
            fieldValue: fieldValue
        }
    };
};

export const updateVersicherungsbeginnAsync = (businessId: string, versicherungsbeginn: string, versicherungsId: string) => {
    return (dispatch: Dispatch) => {
        dispatch(changeVersicherungFieldSync('versicherungsbeginn', versicherungsbeginn));

        const payload: VersicherungOptionalRequestDto = {
            versicherungsbeginn: changeDateFormat(versicherungsbeginn)
        };

        const request: UpdateVersicherungRequestDto = createVersicherungenRequest(payload, versicherungsId);
        return updateVersicherungsbeginnHttpRequest(businessId, request, dispatch)
            .then((response: FrontendResponse<OfferEngineAngebotDto>) => onFulfilledStoreOffer(response, dispatch))
            .catch((e: Error) => onRejectedStoreException(e, dispatch, 'VersicherungsschutzAction -> updateVersicherungsbeginnAsync'));
    };
};

export const updateZahlweiseAsync = (businessId: string, zahlweise: Zahlweise, versicherungsId: string) => {
    return (dispatch: Dispatch) => {
        dispatch(changeVersicherungFieldSync('zahlweise', zahlweise));

        const payload: VersicherungOptionalRequestDto = {
            zahlweise: zahlweise
        };

        const request: UpdateVersicherungRequestDto = createVersicherungenRequest(payload, versicherungsId);
        return updateZahlweiseHttpRequest(businessId, request, dispatch)
            .then((response: FrontendResponse<OfferEngineAngebotDto>) => onFulfilledStoreOffer(response, dispatch))
            .catch((e: Error) => onRejectedStoreException(e, dispatch, 'VersicherungsschutzAction -> updateZahlweiseAsync'));
    };
};
