import { Dispatch } from 'redux';
import { updateBeratungsprotokollHttpRequest } from '../util/fetch/offerengine/OfferEngineController';
import { onRejectedStoreException } from '../exceptions/ExceptionsAction';
import { onFulfilledStoreOffer } from '../app/AppAction';

export function updateIddIdAsync(businessId: string, beratungsprotokollId: string) {
    return (dispatch: Dispatch) => {
        return updateBeratungsprotokollHttpRequest(
            businessId,
            {
                beratungsprotokollId
            },
            dispatch
        )
            .then(response => onFulfilledStoreOffer(response, dispatch))
            .catch(e => onRejectedStoreException(e, dispatch));
    };
}
