import { Mandant, PartnerReferenz, PartnerSystem, PdsPerson } from '../util/fetch/personen/PersonDto';
import { Person, Personen } from './PersonenReducer';

export const mapPdsPersonToStatePerson = (person: PdsPerson, personen: Personen): Person => {
    const ergodirektPartnerreferenz = person.partnerReferenzen.find((referenz: PartnerReferenz) => referenz.partnerSystem === PartnerSystem.ergodirekt);

    return {
        ...personen[person.personId],
        kundennummerParis: ergodirektPartnerreferenz ? ergodirektPartnerreferenz.kundennummer : null,
        pdsId: person.personId,
        geburtsdatum: person.geburtsdatum,
        anrede: person.anrede,
        nachname: person.nachname,
        vorname: person.vorname,
        titel: person.titel,
        mailAdressen: person.mailAdressen,
        adresse: person.adresse,
        bankverbindungen: person.bankverbindungen,
        rollen: personen[person.personId] && personen[person.personId].rollen ? personen[person.personId].rollen : [],

        // Nicht verwendete Felder, die wir nur brauchen, nur um es spaeter ggf wieder in den PDS zu schreiben:
        telefonnummer: person.telefonnummer,
        adressnummer: ergodirektPartnerreferenz ? ergodirektPartnerreferenz.adressnummer : 1,
        kennzeichen: person.kennzeichen
    };
};

export const mapStatePersonToPdsPerson = (person: Person, businessId: string): PdsPerson => {
    const partnerReferenzen: PartnerReferenz[] = person.kundennummerParis
        ? [
              {
                  adressnummer: person.adressnummer,
                  kundennummer: person.kundennummerParis,
                  partnerSystem: PartnerSystem.ergodirekt
              }
          ]
        : [];

    return {
        adresse: person.adresse,
        anrede: person.anrede,
        bankverbindungen: person.bankverbindungen,
        businessId: businessId,
        geburtsdatum: person.geburtsdatum,
        geburtsort: '',
        mailAdressen: person.mailAdressen,
        mandant: Mandant.ergodirekt,
        nachname: person.nachname,
        personId: person.pdsId,
        staatsangehoerigkeit: '',
        telefonnummer: person.telefonnummer,
        titel: person.titel,
        vorname: person.vorname,
        partnerReferenzen: partnerReferenzen,
        kennzeichen: person.kennzeichen
    };
};
