import useInterval from 'use-interval';
import { useEffect, useState } from 'react';
import React from 'react';
import { REFRESH_ENDPOINT } from './constants'

export const RefreshUserToken: React.FC = () => {
  const REFRESH_INTERVAL_IN_MILISECS = 15000; // 15 seconds
  const [intervalTime, setIntervalTime] = useState<number | null>(REFRESH_INTERVAL_IN_MILISECS);
  const [key, setKey] = useState<number>(0);
  const iframeSrc = REFRESH_ENDPOINT;

  useEffect(() => {
    return () => setIntervalTime(null);
  },        []);

  useInterval(
    () => {
      // tslint:disable-next-line:no-shadowed-variable
      setKey((key) => {
        const nextKey = key + 1;
        return Number.isSafeInteger(nextKey) ? nextKey : 0;
      });
    },
    intervalTime,
    false
  );

  return (
    <iframe
      data-testid={`refresh-user-token-${key}`}
      style={{position: 'absolute', right: 0, width: 0, height: 0, visibility: 'hidden'}}
      title={'refresh-user-token'}
      src={iframeSrc}
      key={key}
    />
  );
};
