export interface ErrorObject {
    ursprung: Ursprung;
    url?: string;

    message: string;
    className?: string;
    causeMessage?: string;
    causeClassName?: string;
    httpStatusCode?: number;
    httpStatusPhrase?: string;
    umsystem?: string;
    abortOfferReason?: string;
}

export enum Ursprung {
    URSPRUNG_BFF = 'BFF',
    URSPRUNG_FETCH_CLIENT = 'FETCH_CLIENT',
    URSPRUNG_RECEIVED_PROMISE = 'RECEIVED_PROMISE'
}
