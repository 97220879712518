import { Dispatch } from 'redux';
import { getParisKundeHttpRequest, selectParisKundeHttpRequest } from '../util/fetch/personensuche/PersonensucheController';
import { FrontendResponse } from '../util/fetch/client/FrontendResponse';
import { ParisKunde, PersonensucheDto } from '../util/fetch/personensuche/PersonensucheDto';
import { showMaxNumberVPSync, speicherPersonSync } from '../personen/PersonenAction';
import { isEmpty } from '../util/validate';
import { IAppState } from '../app/IAppState';
import { Person, PersonenState } from '../personen/PersonenReducer';
import { alleRollenBisher, PersonAnlegenRequestDto, PersonAnlegenResponseDto } from '../util/fetch/personensuche/PersonRollenDto';
import { storeOfferSync } from '../app/AppAction';
import { PdsPerson } from '../util/fetch/personen/PersonDto';
import { onRejectedStoreException } from '../exceptions/ExceptionsAction';
import { ErrorObject } from '../util/fetch/client/ErrorObject';
import { isMaxNumberVPGiven, maxNumberVPErreicht, remainingNumberVP } from '../util/PersonenHelper';

export const EINGABE_KUNDENNUMMER: string = 'EINGABE_KUNDENNUMMER';
export type EINGABE_KUNDENNUMMER = typeof EINGABE_KUNDENNUMMER;
export const ZEIGE_SUCHERGEBNIS: string = 'ZEIGE_SUCHERGEBNIS';
export type ZEIGE_SUCHERGEBNIS = typeof ZEIGE_SUCHERGEBNIS;
export const SETZE_PERSONENSUCHE_ZURUECK: string = 'SETZE_PERSONENSUCHE_ZURUECK';
export type SETZE_PERSONENSUCHE_ZURUECK = typeof SETZE_PERSONENSUCHE_ZURUECK;
export const ZEIGE_LOADINGSPINNER: string = 'ZEIGE_LOADINGSPINNER';
export type ZEIGE_LOADINGSPINNER = typeof ZEIGE_LOADINGSPINNER;
export const ZEIGE_SAVINGSPINNER: string = 'ZEIGE_SAVINGSPINNER';
export type ZEIGE_SAVINGSPINNER = typeof ZEIGE_SAVINGSPINNER;
export const ZEIGE_ADRESSE_INVALID: string = 'ZEIGE_ADRESSE_INVALID';
export const SETZE_MELDUNG_VP: string = 'SETZE_MELDUNG_VP';
export type SETZE_MELDUNG_VP = typeof SETZE_MELDUNG_VP;

export interface EnterKundennummerAction {
    type: EINGABE_KUNDENNUMMER;
    kundennummer: string;
}

export const enterKundennummerSync = (kundennummer: string): EnterKundennummerAction => {
    return {
        type: EINGABE_KUNDENNUMMER,
        kundennummer: kundennummer
    };
};

export interface ShowParisKundeAction {
    type: ZEIGE_SUCHERGEBNIS;
    kunde: ParisKunde | null;
    errormessage: string;
}

const showParisKundeSync = (kunde: ParisKunde | null, errormessage: string): ShowParisKundeAction => {
    return {
        type: ZEIGE_SUCHERGEBNIS,
        kunde: kunde,
        errormessage: errormessage
    };
};

export interface ShowPersonIsLoadingAction {
    type: ZEIGE_LOADINGSPINNER;
    showSpinner: boolean;
}

const showPersonIsLoadingSync = (showSpinner: boolean): ShowPersonIsLoadingAction => {
    return {
        type: ZEIGE_LOADINGSPINNER,
        showSpinner: showSpinner
    };
};

export interface ShowPersonIsSavingAction {
    type: ZEIGE_SAVINGSPINNER;
    showSpinner: boolean;
}

const showPersonIsSavingSync = (showSpinner: boolean): ShowPersonIsSavingAction => {
    return {
        type: ZEIGE_SAVINGSPINNER,
        showSpinner: showSpinner
    };
};

export const getParisKundeAsync = (businessId: string, kundennummer: string) => {
    return (dispatch: Dispatch) => {
        const errormessage: string = validateKundennummer(kundennummer);

        if (!isEmpty(errormessage)) {
            dispatch(showParisKundeSync(null, errormessage));
        } else {
            let waitForResponse = setTimeout(() => {
                dispatch(showPersonIsLoadingSync(true));
            }, 300);

            return getParisKundeHttpRequest(businessId, kundennummer, dispatch)
                .then((response: FrontendResponse<PersonensucheDto>) => {
                    clearTimeout(waitForResponse);
                    response.payload && response.payload.parisKunde
                        ? dispatch(showParisKundeSync(response.payload.parisKunde, ''))
                        : dispatch(showParisKundeSync(null, 'Zur eingegebenen Kundennummer konnte kein Kunde in Paris gefunden werden!'));
                })
                .catch((e: Error) => {
                    clearTimeout(waitForResponse);
                    onRejectedStoreException(e, dispatch, 'getParisKundeAsync');
                })
                .finally(() => {
                    dispatch(showPersonIsLoadingSync(false));
                });
        }
    };
};

const validateKundennummer = (kundennummer: string): string => {
    if (isEmpty(kundennummer)) {
        return '';
    }

    if (Number.isNaN(Number(kundennummer))) {
        return `Kundennummer ${kundennummer} ist keine Nummer!`;
    }

    if (kundennummer.length !== 8) {
        return `Kundennummer ${kundennummer} ist keine Paris-Kundennummer!`;
    }

    return '';
};

export const selectKundeAsync = (businessId: string, kunde: ParisKunde) => {
    return (dispatch: Dispatch, getState: () => IAppState) => {
        const bereitsHinzugefuegteParisKundennummer: boolean = Object.values(getState().personen.personen).some(
            (person: Person) => person.kundennummerParis === String(kunde.kundennummer)
        );

        if (maxNumberVPErreicht(getState().personen)) {
            dispatch(showMaxNumberVPSync(true));
            return;
        }

        if (bereitsHinzugefuegteParisKundennummer) {
            dispatch(showParisKundeSync(null, `Der Kunde mit der Kundennummer ${kunde.kundennummer} wurde bereits hinzugefügt.`));
            return;
        }

        const payload: PersonAnlegenRequestDto = {
            parisKunde: kunde,
            alleRollenBisher: alleRollenBisher(getState().personen.personen)
        };

        let waitForResponse = setTimeout(() => {
            dispatch(showPersonIsSavingSync(true));
        });

        return selectParisKundeHttpRequest(businessId, payload, dispatch)
            .then((response: FrontendResponse<PersonAnlegenResponseDto | ErrorObject>) => {
                if (response.payload) {
                    if (payloadIsPersonanlegenResponseDto(response.payload)) {
                        const newPerson: PdsPerson = response.payload.pdsPerson;

                        dispatch(speicherPersonSync(newPerson));
                        clearTimeout(waitForResponse);
                        dispatch(resetPersonensucheSync());
                        dispatch(storeOfferSync(response.payload.angebot));
                        //@ts-ignore
                        dispatch(setRemainingVPsMessageAsync());
                    } else if (payloadIsErrorObject(response.payload)) {
                        dispatch(resetPersonensucheSync());
                        dispatch(showAddressIsInvalidSync(true, response.payload.message));
                    } else {
                        dispatch(resetPersonensucheSync());
                    }
                }
            })
            .catch(e => {
                clearTimeout(waitForResponse);
                onRejectedStoreException(e, dispatch, 'selectKundeAsync');
            })
            .finally(() => {
                dispatch(showPersonIsSavingSync(false));
            });
    };
};

const payloadIsErrorObject = (toBeDetermined: any): toBeDetermined is ErrorObject =>
    // Note: http://www.typescriptlang.org/docs/handbook/advanced-types.html#type-guards-and-differentiating-types
    (toBeDetermined as ErrorObject).message !== undefined && (toBeDetermined as ErrorObject).className !== undefined;

const payloadIsPersonanlegenResponseDto = (toBeDetermined: any): toBeDetermined is PersonAnlegenResponseDto =>
    // Note: http://www.typescriptlang.org/docs/handbook/advanced-types.html#type-guards-and-differentiating-types
    (toBeDetermined as PersonAnlegenResponseDto).pdsPerson !== undefined && (toBeDetermined as PersonAnlegenResponseDto).angebot !== undefined;

export interface ResetPersonensucheAction {
    type: SETZE_PERSONENSUCHE_ZURUECK;
}

const resetPersonensucheSync = (): ResetPersonensucheAction => {
    return {
        type: SETZE_PERSONENSUCHE_ZURUECK
    };
};

export interface ShowAddressIsInvalidAction {
    type: typeof ZEIGE_ADRESSE_INVALID;
    payload: {
        display: boolean;
        message: string;
    };
}

export const showAddressIsInvalidSync = (display: boolean, message: string): ShowAddressIsInvalidAction => {
    return {
        type: ZEIGE_ADRESSE_INVALID,
        payload: {
            display,
            message
        }
    };
};

export interface SetRemainingVPsMessageAction {
    type: SETZE_MELDUNG_VP;
    message: string;
}

export const setRemainingVPsMessageSync = (message: string): SetRemainingVPsMessageAction => {
    return {
        type: SETZE_MELDUNG_VP,
        message
    };
};

export const setRemainingVPsMessageAsync = () => {
    return (dispatch: Dispatch, getstate: () => IAppState) => {
        const personenState: PersonenState = getstate().personen;
        if (isMaxNumberVPGiven(personenState)) {
            const counter: number = remainingNumberVP(personenState);
            let text: string;
            switch (counter) {
                case 0:
                    text = 'Es kann keine weitere Person versichert werden.';
                    break;
                case 1:
                    text = `Es kann noch ${counter} weitere Person versichert werden.`;
                    break;
                default:
                    text = `Es können noch ${counter} weitere Personen versichert werden.`;
                    break;
            }
            dispatch(setRemainingVPsMessageSync(text));
            return;
        }
        return;
    };
};
