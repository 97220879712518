import React from 'react';
import { IAppState } from '../app/IAppState';
import { connect } from 'react-redux';
import Card from '@eg/elements/Card';
import Button from '@eg/elements/Button';
import ArrowDownIcon from '@eg/elements/components/Icons/ArrowDownIcon';
import ArrowUpIcon from '@eg/elements/components/Icons/ArrowUpIcon';
import { ErrorObject } from '../util/fetch/client/ErrorObject';

interface ExceptionsPresentationProps {
    errorObject: ErrorObject | undefined;
}

interface ExceptionsPresentationState {
    showDetails: boolean;
}

export class ExceptionsPresentation extends React.Component<ExceptionsPresentationProps, ExceptionsPresentationState> {
    constructor(props: ExceptionsPresentationProps) {
        super(props);
        this.state = {
            showDetails: false
        };
    }

    handleClickButton = () => {
        this.setState((prevState: ExceptionsPresentationState) => ({
            ...prevState,
            showDetails: !prevState.showDetails
        }));
    };

    render() {
        const { errorObject } = this.props;
        const { showDetails } = this.state;

        if (errorObject && errorObject.abortOfferReason) {
            const messages: string[] = errorObject.abortOfferReason.split('\n');

            return (
                <Card className="exceptions" accentColor="#71022e" label={'Ein fachlicher Fehler liegt vor'}>
                    <p />
                    {messages.map((message: string, index: number) => (
                        <p className="esc_h5" key={message ? message : index}>
                            {message}
                        </p>
                    ))}
                </Card>
            );
        }

        return (
            <Card className="exceptions" accentColor="#71022e" label={'Ein technischer Fehler liegt vor'}>
                <p />
                <p className="esc_h5">
                    Bitte probieren Sie es später erneut oder wenden Sie sich an die 'ERGO Direkt-Hotline <span className="esc_h4">0911 - 148-1010</span>
                    .'
                </p>
                <div className="exceptions-details">
                    <Button variant="text-link" iconRight={showDetails ? ArrowUpIcon : ArrowDownIcon} onClick={this.handleClickButton}>
                        Details
                    </Button>
                    {showDetails && errorObject !== undefined && (
                        <div className="exceptions-details-content">
                            <h2 className="esc_h5">
                                <u>Fehlerdetails:</u>
                            </h2>
                            <div>
                                <table
                                    style={{
                                        borderSpacing: '0px'
                                    }}
                                >
                                    <tbody>
                                        <tr>
                                            <td>Message</td>
                                            <td>: {errorObject.message}</td>
                                        </tr>
                                        <tr />
                                        <tr>
                                            <td>ClassName</td>
                                            <td>: {errorObject.className}</td>
                                        </tr>
                                        <tr />
                                        <tr>
                                            <td>&nbsp;</td>
                                            <td>&nbsp;</td>
                                        </tr>
                                        <tr />
                                        <tr>
                                            <td>Cause-Message</td>
                                            <td>: {errorObject.causeMessage}</td>
                                        </tr>
                                        <tr />
                                        <tr>
                                            <td>Cause-ClassName</td>
                                            <td>: {errorObject.causeClassName}</td>
                                        </tr>
                                        <tr />
                                        <tr>
                                            <td>&nbsp;</td>
                                            <td>&nbsp;</td>
                                        </tr>
                                        <tr />
                                        <tr>
                                            <td>HTTP-Statusphrase</td>
                                            <td>: {errorObject.httpStatusPhrase}</td>
                                        </tr>
                                        <tr />
                                        <tr>
                                            <td>HTTP-Statuscode</td>
                                            <td>: {errorObject.httpStatusCode}</td>
                                        </tr>
                                        <tr />
                                        <tr>
                                            <td>&nbsp;</td>
                                            <td>&nbsp;</td>
                                        </tr>
                                        <tr />
                                        <tr>
                                            <td>Umsystem</td>
                                            <td>: {errorObject.umsystem}</td>
                                        </tr>
                                        <tr />
                                        <tr>
                                            <td>Request-URL</td>
                                            <td>: {errorObject.url}</td>
                                        </tr>
                                        <tr />
                                        <tr>
                                            <td>Fehler-Ursprung</td>
                                            <td>: {errorObject.ursprung}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    )}
                </div>
            </Card>
        );
    }
}

const mapStateToProps = (state: IAppState): ExceptionsPresentationProps => ({
    errorObject: state.exceptions.errorObject
});

export const Exceptions = connect(mapStateToProps)(ExceptionsPresentation);
