import React from 'react';
import { IAppState } from '../../app/IAppState';
import { connect } from 'react-redux';
import SalesmanButton from '@eg/elements/SalesmanButton';
import { Hinweis, Versicherung } from '../../util/fetch/offerengine/OfferEngineAngebotDto';
import Checkbox from '@eg/elements/Checkbox';
import { CheckboxEvent } from '../../util/UiEventTypes';
import { Dispatch } from 'redux';
import { DirektabschlussState } from '../AbschlussReducer';
import { AbschlussDetailInputProps } from '../Abschluss';
import { changeAlleHinweiseBestaetigtSync, updateBestaetigeAlleHinweiseAsync, updateBestaetigeHinweisSync, doAbschluss } from '../AbschlussAction';
import { hasValue } from '../../util/validate';
import { DIREKTABSCHLUSS_BUTTON, getDirektabschlussHinweisId } from '../../util/RanorexIds';
import LoadingSpinner from '@eg/elements/LoadingSpinner';

interface DirektabschlussPresentationProps extends DirektabschlussOwnProps, DirektAbschlussDispatchProps {}

export class DirektAbschlussPresentation extends React.Component<DirektabschlussPresentationProps, {}> {
    createHinweisCheckbox = (hinweis: Hinweis, onChange = (e: CheckboxEvent) => {}): JSX.Element => {
        let className: string = 'hinweis';
        let label: string = hinweis.hinweisText;

        if (hasValue(hinweis.pflichtText)) {
            className = 'hinweis-bold';
            label = hinweis.pflichtText;
        }

        return (
            <div key={hinweis.hinweisart} className={className}>
                <Checkbox
                    name="hinweis"
                    label={<div
                        dangerouslySetInnerHTML={{__html: label}}
                      />}
                    checked={hinweis.bestaetigt}
                    disabled={this.props.oberflaecheSperren}
                    onChange={onChange}
                    ranorex-id={getDirektabschlussHinweisId(hinweis.hinweisart)}
                />
            </div>
        );
    };

    // Note 2019-08-05 bn: MAybe refactor to call api on each onChange Event.
    handleOnChangeCheckbox = (hinweis: Hinweis, checked: boolean): void => {
        const { versicherung, handleOnCheckHinweis, handleOnAllHinweiseChecked } = this.props;
        const { hinweise } = versicherung!;

        const index = hinweise.indexOf(hinweis);

        hinweis.bestaetigt = checked;

        handleOnCheckHinweis(hinweis, index);

        hinweise[index].bestaetigt = checked;
        if (hinweise.every((h: Hinweis) => h.bestaetigt)) {
            handleOnAllHinweiseChecked(versicherung!);
        }
    };

    render() {
        const {
            versicherung,
            oberflaecheSperren,
            handleOnAntragAbschliessenClick,
            alleHinweiseBestaetigt,
            hasErrorMeldungen,
            disableAbschlussButton,
            setButtonLoading,
            setSpinner
        } = this.props;

        const hinweise = versicherung !== null ? versicherung.hinweise : [];

        const hasHinweise: boolean = hinweise && hinweise.length > 0;

        const showfirstHinweis: boolean = !hasErrorMeldungen && hasHinweise;

        const firstHinweis: Hinweis | null = hasHinweise ? hinweise[0] : null;

        const allHinweiseVisible: boolean = firstHinweis != null && firstHinweis.bestaetigt;

        return (
            <div className="esc_grid component-content">
                <div className="esc_grid__wrapper">
                    <div className="esc_col esc_col-s-12">
                        <div className="abschluss-hinweis">
                            {// create first checkbox
                            showfirstHinweis &&
                                firstHinweis &&
                                this.createHinweisCheckbox(firstHinweis, (e: CheckboxEvent) => this.handleOnChangeCheckbox(firstHinweis, e.target.checked))}
                            {// create remaining checkboxes
                            hasHinweise &&
                                allHinweiseVisible &&
                                hinweise
                                    .slice(1)
                                    .map((hinweis: Hinweis) => this.createHinweisCheckbox(hinweis, (e: CheckboxEvent) => this.handleOnChangeCheckbox(hinweis, e.target.checked)))}
                        </div>
                        <LoadingSpinner show={setSpinner}/>
                        <SalesmanButton
                            text="Vertrag abschließen"
                            subText=""
                            note=""
                            id="header_button"
                            loading={setButtonLoading}
                            disabled={oberflaecheSperren || hasErrorMeldungen || !alleHinweiseBestaetigt || disableAbschlussButton}
                            onClick={() => {
                                handleOnAntragAbschliessenClick();
                            }}
                            ranorex-id={DIREKTABSCHLUSS_BUTTON}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

const scrollToHeader = (): void => {
    const header = document && document.getElementById('header_button');
    !header && console.warn('Direktabschluss', 'Element header_button nicht gefunden');
    header && header.scrollIntoView();
};

interface DirektabschlussOwnProps extends DirektabschlussState {
    hasErrorMeldungen: boolean;
    oberflaecheSperren: boolean;
    disableAbschlussButton: boolean;
    setSpinner: boolean
}

const mapStateToProps = (state: IAppState, props: AbschlussDetailInputProps): DirektabschlussOwnProps => ({
    alleHinweiseBestaetigt: state.abschluss.direktAbschluss.alleHinweiseBestaetigt,
    oberflaecheSperren: props.oberflaecheSperren,
    hasErrorMeldungen: props.hasErrorMeldungen ? props.hasErrorMeldungen : false,
    versicherung: props.versicherung ? props.versicherung : null,
    disableAbschlussButton: props.disableAbschlussButton ? props.disableAbschlussButton : false,
    setButtonLoading: state.abschluss.direktAbschluss.setButtonLoading,
    setSpinner: state.abschluss.setSpinner
});

interface DirektAbschlussDispatchProps {
    handleOnCheckHinweis: (hinweis: Hinweis, index: number) => void;
    handleOnAllHinweiseChecked: (versicherung: Versicherung) => void;
    handleOnAntragAbschliessenClick: () => void;
}

const mapDispatchToProps = (dispatch: Dispatch): DirektAbschlussDispatchProps => ({
    handleOnCheckHinweis: (hinweis: Hinweis, index: number): void => {
        dispatch(updateBestaetigeHinweisSync(hinweis, index));
        dispatch(changeAlleHinweiseBestaetigtSync(false));
    },
    handleOnAllHinweiseChecked: (versicherung: Versicherung) => {
        // @ts-ignore
        dispatch(updateBestaetigeAlleHinweiseAsync(versicherung));
    },
    handleOnAntragAbschliessenClick: () => {
        // @ts-ignore
        dispatch(doAbschluss());
        scrollToHeader();
    }
});

export const Direktabschluss = connect(mapStateToProps, mapDispatchToProps)(DirektAbschlussPresentation);
