export const relativesList = [
    'Ehemann',
    'Ehefrau',
    'Eingetragener Lebenspartner',
    'Eingetragene Lebenspartnerin',
    'Partner einer eheähnlichen oder lebenspartnerschaftsähnlichen Gemeinschaft',
    'Partnerin einer eheähnlichen oder lebenspartnerschaftsähnlichen Gemeinschaft',
    'Verlobte',
    'Verlobter',
    'Tochter',
    'Sohn',
    'Schwiegertochter = Ehemann/Ehefrau der Tochter',
    'Schwiegersohn = Ehefrau/Ehemann des Sohnes',
    'Eingetragene/r Lebenspartner/in der Tochter',
    'Eingetragene/r Lebenspartner/in des Sohnes',
    'Enkeltochter',
    'Enkelsohn',
    'Urenkeltochter',
    'Urenkelsohn',
    'Adoptivtochter',
    'Adoptivsohn',
    'Pflegetochter',
    'Pflegesohn',
    'Urgroßmutter',
    'Urgroßvater',
    'Großmutter',
    'Großvater',
    'Mutter',
    'Vater',
    'Pflegemutter',
    'Pflegevater',
    'Stiefmutter',
    'Stiefvater',
    'Schwiegermutter = Mutter des Ehemanns oder der Ehefrau',
    'Schwiegervater = Vater des Ehemanns oder der Ehefrau',
    'Mutter der eingetragenen Lebenspartnerin oder des eingetragenen Lebenspartners',
    'Vater der eingetragenen Lebenspartnerin oder des eingetragenen Lebenspartners',
    'Großmutter des Ehemanns oder der Ehefrau',
    'Großvater des Ehemanns oder der Ehefrau',
    'Großmutter der eingetragenen Lebenspartnerin oder des eingetragenen Lebenspartners',
    'Großvater der eingetragenen Lebenspartnerin oder des eingetragenen Lebenspartners',
    'Ur-Großmutter des Ehemanns oder der Ehefrau',
    'Ur-Großvater des Ehemanns oder der Ehefrau',
    'Ur-Großmutter der eingetragenen Lebenspartnerin oder des eingetragenen Lebenspartners',
    'Ur-Großvater der eingetragenen Lebenspartnerin oder des eingetragenen Lebenspartners',
    'Schwester',
    'Bruder',
    'Halbschwester',
    'Halbbruder',
    'Schwägerin/Schwager = Ehefrau/mann des Bruders',
    'Schwägerin/Schwager = Ehemann/frau der Schwester',
    'Schwägerin/Schwager = Ehefrau/mann des Halbbruders',
    'Schwägerin/Schwager = Ehemann/frau der Halbschwester',
    'Eingetragene/r Lebenspartner/in des Bruders',
    'Eingetragene/r Lebenspartner/in der Schwester',
    'Eingetragene/r Lebenspartner/in des Halbbruders',
    'Eingetragene/r Lebenspartner/in der Halbschwester',
    'Schwägerin = Schwester der Ehefrau oder des Ehemanns',
    'Schwager = Bruder der Ehefrau oder des Ehemanns',
    'Halbschwester der Ehefrau oder des Ehemanns',
    'Halbbruder der Ehefrau oder des Ehemanns',
    'Schwester der eingetragenen Lebenspartnerin oder des eingetragenen Lebenspartners',
    'Bruder der eingetragenen Lebenspartnerin oder des eingetragenen Lebenspartners',
    'Halbschwester der eingetragenen Lebenspartnerin oder des eingetragenen Lebenspartners',
    'Halbbruder der eingetragenen Lebenspartnerin oder des eingetragenen Lebenspartners',
    'Tochter der Ehefrau',
    'Sohn der Ehefrau',
    'Tochter des Ehemanns',
    'Sohn des Ehemanns',
    'Tochter der eingetragenen Lebenspartnerin',
    'Sohn der eingetragenen Lebenspartnerin',
    'Tochter des eingetragenen Lebenspartners',
    'Sohn des eingetragenen Lebenspartners',
    'Adoptiv-Tochter der Ehefrau',
    'Adoptiv-Sohn der Ehefrau',
    'Adoptiv-Tochter des Ehemanns',
    'Adoptiv-Sohn des Ehemanns',
    'Adoptiv-Tochter der eingetragenen Lebenspartnerin',
    'Adoptiv-Sohn der eingetragenen Lebenspartnerin',
    'Adoptiv-Tochter des eingetragenen Lebenspartners',
    'Adoptiv-Sohn des eingetragenen Lebenspartners',
    'Pflege-Tochter der Ehefrau',
    'Pflege-Sohn der Ehefrau',
    'Pflege-Tochter des Ehemanns',
    'Pflege-Sohn des Ehemanns',
    'Pflege-Tochter der eingetragenen Lebenspartnerin',
    'Pflege-Sohn der eingetragenen Lebenspartnerin',
    'Pflege-Tochter des eingetragenen Lebenspartners',
    'Pflege-Sohn des eingetragenen Lebenspartners',
    'Enkeltochter der Ehefrau oder des Ehemanns',
    'Enkelsohn der Ehefrau oder des Ehemanns',
    'Enkeltochter der eingetragenen Lebenspartnerin oder des eingetragenen Lebenspartners',
    'Enkelsohn der eingetragenen Lebenspartnerin oder des eingetragenen Lebenspartners',
    'Ur-Enkeltochter der Ehefrau oder des Ehemanns',
    'Ur-Enkelsohn der Ehefrau oder des Ehemanns',
    'Ur-Enkeltochter der eingetragenen Lebenspartnerin oder des eingetragenen Lebenspartners',
    'Ur-Enkelsohn der eingetragenen Lebenspartnerin oder des eingetragenen Lebenspartners',
    'Nichte = Tochter der Schwester oder des Bruders',
    'Neffe = Sohn der Schwester oder des Bruders',
    'Nichte = Tochter der Halb-Schwester oder des Halb-Bruders',
    'Neffe = Sohn der Halb-Schwester oder des Halb-Bruders',
    'Adoptiv-Tochter der Schwester oder des Bruders',
    'Adoptiv-Sohn der Schwester oder des Bruders',
    'Adoptiv-Tochter der Halb-Schwester oder des Halb-Bruders',
    'Adoptiv-Sohn der Halb-Schwester oder des Halb-Bruders',
    'Tante = Schwester der Mutter oder des Vaters',
    'Onkel = Bruder der Mutter oder des Vaters',
    'Halb-Schwester der Mutter oder des Vaters',
    'Halb-Bruder der Mutter oder des Vaters',
    'Sonstige: Nicht in einem Vertrag zulässig, da hier Versicherungssteuerpflicht!'
];
