import { UpdateVersicherungRequestDto, VersicherungOptionalRequestDto } from './fetch/offerengine/UpdateVersicherungRequestDto';

export const createVersicherungenRequest = (payload: VersicherungOptionalRequestDto, versicherungsId: string): UpdateVersicherungRequestDto => ({
    versicherungen: [
        {
            versicherungsId,
            ...payload
        }
    ]
});
