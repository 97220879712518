import { PdsPerson } from '../personen/PersonDto';

export interface OfferEngineAngebotDto {
    versions: ProductVersions;
    angebot: OfferEngineAngebot;
    personen: PdsPerson[];
}

export interface OfferEnginePersonDataDto {
    angebot: OfferEngineAngebot;
    pdsPerson: PdsPerson;
}

export interface ProductVersions {
    offerEngineBaseVersion: string;
    frontendVersion: string;
}

export interface OfferEngineAngebot {
    abbuchungstagImMonat: AbbuchungstagImMonat;
    adsId: string;
    aenderungsdatum: string;
    aktionsnummer: string;
    haustarif: boolean;
    angebotsnummer: string;
    angebotsstatusaenderung: string;
    bankverbindung: Bankverbindung;
    beitraege: Beitrag[];
    beitragstabelle: Beitragstabelle[];
    beratungsprotokollId: string;
    businessId: string;
    erstelldatum: string;
    gespraechspartner: Gespraechspartner;
    historiennummer: string;
    kanalrichtung: Kanalrichtung | null;
    kundenberater: Kundenberater;
    kundennummer: string;
    leistungsvereinbarungen: Leistungsvereinbarung[];
    link: Link;
    mandant: string;
    quellsystem: Quellsystem;
    rollen: Rolle[];
    sparte: string;
    tarifgeneration: string;
    ursprungAngebotsnummer: string;
    verkaufsname: string;
    verkaufsprozessart: Verkaufsprozessart;
    vermittler: Vermittler;
    versandmail: string;
    versandweg: Versandweg;
    versicherungen: Versicherung[];
    vertriebskanal: Vertriebskanal;
    vsnrErgoDirekt: string;
    vsnrRisikotraeger: string;
    wertebereiche: WertebereichAngebot[];
    rollenWertebereiche: Rollenwertebereich[];
    rabatt: Rabatt;
    zuschlaegeNachlaesse: ZuschlagNachlass[] | null;
}

export interface Bankverbindung {
    bankname: string;
    bic: string;
    iban: string;
    kontoinhaber: string;
    isSelected: boolean;
    paymentId: string;
}

export interface Beitrag {
    bruttobeitragNachZahlweise: number | null;
    struktur: Struktur;
}

export interface Beitragstabelle {
    leistungsvereinbarungsart: Leistungsvereinbarungsart;
    lvbBeitragstabelle: LvbBeitragstabelle[];
}

export interface LvbBeitragstabelle {
    von: number;
    bis: number;
    beitrag: number;
}

export interface Gespraechspartner {
    vorname: string;
    nachname: string;
}

export interface Link {
    methods: string[];
    uri: string;
}

export interface Struktur {
    versicherungen: string;
    personen: string;
    lvbArt: string;
}

export enum Kanalrichtung {
    EINGEHEND = 'EINGEHEND',
    AUSGEHEND = 'AUSGEHEND'
}

export interface Kundenberater {
    kundencentermitarbeiter: boolean;
    nummer: string;
}

export interface Leistungsvereinbarung {
    auswaehlbar: boolean;
    leistungsvereinbarungsId: string;
    leistungsvereinbarungsart: Leistungsvereinbarungsart;
    link: Link;
    obligatorisch: boolean;
    progression: number;
    struktur: Struktur;
    vereinbart: boolean;
    versicherungssumme: number;
    wertebereiche: WertebereichAngebot[];
}

export interface Rabatt {
    rabattWert: Rabattwert;
}

export enum Rabattwert {
    PROZENT_2 = 'PROZENT_2',
    PROZENT_3 = 'PROZENT_3',
    PROZENT_4 = 'PROZENT_4',
    PROZENT_5 = 'PROZENT_5',
    PROZENT_6 = 'PROZENT_6',
    PROZENT_7 = 'PROZENT_7',
    PROZENT_8 = 'PROZENT_8',
    PROZENT_9 = 'PROZENT_9',
    PROZENT_10 = 'PROZENT_10',
    PROZENT_11 = 'PROZENT_11',
    PROZENT_12 = 'PROZENT_12',
    PROZENT_13 = 'PROZENT_13',
    PROZENT_14 = 'PROZENT_14',
    PROZENT_15 = 'PROZENT_15',
    PROZENT_16 = 'PROZENT_16',
    PROZENT_17 = 'PROZENT_17',
    PROZENT_18 = 'PROZENT_18',
    PROZENT_19 = 'PROZENT_19',
    PROZENT_20 = 'PROZENT_20',
    KEIN_RABATT = 'KEIN_RABATT'
}

export interface ZuschlagNachlass {
    value: string | null;
    zuschlagNachlassnummer: number;
    zuschlagNachlasstyp: ZuschlagNachlassTyp;
    bezeichnung: string;
}

export enum ZuschlagNachlassTyp {
    ZUSCHLAG = 'ZUSCHLAG',
    NACHLASS = 'NACHLASS'
}

export enum Leistungsvereinbarungsart {
    ZAHN_KFO = 'ZAHN_KFO'
}

export interface WertebereichAngebot {
    attribut: string;
    obligatorisch: boolean;
    typ: Typ;
    vorbelegung: {};
}

export interface WertebereichAufzaehlung extends WertebereichAngebot {
    werte: string[] | number[];
}

export interface WertebereichBereich extends WertebereichAngebot {
    min: number | Date;
    max: number | Date;
    schrittweite: number | Date | null;
}

export interface Rollenwertebereich {
    min: number;
    max: number;
    rollentyp: Rollentyp;
}

export interface WertebereichUneingeschraenkt extends WertebereichAngebot {}

export enum Typ {
    AUFZAEHLUNG,
    BEREICH,
    UNEINGESCHRAENKT
}

export enum Quellsystem {
    DAVID = 'DAVID',
    EDWIN = 'EDWIN',
    ERGO_INTERNET = 'ERGO_INTERNET',
    ERGO_VERMITTLERHOMEPAGE = 'ERGO_VERMITTLERHOMEPAGE',
    ERGODIREKT_INTERNET = 'ERGODIREKT_INTERNET'
}

export interface Rolle {
    personId: string;
    rollentyp: Rollentyp;
    reference: {
        personId: string
    }
}

export enum Rollentyp {
    VERSICHERUNGSNEHMER = 'VERSICHERUNGSNEHMER',
    VERSICHERTE_PERSON = 'VERSICHERTE_PERSON',
    BEITRAGSZAHLER = 'BEITRAGSZAHLER',
    VERSANDANSCHRIFT = 'VERSANDANSCHRIFT',
    ANSPRECHPARTNER = 'ANSPRECHPARTNER',
    WERBER = 'WERBER'
}

export enum Verkaufsprozessart {
    VOLLANGEBOT = 'VOLLANGEBOT',
    DIREKTABSCHLUSS = 'DIREKTABSCHLUSS'
}

export enum Versicherungsstatus {
    ANGELEGT = 'ANGELEGT',
    ABSCHLUSS_GEWUENSCHT = 'ABSCHLUSS_GEWUENSCHT'
}

export interface Vermittler {
    euVermittlerrichtlinie: boolean;
    identifikationsnummer: string | null;
    vermittlernummer: string | null;
    agenturId: string | null;
}

export interface Versicherung {
    aenderungsdatum: Date; // Zeitpunkt
    antragseingang: Date; // Zeitpunkt
    ausgewaehlt: boolean;
    berechenbar: boolean;
    druckauftragsinformationen: Druckauftragsinformation[];
    hinweise: Hinweis[];
    link: Link;
    statusaenderungsdatum: Date; // Zeitpunkt
    tarifvariante: string;
    variantennummer: number;
    verkaufsname: string;
    verkaufszeitpunkt: Date; // Zeitpunkt
    versicherungsId: string;
    versicherungsbeginn: string; //yyyy-MM-dd
    versicherungsstatus: string;
    versicherungsende: Date;
    wertebereiche: WertebereichAngebot[];
    workflownummer: string;
    zahlweise: Zahlweise;
}

export interface Hinweis {
    hinweisart: string;
    bestaetigt: boolean;
    kategorie: string;
    hinweisText: string;
    pflichtText: string;
}

export interface Druckauftragsinformation {
    auftragsnummer: string;
    aussendeart: Aussendeart;
    freundschaftswerbung: boolean;
    versandweg: Versandweg;
    zeitpunktAuftragserstellung: string;
}

export enum Aussendeart {
    HAUPT_AUSSENDUNG = 'HAUPT_AUSSENDUNG',
    NACHFASS = 'NACHFASS'
}

export enum Versandweg {
    POST = 'POST',
    EMAIL = 'EMAIL',
    DIGITALE_KUNDENAKTE = 'DIGITALE_KUNDENAKTE',
    FAX = 'FAX',
    SACHBEARBEITER = 'SACHBEARBEITER',
    SIMULATION = 'SIMULATION'
}

export enum Zahlweise {
    EINMALZAHLUNG = 'EINMALZAHLUNG',
    JAEHRLICH = 'JAEHRLICH',
    HALBJAEHRLICH = 'HALBJAEHRLICH',
    VIERTELJAEHRLICH = 'VIERTELJAEHRLICH',
    MONATLICH = 'MONATLICH'
}

export enum Vertriebskanal {
    TELEFON = 'TELEFON',
    INTERNET = 'INTERNET',
    SCHRIFTLICH = 'SCHRIFTLICH',
    CHAT = 'CHAT',
    SKYPE = 'SKYPE',
    EMAIL = 'EMAIL'
}

export enum AbbuchungstagImMonat {
    TAG_1 = 'TAG_1',
    TAG_16 = 'TAG_16'
}
