import 'url-search-params-polyfill';

import React from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { IAppState } from './IAppState';
import { ladeAngebotAsync } from './AppAction';
import { Basisdaten } from '../basisdaten/Basisdaten';
import { Personen } from '../personen/Personen';
import { Versicherungsschutz } from '../versicherungsschutz/Versicherungsschutz';
import { Beratungsprotokoll } from '../beratungsprotokoll/Beratungsprotokoll';
import { Bankverbindung } from '../bankverbindung/Bankverbindung';
import ergoLogo from '../../assets/images/ergo_logo.png';
import { Exceptions } from '../exceptions/Exceptions';
import { Personensuche } from '../personensuche/Personensuche';
import { Meldungen } from '../meldungen/Meldungen';
import { ScrollComponent } from './ScrollComponent';
import { MeldungAngebotLaden } from './MeldungAngebotLaden';
import { Abschlusswunsch } from '../abschlusswunsch/Abschlusswunsch';
import { Verkaufsprozessart } from '../util/fetch/offerengine/OfferEngineAngebotDto';
import { Abschluss } from '../abschluss/Abschluss';
import TaxModal from '../taxmodal/TaxModal';
import Unauthenticated from '../auth/Unauthenticated'
import { RefreshUserToken } from '../auth/RefreshUserToken'
import { businessIdUpdateAction } from './actions/businessIdUpdateAction'

interface AppPresentationProps extends AppProps, AppDispatchProps {}

interface AppProps {
    businessId: string;
    hasBasisdaten: boolean;
    offerEngineBaseVersion: string;
    kfoVersion: string;
    hasError: boolean;
    verkaufsprozessart: Verkaufsprozessart | null;
    abschlussWarErfolgreich: boolean;
    isOpen: boolean;
    unauthenticated: boolean;
}

interface AppDispatchProps {
    ladeAngebotMitBusinessId: (businessId: string) => void;
}

interface AppPresentationState {
    beratungsprotokollKey: number;
}

export class AppPresentation extends React.Component<AppPresentationProps, AppPresentationState> {
    constructor(props: AppPresentationProps) {
        super(props);
        this.state = {
            beratungsprotokollKey: 0
        };
    }

    componentDidMount(): void {
        const searchParams = new URLSearchParams(window.location.search);
        let businessId: string = searchParams.get('businessId') || '';
        businessId = businessId.replace(/\//g, '');
        this.props.ladeAngebotMitBusinessId(businessId);
    }

    componentDidUpdate(prevProps: Readonly<AppPresentationProps>): void {
        if (prevProps.verkaufsprozessart && prevProps.verkaufsprozessart !== this.props.verkaufsprozessart) {
            this.setState((prevState: AppPresentationState) => ({
                beratungsprotokollKey: prevState.beratungsprotokollKey + 1
            }));
        }
    }

    render() {
        return (
            <div className="esc_container">
                <div className="esc_grid">
                    <div className="esc_grid__wrapper">
                        <div className="esc_col esc_col-s-9">
                            <div className="esc_container--xxl esc_box--xxl">

                                { this.props.unauthenticated ? (
                                  <Unauthenticated businessId={this.props.businessId} />
                                ) : this.props.hasError ? (
                                  <><Exceptions /><RefreshUserToken /></>
                                ) : !this.props.hasBasisdaten ? (
                                    <><MeldungAngebotLaden /><RefreshUserToken /></>
                                ) : (
                                    <div>
                                        <Basisdaten />
                                        <Personen />
                                        <Versicherungsschutz />
                                        <Abschlusswunsch />
                                        {!this.props.abschlussWarErfolgreich && (
                                            <Beratungsprotokoll
                                                key={this.state.beratungsprotokollKey}
                                                allowDenial={this.props.verkaufsprozessart === Verkaufsprozessart.VOLLANGEBOT}
                                            />
                                        )}
                                        <Bankverbindung />
                                        <Abschluss />
                                        <ScrollComponent />
                                        <RefreshUserToken />
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="esc_col esc_col-s-3">
                            <div className="esc_container esc_box sidebar">
                                <img src={ergoLogo} alt="ERGO Logo" className="ergo-logo" />
                                <p>
                                    <small>
                                        <span>KFO Frontend Version: </span>
                                        <span>
                                            <b>{this.props.kfoVersion}</b>
                                        </span>
                                        <br />
                                        <span>Kranken Offerengine Version: </span>
                                        <span>
                                            <b>{this.props.offerEngineBaseVersion}</b>
                                        </span>
                                        <br />
                                        <span>BusinessId: </span>
                                        <span>{this.props.businessId}</span>
                                    </small>
                                </p>
                                <hr />
                                {!this.props.hasError && this.props.hasBasisdaten && (
                                    <div className="sidebar-scroll">
                                        <Personensuche />
                                        <Meldungen />
                                    </div>
                                )}
                            </div>
                        </div>
                        <TaxModal isOpen={this.props.isOpen} />
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: IAppState): AppProps => ({
    businessId: state.businessId,
    hasBasisdaten: !!state.basisdaten.businessId,
    offerEngineBaseVersion: state.basisdaten.offerEngineBaseVersion,
    kfoVersion: state.basisdaten.kfoVersion,
    hasError: state.exceptions.hasError,
    verkaufsprozessart: state.basisdaten.verkaufsprozessart,
    abschlussWarErfolgreich: state.abschluss.abschlussErfolgreich,
    isOpen: state.taxmodal.isOpen,
    unauthenticated: state.unauthenticated
});

const mapDispatchToProps = (dispatch: Dispatch): AppDispatchProps => ({
    ladeAngebotMitBusinessId: (businessId: string): void => {
        // @ts-ignore
        dispatch(businessIdUpdateAction(businessId));

        // @ts-ignore
        dispatch(ladeAngebotAsync(businessId));
    }
});

export const App = connect(mapStateToProps, mapDispatchToProps)(AppPresentation);
