import { initialPersonenState, Person, Personen, PersonenState } from '../personen/PersonenReducer';
import { Rollentyp } from './fetch/offerengine/OfferEngineAngebotDto';

export const getAllePersonenMitRolle = (personen: Personen, neededRollenTyp: Rollentyp): Person[] =>
    Object.values(personen).filter((person: Person) => person.rollen && person.rollen.some((rollenTyp: Rollentyp) => rollenTyp === neededRollenTyp));

export const isMaxNumberVPGiven = (personenState: PersonenState): boolean => personenState.maxNumberVP !== initialPersonenState.maxNumberVP;

export const remainingNumberVP = (personenState: PersonenState): number =>
    personenState.maxNumberVP - getAllePersonenMitRolle(personenState.personen, Rollentyp.VERSICHERTE_PERSON).length;

export const maxNumberVPErreicht = (personenState: PersonenState): boolean => isMaxNumberVPGiven(personenState) && remainingNumberVP(personenState) <= 0;
