import { FrontendResponse } from "./fetch/client/FrontendResponse";
import { OfferEngineAngebotDto, OfferEnginePersonDataDto } from "./fetch/offerengine/OfferEngineAngebotDto";

export const getSpcsMappedResponse = (response: FrontendResponse<OfferEngineAngebotDto>): FrontendResponse<OfferEngineAngebotDto> => {
    const rollen = response.payload?.angebot.rollen || []
    const insuranceHolderRole = rollen.find(rolle => rolle.rollentyp === "VERSICHERUNGSNEHMER");
    const insuranceHolderId = insuranceHolderRole?.reference.personId;
    const insuranceHolder = response.payload?.personen.find(person => person.personId === insuranceHolderId)
    const bankverbindung = insuranceHolder?.bankverbindungen?.find(item => item.isSelected);
    const emailData = insuranceHolder?.mailAdressen.find(item => item.selected);

    const newResponse = {...response}
    if(newResponse.payload?.angebot && bankverbindung) {
        newResponse.payload.angebot.bankverbindung = bankverbindung;
    }
    if(newResponse.payload?.angebot && emailData) {
        newResponse.payload.angebot.versandmail = emailData.adresse;
    }
    return newResponse;
}

export const getSpcsPersonMappedResponse = (response: FrontendResponse<OfferEnginePersonDataDto>): FrontendResponse<OfferEnginePersonDataDto> => {
    const bankverbindung = response.payload?.pdsPerson?.bankverbindungen?.find(item => item.isSelected);
    const newResponse = {...response}
    if(newResponse.payload?.angebot && bankverbindung) {
        newResponse.payload.angebot.bankverbindung = bankverbindung;
    }
    return newResponse;
}