import {
    EINGABE_KUNDENNUMMER,
    EnterKundennummerAction,
    ResetPersonensucheAction,
    SetRemainingVPsMessageAction,
    SETZE_MELDUNG_VP,
    SETZE_PERSONENSUCHE_ZURUECK,
    ShowAddressIsInvalidAction,
    ShowParisKundeAction,
    ShowPersonIsLoadingAction,
    ZEIGE_ADRESSE_INVALID,
    ZEIGE_LOADINGSPINNER,
    ZEIGE_SAVINGSPINNER,
    ZEIGE_SUCHERGEBNIS
} from './PersonensucheAction';
import { ParisKunde } from '../util/fetch/personensuche/PersonensucheDto';

export interface PersonensucheState {
    kundennummer: string;
    kunde: ParisKunde | null;
    showResponse: boolean;
    errormessage: string;
    showPersonIsLoadingSpinner: boolean;
    showAddressIsInvalid: boolean;
    addressIsInvalidMessage: string;
    showPersonIsSavingSpinner: boolean;
    remainingVPsMessage: string;
}

const initialPersonensucheState = {
    kundennummer: '',
    kunde: null,
    showResponse: false,
    errormessage: '',
    showPersonIsLoadingSpinner: false,
    showAddressIsInvalid: false,
    addressIsInvalidMessage: '',
    showPersonIsSavingSpinner: false,
    remainingVPsMessage: ''
};

type PersonensucheAction = EnterKundennummerAction &
    ShowParisKundeAction &
    ShowPersonIsLoadingAction &
    ResetPersonensucheAction &
    ShowAddressIsInvalidAction &
    SetRemainingVPsMessageAction;

export const personensucheReducer = (state: PersonensucheState = initialPersonensucheState, action: PersonensucheAction): PersonensucheState => {
    switch (action.type) {
        case EINGABE_KUNDENNUMMER: {
            return {
                ...state,
                kundennummer: action.kundennummer,
                showResponse: false,
                errormessage: '',
                showPersonIsLoadingSpinner: false
            };
        }
        case ZEIGE_SUCHERGEBNIS: {
            return {
                ...state,
                kunde: action.kunde,
                showResponse: true,
                errormessage: action.errormessage
            };
        }
        case ZEIGE_LOADINGSPINNER: {
            return {
                ...state,
                showPersonIsLoadingSpinner: action.showSpinner
            };
        }
        case ZEIGE_SAVINGSPINNER: {
            return {
                ...state,
                showPersonIsSavingSpinner: action.showSpinner
            };
        }
        case SETZE_PERSONENSUCHE_ZURUECK: {
            return initialPersonensucheState;
        }
        case ZEIGE_ADRESSE_INVALID: {
            return {
                ...state,
                showAddressIsInvalid: action.payload.display,
                addressIsInvalidMessage: action.payload.message
            };
        }
        case SETZE_MELDUNG_VP: {
            return {
                ...state,
                remainingVPsMessage: action.message
            };
        }
        default:
            return state;
    }
};
