
export const UPDATE_BUSINESS_ID = 'UPDATE_BUSINESS_ID';
export type UPDATE_BUSINESS_ID = typeof UPDATE_BUSINESS_ID;

export interface IUpdateBusinessId {
    type: UPDATE_BUSINESS_ID;
    payload: string;
}



export function businessIdUpdateAction(payload: string): IUpdateBusinessId {
    return {
        type: UPDATE_BUSINESS_ID,
        payload: payload
    };
}
