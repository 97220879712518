export const formatPayloadDate = (datum: undefined | string): string => {
    if (!datum) {
        return '';
    }

    return new Date(datum).toLocaleDateString('de-DE', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      });
}

export const datumToString = (datum: Date | undefined | string): string => {
    if (!datum) {
        return '';
    }

    try {
        if (typeof datum === 'string') {
            return changeDateFormat(datum);
        }

        return datum.getDate() + '.' + (datum.getMonth() + 1) + '.' + datum.getFullYear();
    } catch (e) {
        return '';
    }
};

export const changeDateFormat = (datum: string): string => {
    // Datums-Format: dd.MM.yyyy
    const regExpDotDate = /^([1-9]|0[1-9]|[12][0-9]|3[01])[.]([1-9]|0[1-9]|1[012])[.](19|20)[0-9][0-9]$/;
    // Datums-Format: yyyy-MM-dd
    const regExpHyphenDate = /^(19|20)[0-9][0-9][-]([1-9]|0[1-9]|1[012])[-]([1-9]|0[1-9]|[12][0-9]|3[01])$/;

    if (regExpDotDate.test(datum)) {
        const newDatum: string[] = datum.split('.');
        return newDatum[2] + '-' + newDatum[1] + '-' + newDatum[0];
    }

    if (regExpHyphenDate.test(datum)) {
        const newDatum: string[] = datum.split('-');
        return newDatum[2] + '.' + newDatum[1] + '.' + newDatum[0];
    }

    return datum;
};

export const getIsoDateString = (date: Date | undefined): string => {
    if (date) {
        date.setHours(12);
        return date.toISOString().slice(0, 10);
    }

    return '';
};
