import React from 'react';
import { IAppState } from '../app/IAppState';
import { connect } from 'react-redux';
import { Verkaufsprozessart, Versicherung } from '../util/fetch/offerengine/OfferEngineAngebotDto';
import { AbschlussState } from './AbschlussReducer';
import Panel from '@eg/elements/Panel';
import { Direktabschluss } from './direktabschluss/Direktabschluss';
import { Versand } from './versand/Versand';
import { Meldung, Schweregrad } from '../util/fetch/client/MeldungenDto';
import { showAbschlussErgebnisDialogSync } from './AbschlussAction';
import { bindActionCreators, Dispatch } from 'redux';
import Modal from '@eg/elements/Modal';
import { UIMouseEvent } from '../util/UiEventTypes';
import { ABSCHLUSS_MODAL, ABSCHLUSS_MODAL_DETAILS, ABSCHLUSS_MODAL_HEADING } from '../util/RanorexIds';

interface AbschlussPresentationProps extends AbschlussDispatchProps, AbschlussLinkState {}

export class AbschlussPresentation extends React.Component<AbschlussPresentationProps, {}> {
    cancelDialogHandler = (event: UIMouseEvent | undefined) => {
        this.props.cancelAbschlussErfolgreichDialogHandler(false);
    };

    render() {
        const {
            oberflaecheSperren,
            verkaufsprozessart,
            hasErrorMeldungen,
            versicherung,
            disableAbschlussButton,
            abschlussErfolgreichDetails,
            abschlussErfolgreich,
            showAbschlussErfolgreichDialog
        } = this.props;

        const headerTitle: string = verkaufsprozessart === Verkaufsprozessart.DIREKTABSCHLUSS ? 'Abschluss' : 'Versand';
        const abschlussErfolgreichHeading: string =
            verkaufsprozessart && Verkaufsprozessart.VOLLANGEBOT === verkaufsprozessart ? 'Angebot erfolgreich versendet' : 'Abschluss erfolgreich';

        const component: JSX.Element =
            verkaufsprozessart === Verkaufsprozessart.DIREKTABSCHLUSS ? (
                <Direktabschluss
                    hasErrorMeldungen={hasErrorMeldungen}
                    oberflaecheSperren={oberflaecheSperren}
                    versicherung={versicherung}
                    disableAbschlussButton={disableAbschlussButton}
                />
            ) : (
                <Versand
                    hasErrorMeldungen={hasErrorMeldungen}
                    oberflaecheSperren={oberflaecheSperren}
                    versicherung={versicherung}
                    disableAbschlussButton={disableAbschlussButton}
                />
            );

        return (
            <div>
                <div className="esc_grid">
                    <div className="esc_grid__wrapper">
                        <div className="esc_col esc_col-s-12">
                            <Panel>
                                <h2 className="esc_h4">{headerTitle}</h2>
                            </Panel>
                        </div>
                    </div>
                </div>
                <div className="esc_grid component-content">
                    <div className="esc_grid__wrapper">
                        {component}
                        <Modal open={abschlussErfolgreich && showAbschlussErfolgreichDialog} onDismiss={this.cancelDialogHandler} dismissible ranorex-id={ABSCHLUSS_MODAL}>
                            <div style={{ textAlign: 'center' }}>
                                <h1 ranorex-id={ABSCHLUSS_MODAL_HEADING}>{abschlussErfolgreichHeading}</h1>
                                <br />
                                {abschlussErfolgreichDetails.length > 0 && <h2 ranorex-id={ABSCHLUSS_MODAL_DETAILS}>{abschlussErfolgreichDetails}</h2>}

                                <h4>Sie können den Browser-Tab jetzt schließen.</h4>
                            </div>
                        </Modal>
                    </div>
                </div>
            </div>
        );
    }
}

const errorMeldungen = (meldungen: Meldung[]): boolean => meldungen.some((meldung: Meldung) => meldung.schweregrad === Schweregrad.ERROR);

const mapStateToProps = (state: IAppState): AbschlussLinkState => ({
    verkaufsprozessart: state.basisdaten.verkaufsprozessart,
    direktAbschluss: state.abschluss.direktAbschluss,
    versandAbschluss: state.abschluss.versandAbschluss,
    abschlussErfolgreich: state.abschluss.abschlussErfolgreich,
    abschlussErfolgreichDetails: state.abschluss.abschlussErfolgreichDetails,
    showAbschlussErfolgreichDialog: state.abschluss.showAbschlussErfolgreichDialog,
    oberflaecheSperren: state.abschluss.abschlussErfolgreich,
    hasErrorMeldungen: errorMeldungen(state.meldungen.meldungenFE) || errorMeldungen(state.meldungen.meldungenBFF) || errorMeldungen(state.meldungen.meldungenOE),
    versicherung: state.abschluss.direktAbschluss.versicherung,
    disableAbschlussButton: state.abschluss.disableAbschlussButton,
    setSpinner: state.abschluss.setSpinner
});

const mapDispatchToProps = (dispatch: Dispatch): AbschlussDispatchProps =>
    bindActionCreators(
        {
            cancelAbschlussErfolgreichDialogHandler: showAbschlussErgebnisDialogSync
        },
        dispatch
    );

interface AbschlussLinkState extends AbschlussState {
    verkaufsprozessart: Verkaufsprozessart | null;
    oberflaecheSperren: boolean;
    abschlussErfolgreich: boolean;
    showAbschlussErfolgreichDialog: boolean;
    hasErrorMeldungen: boolean;
    versicherung: Versicherung | null;
}

export interface AbschlussDetailInputProps {
    hasErrorMeldungen?: boolean;
    oberflaecheSperren: boolean;
    versicherung?: Versicherung | null;
    disableAbschlussButton?: boolean;
    verkaufsprozessart?: Verkaufsprozessart | null;
}

interface AbschlussDispatchProps {
    cancelAbschlussErfolgreichDialogHandler: (showDialog: boolean) => void;
}

export const Abschluss = connect(mapStateToProps, mapDispatchToProps)(AbschlussPresentation);
