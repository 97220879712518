import { LADE_ANGEBOT, LADE_PRODUKT_VERSIONEN, LoadOfferAction, LoadProductVersionsAction } from '../app/AppAction';
import {
    Gespraechspartner,
    Kanalrichtung,
    OfferEngineAngebot,
    Rabattwert,
    Verkaufsprozessart,
    WertebereichAufzaehlung,
    ZuschlagNachlass
} from '../util/fetch/offerengine/OfferEngineAngebotDto';
import { AENDERE_VERKAUFSPROZESSART, ChangeVerkaufsprozessartAction } from '../abschlusswunsch/AbschlusswunschAction';
import { getWertebereichFromAngebot } from '../util/WertebereicheHelper';
import { sortAlphabetical } from '../util/ReducerHelper';

export interface BasisdatenState {
    offerEngineBaseVersion: string;
    kfoVersion: string;
    angebotsnummer: string;
    businessId: string;
    aktionsnummer: string;
    isHaustarif: boolean;
    isVermittlerAngebot: boolean;
    mitarbeiterrabattLabel: string;
    vermittlerrabattLabel: string;
    beratungsprotokollId: string;
    gespraechspartner: Gespraechspartner;
    kanalrichtung: Kanalrichtung | null;
    verkaufsprozessart: Verkaufsprozessart | null;
    werteVerkaufsprozessart: string[];
}

export const initialBasisdatenState: BasisdatenState = {
    offerEngineBaseVersion: '',
    kfoVersion: '',
    angebotsnummer: '',
    businessId: '',
    aktionsnummer: '',
    isHaustarif: false,
    isVermittlerAngebot: false,
    mitarbeiterrabattLabel: '',
    vermittlerrabattLabel: '',
    beratungsprotokollId: '',
    gespraechspartner: {
        vorname: '',
        nachname: ''
    },
    kanalrichtung: null,
    verkaufsprozessart: null,
    werteVerkaufsprozessart: []
};

export const UNBEKANNTER_MITARBEITERRABATT: string = 'unbekannter Mitarbeiter-Rabatt';
export const MITARBEITER_RABATT_5_PROZENT_ZUNA_NUMMER: number = 700;
export const UNBEKANNTER_VERMITTLERRABATT: string = 'unbekannter Vermittler-Rabatt';
export const VERMITTLER_RABATT_2_PROZENT_ZUNA_NUMMER: number = 601;

type BasisdatenAction = LoadOfferAction & LoadProductVersionsAction & ChangeVerkaufsprozessartAction;

export const basisdatenReducer = (state: BasisdatenState = initialBasisdatenState, action: BasisdatenAction): BasisdatenState => {
    switch (action.type) {
        case LADE_ANGEBOT: {
            return {
                ...state,
                businessId: action.angebot.businessId,
                angebotsnummer: action.angebot.angebotsnummer,
                aktionsnummer: getAktionsnummer(action.angebot),
                isHaustarif: haustarif(action.angebot),
                isVermittlerAngebot: rabatt(action.angebot),
                mitarbeiterrabattLabel: bezeichnungFuerRabatt(action.angebot, MITARBEITER_RABATT_5_PROZENT_ZUNA_NUMMER, UNBEKANNTER_MITARBEITERRABATT),
                vermittlerrabattLabel: bezeichnungFuerRabatt(action.angebot, VERMITTLER_RABATT_2_PROZENT_ZUNA_NUMMER, UNBEKANNTER_VERMITTLERRABATT),
                beratungsprotokollId: action.angebot.beratungsprotokollId,
                gespraechspartner: action.angebot.gespraechspartner,
                kanalrichtung: action.angebot.kanalrichtung,
                verkaufsprozessart: action.angebot.verkaufsprozessart,
                werteVerkaufsprozessart: getWerteVerkaufsprozessart(action.angebot)
            };
        }
        case LADE_PRODUKT_VERSIONEN: {
            return {
                ...state,
                offerEngineBaseVersion: action.productVersions.offerEngineBaseVersion,
                kfoVersion: action.productVersions.frontendVersion
            };
        }
        case AENDERE_VERKAUFSPROZESSART: {
            return {
                ...state,
                verkaufsprozessart: action.verkaufsprozessart
            };
        }
        default:
            return state;
    }
};

const getAktionsnummer = (angebot: OfferEngineAngebot): string => angebot?.aktionsnummer || initialBasisdatenState.aktionsnummer;

const haustarif = (angebot: OfferEngineAngebot): boolean => angebot?.haustarif || initialBasisdatenState.isHaustarif;

const rabatt = (angebot: OfferEngineAngebot): boolean => angebot.rabatt && angebot.rabatt.rabattWert !== null && angebot.rabatt.rabattWert !== Rabattwert.KEIN_RABATT;

const bezeichnungFuerRabatt = (angebot: OfferEngineAngebot, zuschlagNachlassnummer: number, defaultValue: string): string =>
    angebot.zuschlaegeNachlaesse && angebot.zuschlaegeNachlaesse.find((zuschlagNachlass: ZuschlagNachlass) => zuschlagNachlass.zuschlagNachlassnummer === zuschlagNachlassnummer)
        ? angebot.zuschlaegeNachlaesse.find((zuschlagNachlass: ZuschlagNachlass) => zuschlagNachlass.zuschlagNachlassnummer === zuschlagNachlassnummer)!.bezeichnung
        : defaultValue;

const getWerteVerkaufsprozessart = (angebot: OfferEngineAngebot): string[] =>
    ((getWertebereichFromAngebot('verkaufsprozessart', angebot.wertebereiche) as WertebereichAufzaehlung).werte as string[]).sort(sortAlphabetical);
