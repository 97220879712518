import { ErrorObject } from '../util/fetch/client/ErrorObject';
import { SPEICHERE_EXCEPTION, StoreExceptionAction } from './ExceptionsAction';

export interface ExceptionState {
    hasError: boolean;
    errorObject: ErrorObject | undefined;
}

export const initialExceptionState = {
    hasError: false,
    errorObject: undefined
};

type ExceptionAction = StoreExceptionAction;

export const exceptionsReducer = (state: ExceptionState = initialExceptionState, action: ExceptionAction): ExceptionState => {
    switch (action.type) {
        case SPEICHERE_EXCEPTION: {
            return {
                ...state,
                hasError: action.errorObject !== null && action.errorObject !== undefined,
                errorObject: action.errorObject
            };
        }
        default:
            return state;
    }
};
