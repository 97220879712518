import ToothIcon from '@eg/elements/components/Icons/ToothIcon';
import Card from '@eg/elements/Card';
import React from 'react';

export function MeldungAngebotLaden() {
    return (
        <Card className="angebot-laden">
            <div>
                <ToothIcon width={60} height={60} className="angebot-laden-icon" />
            </div>
            <h2 className="esc_h3">Das KFO-Angebot wird geladen.</h2>
        </Card>
    );
}
