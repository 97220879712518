import { Dispatch } from 'redux';
import { FrontendResponse } from '../client/FrontendResponse';
import { PersonensucheDto } from './PersonensucheDto';
import { sendGetRequest, sendPostRequest } from '../client/FetchBaseClient';
import { PersonAnlegenRequestDto, PersonAnlegenResponseDto } from './PersonRollenDto';

export const getParisKundeHttpRequest = (businessId: string, kundennummer: string, dispatch: Dispatch): Promise<FrontendResponse<PersonensucheDto>> => {
    return sendGetRequest(`/kfo/personen/${businessId}/suche/pariskunden/?kundennummer=${kundennummer}`, dispatch);
};

export const selectParisKundeHttpRequest = (businessId: string, payload: PersonAnlegenRequestDto, dispatch: Dispatch): Promise<FrontendResponse<PersonAnlegenResponseDto>> => {
    return sendPostRequest(`/kfo/personen/${businessId}/speichere/pariskunden/`, payload, dispatch);
};
