/**
 * null      -> true
 * undefined -> true
 *   ''      -> true
 *   {}      -> false
 *   []      -> false
 *  '  '     -> false
 **/
export const isAbsent = (obj: any): boolean => !isPresent(obj);

/**
 * null      -> false
 * undefined -> false
 *   ''      -> false
 *   {}      -> true
 *   []      -> true
 *  '  '     -> true
 **/
export const isPresent = (obj: any): boolean => obj;

/**
 * null      -> true
 * undefined -> true
 * empty ''  -> true
 * blank ' ' -> true
 * 'abc'     -> false
 * '  abc  ' -> false
 **/
export const isEmpty = (s: string): boolean => {
    return isAbsent(s) || s.replace(/ /g, '').length === 0;
};

/**
 * null      -> false
 * undefined -> false
 * empty ''  -> false
 * blank ' ' -> false
 * 'abc'     -> true
 * '  abc  ' -> true
 **/
export const hasValue = (s: string): boolean => isPresent(s) && !isEmpty(s);

/**
 * null      -> false
 * undefined -> false
 * empty ''  -> false
 * blank ' ' -> false
 * 'abc'     -> false
 * '  abc  ' -> false
 * 'true'    -> true
 * 'True'    -> true
 **/
export const isTrue = (s: string): boolean => hasValue(s) && s.toLowerCase() === 'true';

export const isDate = (date: any): boolean => isPresent(date) && Object.prototype.toString.call(date) === '[object Date]' && !Number.isNaN(date);

export const extract = (element: string, regex: RegExp): string => {
    if (isEmpty(element) || regex.test(element)) {
        return element;
    }

    const reducer = (accumulator: string, currentValue: string): string => accumulator + currentValue;
    // @ts-ignore
    const filteredArray: string[] = [...element].filter((character: string) => regex.test(character));
    return filteredArray.length > 0 ? filteredArray.reduce(reducer) : '';
};
