import { Dispatch } from 'redux';
import { onFulfilledStoreOffer } from '../app/AppAction';
import { IAppState } from '../app/IAppState';
import { onRejectedStoreException } from '../exceptions/ExceptionsAction';
import { clearMeldungenAfterAbschlussSync } from '../meldungen/MeldungenAction';
import { postAbschlussHttpRequest } from '../util/fetch/abschluss/SubmitController';
import { SubmitResponseDto } from '../util/fetch/abschluss/SubmitResponseDto';
import { FrontendResponse } from '../util/fetch/client/FrontendResponse';
import {
    Hinweis,
    OfferEngineAngebotDto,
    OfferEnginePersonDataDto,
    Versandweg,
    Versicherung
} from '../util/fetch/offerengine/OfferEngineAngebotDto';
import { changeVersandHttpRequest, getAngebotHttpRequest, updateBestaetigeDAHinweiseHttpRequest, updateVersandHttpRequest } from '../util/fetch/offerengine/OfferEngineController';
import { UpdateVersandRequestDto } from '../util/fetch/offerengine/UpdateVersandRequestDto';
import { HinweisDto, UpdateVersicherungRequestDto, Versicherung as VersicherungDto } from '../util/fetch/offerengine/UpdateVersicherungRequestDto';
import { getSpcsPersonMappedResponse } from "../util/spcsResponse";
import { ANDERE_EMAIL } from './AbschlussReducer';

export const CHANGE_VERSANDWEG: string = 'CHANGE_VERSANDWEG';
export const CHANGE_VERSANDMAIL: string = 'CHANGE_VERSANDMAIL';
export const SET_BUTTON_LOADING: string = 'SET_BUTTON_LOADING';

export const BESTAETIGE_HINWEIS: string = 'BESTAETIGE_HINWEIS';
export const BESTAETIGE_ALLE_HINWEISE: string = 'BESTAETIGE_ALLE_HINWEISE';
export const AENDERE_ABSCHLUSS_ERGEBNIS: string = 'AENDERE_ABSCHLUSS_ERGEBNIS';
export const SHOW_ABSCHLUSS_DIALOG: string = 'SHOW_ABSCHLUSS_DIALOG';

export interface ChangeAbschlussErgebnisAction {
    type: typeof AENDERE_ABSCHLUSS_ERGEBNIS;
    payload: {
        angebotsnummer: string;
        abschlussErfolgreichDetails: string;
        abschlussErfolgreich: boolean;
    };
}

export const changeAbschlussErgebnisSync = (angebotsnummer: string, abschlussErfolgreichDetails: string, abschlussErfolgreich: boolean): ChangeAbschlussErgebnisAction => {
    return {
        type: AENDERE_ABSCHLUSS_ERGEBNIS,
        payload: {
            angebotsnummer,
            abschlussErfolgreichDetails,
            abschlussErfolgreich
        }
    };
};

export interface ShowAbschlussErgebnisDialogAction {
    type: typeof SHOW_ABSCHLUSS_DIALOG;
    showDialog: boolean;
}

export const showAbschlussErgebnisDialogSync = (showDialog: boolean): ShowAbschlussErgebnisDialogAction => {
    return {
        type: SHOW_ABSCHLUSS_DIALOG,
        showDialog
    };
};

export interface ChangeBestaetigeAlleHinweiseAction {
    type: typeof BESTAETIGE_ALLE_HINWEISE;
    alleHinweiseBestaetigt: boolean;
}

export const changeAlleHinweiseBestaetigtSync = (alleHinweiseBestaetigt: boolean): ChangeBestaetigeAlleHinweiseAction => {
    return {
        type: BESTAETIGE_ALLE_HINWEISE,
        alleHinweiseBestaetigt
    };
};

/**
 *
 */

export interface ChangeVersandwegAction {
    type: typeof CHANGE_VERSANDWEG;
    versandweg: Versandweg;
}

export const updateVersandwegSync = (versandweg: Versandweg): ChangeVersandwegAction => {
    return {
        type: CHANGE_VERSANDWEG,
        versandweg
    };
};

/**
 *
 */

export interface ChangeVersandmailAction {
    type: typeof CHANGE_VERSANDMAIL;
    versandmail: string;
}

export const updateVersandmailSync = (versandmail: string): ChangeVersandmailAction => {
    return {
        type: CHANGE_VERSANDMAIL,
        versandmail
    };
};

/**
 *
 */

export interface SetButtonLoadingAction {
    type: typeof SET_BUTTON_LOADING;
    setButtonLoading: boolean;
}

export const setButtonLoadingSync = (setButtonLoading: boolean): SetButtonLoadingAction => {
    return {
        type: SET_BUTTON_LOADING,
        setButtonLoading
    };
};

export const updateVersandwegSyncAsync = (versandweg: Versandweg) => {
    return (dispatch: Dispatch, getState: () => IAppState) => {
        dispatch(updateVersandwegSync(versandweg));

        const request: UpdateVersandRequestDto = {
            versandweg: versandweg
        };

        return updateVersandHttpRequest(getState().basisdaten.businessId, request, dispatch)
            .then((response: FrontendResponse<OfferEngineAngebotDto>) => onFulfilledStoreOffer(response, dispatch))
            .catch((e: Error) => onRejectedStoreException(e, dispatch, 'AbschlussAction -> updateVersandwegSyncAsync'));
    };
};

export const updateVersandmailAsync = (email: string) => {
    return (dispatch: Dispatch, getState: () => IAppState) => {
        dispatch(updateVersandmailSync(email));

        if (email === ANDERE_EMAIL) {
            return;
        }

        const request: UpdateVersandRequestDto = {
            versandmail: email
        };

        return updateVersandHttpRequest(getState().basisdaten.businessId, request, dispatch)
            .then((response: FrontendResponse<OfferEngineAngebotDto>) => {
                onFulfilledStoreOffer(response, dispatch);
            })

            .catch((e: Error) => onRejectedStoreException(e, dispatch, 'AbschlussAction -> updateVersandmailAsync'));
    };
};

export const changeVersandmailAsync = (email: string) => {
    return (dispatch: Dispatch, getState: () => IAppState) => {
        dispatch(updateVersandmailSync(email));

        const personId = getState().bankverbindung.versicherungsnehmer?.pdsId || '';
        const emailData = getState().bankverbindung.versicherungsnehmer?.mailAdressen.find(item => item.adresse === email);
        const emailId = emailData ? emailData.id : '';
        return changeVersandHttpRequest(getState().basisdaten.businessId, personId, dispatch, emailId)
            .then((response: FrontendResponse<OfferEngineAngebotDto>) => {
                onFulfilledStoreOffer(response, dispatch);
            })

            .catch((e: Error) => onRejectedStoreException(e, dispatch, 'AbschlussAction -> updateVersandmailAsync'));
    };
};

export const doAbschluss = () => {
    return (dispatch: Dispatch, getState: () => IAppState) => {
        dispatch(setButtonLoadingSync(true));
        const businessId = getState().basisdaten.businessId;
        return postAbschlussHttpRequest(businessId, dispatch)
            .then((response: FrontendResponse<SubmitResponseDto>) => {
                if (response.angebot && response.angebot !== null) {
                    const details = `Angebot: ${response.angebot.angebotsnummer}`;

                    dispatch(changeAbschlussErgebnisSync(response.angebot.angebotsnummer, details, true));
                    dispatch(showAbschlussErgebnisDialogSync(true));
                    dispatch(clearMeldungenAfterAbschlussSync());
                }

                /* Note: AbschlussKomponente will change the angebot
                 * (e.g. set versicherung->versicherungsstatus from ANGELEGT to ABSCHLUSS_BEANTRAGT)
                 * without sending us the updated angebot.
                 * This call fetches the final angebot to have a clean and consistent state in the frontend.
                 * */

                // @ts-ignore
                dispatch(ladeFinalAngebotAsync(businessId));
            })
            .catch((e: Error) => {
                onRejectedStoreException(e, dispatch);
            })
    };
};

const ladeFinalAngebotAsync = (businessId: string) => {
    return (dispatch: Dispatch) => {
        return getAngebotHttpRequest(businessId, dispatch).then(response => 
            {
                onFulfilledStoreOffer(response, dispatch);
                dispatch(setButtonLoadingSync(false));
            });
        // Note: if this call will fail, we do not display a technical error message.
    };
};

export interface ChangeHinweisAction {
    type: typeof BESTAETIGE_HINWEIS;
    payload: {
        hinweis: Hinweis;
        index: number;
    };
}

export const updateBestaetigeHinweisSync = (hinweis: Hinweis, index: number): ChangeHinweisAction => {
    return {
        type: BESTAETIGE_HINWEIS,
        payload: {
            hinweis,
            index
        }
    };
};

const mapHinweis = (hinweis: Hinweis): HinweisDto => ({
    bestaetigt: hinweis.bestaetigt,
    hinweisart: hinweis.hinweisart
});

export const updateBestaetigeAlleHinweiseAsync = (versicherung: Versicherung) => {
    return (dispatch: Dispatch, getState: () => IAppState) => {
        const versicherungDto: VersicherungDto = {
            versicherungsId: versicherung.versicherungsId,
            hinweise: versicherung.hinweise.map((hinweis: Hinweis) => mapHinweis(hinweis))
        };

        const request: UpdateVersicherungRequestDto = {
            versicherungen: [versicherungDto]
        };
        dispatch(changeAlleHinweiseBestaetigtSync(true));

        return updateBestaetigeDAHinweiseHttpRequest(getState().basisdaten.businessId, request, dispatch)
            .then((response: FrontendResponse<OfferEnginePersonDataDto>) => getSpcsPersonMappedResponse(response))
            .then((response: FrontendResponse<OfferEnginePersonDataDto>) => onFulfilledStoreOffer(response, dispatch))
            .catch((e: Error) => onRejectedStoreException(e, dispatch, 'AbschlussAction -> updateBestaetigeAlleHinweiseAsync'));
    };
};
