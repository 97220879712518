import { Dispatch } from 'redux';
import { getAngebotHttpRequest } from '../util/fetch/offerengine/OfferEngineController';
import {
    OfferEngineAngebot,
    OfferEngineAngebotDto,
    OfferEnginePersonDataDto,
    ProductVersions,
    Vertriebskanal
} from '../util/fetch/offerengine/OfferEngineAngebotDto';
import { FrontendResponse } from '../util/fetch/client/FrontendResponse';
import { onRejectedStoreException } from '../exceptions/ExceptionsAction';
import { loadPersonsSync } from '../personen/PersonenAction';
import { setRemainingVPsMessageAsync } from '../personensuche/PersonensucheAction';
import { getSpcsMappedResponse } from '../util/spcsResponse';
import {isEmpty} from "../util/validate";

export const LADE_ANGEBOT: string = 'LADE_ANGEBOT';
export type LADE_ANGEBOT = typeof LADE_ANGEBOT;
export const LADE_PRODUKT_VERSIONEN: string = 'LADE_PRODUKT_VERSIONEN';
export type LADE_PRODUKT_VERSIONEN = typeof LADE_PRODUKT_VERSIONEN;

export interface LoadOfferAction {
    type: LADE_ANGEBOT;
    angebot: OfferEngineAngebot;
}

export const storeOfferSync = (angebot: OfferEngineAngebot): LoadOfferAction => {
    return {
        type: LADE_ANGEBOT,
        angebot: angebot
    };
};

export const onFulfilledStoreOffer = (response: FrontendResponse<OfferEngineAngebotDto | OfferEnginePersonDataDto>, dispatch: Dispatch): void => {
    response.payload && response.payload.angebot && dispatch(storeOfferSync(response.payload.angebot));
};

export interface LoadProductVersionsAction {
    type: LADE_PRODUKT_VERSIONEN;
    productVersions: ProductVersions;
}

export const storeVersionsSync = (productVersions: ProductVersions): LoadProductVersionsAction => {
    return {
        type: LADE_PRODUKT_VERSIONEN,
        productVersions: productVersions
    };
};

const throwErrorIfInvalid = (payload: OfferEngineAngebotDto): void => {
    if (payload.personen === null || payload.personen.length === 0) {
        throw new Error('Das Angebot ist keiner Person zugeordnet.');
    }
    if (
        (!payload.angebot.gespraechspartner ||
            !payload.angebot.gespraechspartner.vorname ||
            isEmpty(payload.angebot.gespraechspartner.vorname) ||
            !payload.angebot.gespraechspartner.nachname ||
            isEmpty(payload.angebot.gespraechspartner.nachname)) &&
        Vertriebskanal.INTERNET !== payload.angebot.vertriebskanal
    ) {
        throw new Error("Die Felder 'vorname' und 'nachname' bei 'gespraechspartner' im Angebot sind leer.");
    }
};

export const ladeAngebotAsync = (businessId: string) => {
    return (dispatch: Dispatch) => {
        return getAngebotHttpRequest(businessId, dispatch)
            .then((response: FrontendResponse<OfferEngineAngebotDto>) => {
                if (!response.payload) {
                    return;
                }

                throwErrorIfInvalid(response.payload);
                const mappedResponse = getSpcsMappedResponse(response) 
                dispatch(storeVersionsSync(response.payload.versions)) &&
                    //@ts-ignore
                    dispatch(storeOfferSync(mappedResponse.payload.angebot)) &&
                    dispatch(loadPersonsSync(response.payload.personen)) &&
                    // @ts-ignore
                    dispatch(setRemainingVPsMessageAsync());
            })
            .catch(e => onRejectedStoreException(e, dispatch, 'ladeAngebotAsync'));
    };
};
