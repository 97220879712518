import { OfferEnginePersonDataDto, Verkaufsprozessart } from '../util/fetch/offerengine/OfferEngineAngebotDto';
import { Dispatch } from 'redux';
import { UpdateVerkaufsprozessartRequestDto } from '../util/fetch/offerengine/UpdateVerkaufsprozessartRequestDto';
import { updateVerkaufsprozessartHttpRequest } from '../util/fetch/offerengine/OfferEngineController';
import { FrontendResponse } from '../util/fetch/client/FrontendResponse';
import { onFulfilledStoreOffer } from '../app/AppAction';
import { onRejectedStoreException } from '../exceptions/ExceptionsAction';
import { getSpcsPersonMappedResponse } from '../util/spcsResponse';

export const AENDERE_VERKAUFSPROZESSART: string = 'AENDERE_VERKAUFSPROZESSART';

export interface ChangeVerkaufsprozessartAction {
    type: typeof AENDERE_VERKAUFSPROZESSART;
    verkaufsprozessart: Verkaufsprozessart;
}

export const updateVerkaufsprozessartSync = (verkaufsprozessart: Verkaufsprozessart): ChangeVerkaufsprozessartAction => {
    return {
        type: AENDERE_VERKAUFSPROZESSART,
        verkaufsprozessart
    };
};

export const updateVerkaufsprozessartAsync = (businessId: string, verkaufsprozessart: Verkaufsprozessart) => {
    return (dispatch: Dispatch) => {
        dispatch(updateVerkaufsprozessartSync(verkaufsprozessart));

        const request: UpdateVerkaufsprozessartRequestDto = {
            beratungsprotokollId: "BERATUNGSVERZICHT",
            verkaufsprozessart: verkaufsprozessart
        };
        return updateVerkaufsprozessartHttpRequest(businessId, request, dispatch)
            .then((response: FrontendResponse<OfferEnginePersonDataDto>) => getSpcsPersonMappedResponse(response))
            .then((response: FrontendResponse<OfferEnginePersonDataDto>) => onFulfilledStoreOffer(response, dispatch))
            .catch((e: Error) => onRejectedStoreException(e, dispatch, 'AbschlusswunschAction -> updateVerkaufsprozessartAsync'));
    };
};
