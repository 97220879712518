import {
    Beitrag,
    Beitragstabelle,
    Leistungsvereinbarungsart,
    LvbBeitragstabelle,
    OfferEngineAngebot,
    Typ,
    WertebereichAufzaehlung,
    Zahlweise
} from '../util/fetch/offerengine/OfferEngineAngebotDto';
import { LADE_ANGEBOT, LoadOfferAction } from '../app/AppAction';
import { AENDERE_VERSICHERUNGSFELD, ChangeVersicherungFieldAction } from './VersicherungsschutzAction';
import { getWertebereichFromAngebot } from '../util/WertebereicheHelper';
import { formatPayloadDate } from '../util/DateFormattingHelper';

export interface VersicherungsschutzState {
    versicherungsId: string;
    versicherungsbeginn: string;
    zahlweise: Zahlweise;
    wertebereichVersicherungsbeginn: WertebereichAufzaehlung;
    wertebereichZahlweise: WertebereichAufzaehlung;
    beitraege: Beitrag[];
    beitragstabelle: LvbBeitragstabelle[];
}

const initialVersicherungsschutzState = {
    versicherungsId: '',
    versicherungsbeginn: '',
    zahlweise: Zahlweise.MONATLICH,
    wertebereichVersicherungsbeginn: {
        werte: [],
        attribut: '',
        obligatorisch: false,
        typ: Typ.AUFZAEHLUNG,
        vorbelegung: {}
    },
    wertebereichZahlweise: {
        werte: [],
        attribut: '',
        obligatorisch: false,
        typ: Typ.AUFZAEHLUNG,
        vorbelegung: {}
    },
    beitraege: [],
    beitragstabelle: []
};

type VersicherungsschutzAction = LoadOfferAction & ChangeVersicherungFieldAction;

export const versicherungsschutzReducer = (state: VersicherungsschutzState = initialVersicherungsschutzState, action: VersicherungsschutzAction): VersicherungsschutzState => {
    switch (action.type) {
        case LADE_ANGEBOT: {
            return {
                ...state,
                versicherungsId: action.angebot.versicherungen[0].versicherungsId,
                versicherungsbeginn: formatPayloadDate(action.angebot.versicherungen[0].versicherungsbeginn),
                zahlweise: action.angebot.versicherungen[0].zahlweise,
                wertebereichVersicherungsbeginn: getWertebereichFromAngebot('versicherungsbeginn', action.angebot.versicherungen[0].wertebereiche),
                wertebereichZahlweise: getWertebereichFromAngebot('zahlweise', action.angebot.versicherungen[0].wertebereiche),
                beitraege: action.angebot.beitraege,
                beitragstabelle: getBeitragstabelle(action.angebot)
            };
        }
        case AENDERE_VERSICHERUNGSFELD: {
            return {
                ...state,
                [action.payload.fieldName]: action.payload.fieldValue
            };
        }
        default:
            return state;
    }
};

const getBeitragstabelle = (angebot: OfferEngineAngebot): LvbBeitragstabelle[] => {
    const beitragstabelle_KFO: Beitragstabelle | undefined = angebot.beitragstabelle.find(
        (tabelle: Beitragstabelle) => tabelle.leistungsvereinbarungsart === Leistungsvereinbarungsart.ZAHN_KFO
    );

    if (!beitragstabelle_KFO) {
        return [];
    }

    return beitragstabelle_KFO.lvbBeitragstabelle;
};
