import React from 'react';
import { IAppState } from '../../app/IAppState';
import { connect } from 'react-redux';
import SalesmanButton from '@eg/elements/SalesmanButton';
import { Versandweg } from '../../util/fetch/offerengine/OfferEngineAngebotDto';
import { Dispatch } from 'redux';
import RadioGroupRow from '@eg/elements/RadioGroupRow';
import { createRadioButtons } from '../../util/WertebereicheHelper';
import { updateVersandwegSyncAsync, doAbschluss } from '../AbschlussAction';
import { RadioEvent } from '../../util/UiEventTypes';
import { AbschlussDetailInputProps } from '../Abschluss';
import { EmailSelection } from '../email/EmailSelection';
import { VERSANDWEGDIGITALE_KUNDENAKTE, VERSANDWEGE } from './VersandwegWertebereich';
import { VERSAND_BUTTON, VERSAND_RADIOGROUP } from '../../util/RanorexIds';
import { dkaEnabledSelector } from './DkaEnabledSelector';
import LoadingSpinner from '@eg/elements/LoadingSpinner';

interface VersandPresentationProps extends VersandOwnProps, VersandDispatchProps {}

interface VersandPresentationState {
    versandweg: Versandweg | undefined;
}

export class VersandPresentation extends React.Component<VersandPresentationProps, VersandPresentationState> {
    constructor(props: VersandPresentationProps) {
        super(props);
        this.state = {
            versandweg: props.versandweg
        };
    }

    changeVersandweg = (event: RadioEvent) => {
        //@ts-ignore
        const versandweg: Versandweg = event.target.value as Versandweg;
        this.setState(() => ({
            versandweg: versandweg
        }));
        this.props.handleOnChangeVersandweg(versandweg);
    };

    render() {
        const { versicherung, oberflaecheSperren, handleOnAntragAbschliessenClick, hasErrorMeldungen, setButtonLoading, disableAbschlussButton, dkaEnabled, setSpinner } = this.props;

        return (
            <div className="esc_grid component-content">
                <div className="esc_grid__wrapper">
                    <div className="esc_col esc_col-s-6">
                        <RadioGroupRow
                            label="Versandkanal"
                            name="versandweg"
                            value={this.state.versandweg || ''}
                            disabled={oberflaecheSperren}
                            onChange={this.changeVersandweg}
                            ranorex-id={VERSAND_RADIOGROUP}
                        >
                            {createRadioButtons(VERSANDWEGE)}
                            {dkaEnabled && createRadioButtons(VERSANDWEGDIGITALE_KUNDENAKTE)}
                        </RadioGroupRow>
                    </div>
                    <LoadingSpinner show={setSpinner}/>
                    {this.state.versandweg === Versandweg.EMAIL && (
                        <div className="esc_col esc_col-s-12">
                            <EmailSelection oberflaecheSperren={oberflaecheSperren} />
                        </div>
                    )}

                    <div className="esc_col esc_col-s-12">
                        <SalesmanButton
                            text="Angebot erstellen"
                            subText=""
                            note=""
                            // variant="primary"
                            id="header_button"
                            disabled={oberflaecheSperren || hasErrorMeldungen || versicherung === null || this.state.versandweg === null || disableAbschlussButton}
                            loading={setButtonLoading}
                            onClick={() => {
                                handleOnAntragAbschliessenClick();
                            }}
                            ranorex-id={VERSAND_BUTTON}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

const scrollToHeader = (): void => {
    const header = document && document.getElementById('header_button');
    !header && console.warn('Versand', 'Element header_button nicht gefunden');
    header && header.scrollIntoView();
};

const mapStateToProps = (state: IAppState, props: AbschlussDetailInputProps): VersandOwnProps => ({
    versandweg: state.abschluss.versandAbschluss.versandweg || undefined,
    hasErrorMeldungen: props.hasErrorMeldungen,
    oberflaecheSperren: props.oberflaecheSperren,
    versicherung: props.versicherung,
    setButtonLoading: state.abschluss.versandAbschluss.setButtonLoading,
    disableAbschlussButton: props.disableAbschlussButton,
    dkaEnabled: dkaEnabledSelector(state),
    setSpinner: state.abschluss.setSpinner
});

interface VersandDispatchProps {
    handleOnAntragAbschliessenClick: () => void;
    handleOnChangeVersandweg: (versandweg: Versandweg) => void;
}

interface VersandOwnProps extends AbschlussDetailInputProps {
    versandweg: Versandweg | undefined;
    setButtonLoading: boolean;
    dkaEnabled: boolean;
    setSpinner: boolean;
}

const mapDispatchToProps = (dispatch: Dispatch): VersandDispatchProps => ({
    handleOnChangeVersandweg: (versandweg: Versandweg) => {
        // @ts-ignore
        dispatch(updateVersandwegSyncAsync(versandweg));
    },
    handleOnAntragAbschliessenClick: () => {
        // @ts-ignore
        dispatch(doAbschluss());
        scrollToHeader();
    }
});

export const Versand = connect(mapStateToProps, mapDispatchToProps)(VersandPresentation);
