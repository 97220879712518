import { Verkaufsprozessart } from '../util/fetch/offerengine/OfferEngineAngebotDto';
import { WertebereichLokal } from '../util/WertebereicheHelper';

export const WertebereichVerkaufsprozessartenMapping: WertebereichLokal[] = [
    {
        value: Verkaufsprozessart.DIREKTABSCHLUSS,
        name: 'Direkt abschliessen'
    },
    {
        value: Verkaufsprozessart.VOLLANGEBOT,
        name: 'Vollangebot'
    }
];
