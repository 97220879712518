import React from 'react';
import InputRow from '@eg/elements/InputRow';
import { InputEvent, InputKeyboardEvent } from '../../util/UiEventTypes';
import { EMAIL_INPUT } from '../../util/RanorexIds';

interface EmailInputRowProps {
    label: string;
    disabled: boolean;
    value: string;
    onLeave: (event: InputEvent | InputKeyboardEvent) => void;
}

interface EmailInputRowState {
    mailAdresse: string;
}

export default class EmailInputRow extends React.Component<EmailInputRowProps, EmailInputRowState> {
    constructor(props: EmailInputRowProps) {
        super(props);
        this.state = {
            mailAdresse: props.value
        };
    }

    changeMailadresse = (event: InputEvent) => {
        event.persist();
        //@ts-ignore
        const value = event.target.value.toLowerCase();
        this.setState(() => ({
            mailAdresse: value
        }));
    };

    getErrortext = (mailaddress: string): boolean | string => {
        if (!isEmailValid(mailaddress)) {
            return true;
        }

        // @ts-ignore
        if (1 === 2 && isCompanyMailaddress(mailaddress)) {
            return 'Bitte private E-Mail-Adresse angeben.';
        }

        return false;
    };

    render() {
        return (
            <>
                <InputRow
                    label={this.props.label}
                    disabled={this.props.disabled}
                    value={this.state.mailAdresse}
                    error={this.getErrortext(this.state.mailAdresse)}
                    onChange={this.changeMailadresse}
                    onBlur={(e: InputEvent) => (!this.getErrortext(this.state.mailAdresse) ? this.props.onLeave(e) : null)}
                    onKeyPress={(e: InputKeyboardEvent) => (e.key === 'Enter' ? (!this.getErrortext(this.state.mailAdresse) ? this.props.onLeave(e) : null) : null)}
                    ranorex-id={EMAIL_INPUT}
                />
            </>
        );
    }
}

const isEmailValid = (mailadresse: string): boolean => {
    const regex: RegExp = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
    return regex.test(mailadresse);
};

const isCompanyMailaddress = (mailaddress: string): boolean => {
    const MAILDOMAIN_BLACKLIST: string[] = [
        '@victoria.de',
        '@ergo.de',
        '@ergo.digital',
        '@ergo.com',
        '@das.de',
        '@viv.de',
        '@dkv.com',
        '@hamburg-mannheimer.de',
        '@longial.de',
        '@itergo.com',
        '@danv.de',
        '@ergodirekt.de'
    ];
    return mailaddress ? MAILDOMAIN_BLACKLIST.some(domain => mailaddress.toLowerCase().endsWith(domain)) : false;
};
