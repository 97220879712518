import { IAppState } from '../../app/IAppState';
import { versicherungsnehmerPersonSelector } from '../../util/Selectors';
import { ANDERE_EMAIL, WAEHLE_EMAIL } from '../AbschlussReducer';

export const versandmailSelector = (state: IAppState): string =>
    state.abschluss.versandmail && state.abschluss.versandmail.length > 0
        ? state.abschluss.versandmail
        : versicherungsnehmerPersonSelector(state)!.mailAdressen.length > 1
        ? WAEHLE_EMAIL
        : ANDERE_EMAIL;
