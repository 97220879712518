import {
    AddRolleAction,
    DeletePersonAction,
    DeleteRolleAction,
    LADE_PERSONEN,
    LoadPersonsAction,
    PERSON_LOESCHEN,
    PERSON_SPEICHERN,
    ROLLE_HINZUFUEGEN,
    ROLLE_LOESCHEN,
    ShowMaxNumberVPAction,
    ShowPersonLoadingSpinnerAction,
    SpeicherPersonAction,
    ZEIGE_LOADINGSPINNER_PERSON,
    ZEIGE_MAXNUMBER_VP
} from './PersonenAction';
import { Adresse, Anrede, Kennzeichen, MailAdresse, PdsPerson, Telefonnummer } from '../util/fetch/personen/PersonDto';
import { Bankverbindung, Rolle, Rollentyp, Rollenwertebereich } from '../util/fetch/offerengine/OfferEngineAngebotDto';
import { LADE_ANGEBOT, LoadOfferAction } from '../app/AppAction';
import { ZEIGE_SAVINGSPINNER } from '../personensuche/PersonensucheAction';
import { mapPdsPersonToStatePerson } from './PersonenMapper';

export interface PersonenState {
    personen: Personen;
    showPersonLoadingSpinner: boolean;
    maxNumberVP: number;
    showModalMaxNumberVP: boolean;
}

export interface Personen {
    [Key: string]: Person; // Key ist die pdsId
}

export interface Person {
    kundennummerParis: string | null;
    pdsId: string;
    geburtsdatum: Date;
    anrede: Anrede;
    nachname: string;
    vorname: string;
    titel: string;
    mailAdressen: MailAdresse[];
    adresse: Adresse;
    bankverbindungen: Bankverbindung[];
    rollen: Rollentyp[];
    telefonnummer: Telefonnummer;
    adressnummer: number;
    kennzeichen: Kennzeichen[];
}

export const initialPersonenState = {
    personen: {},
    showPersonLoadingSpinner: false,
    maxNumberVP: -1,
    showModalMaxNumberVP: false
};

type PersonenAction = LoadOfferAction &
    LoadPersonsAction &
    SpeicherPersonAction &
    AddRolleAction &
    DeleteRolleAction &
    DeletePersonAction &
    ShowPersonLoadingSpinnerAction &
    ShowMaxNumberVPAction;

export const personenReducer = (state: PersonenState = initialPersonenState, action: PersonenAction): PersonenState => {
    switch (action.type) {
        case LADE_PERSONEN: {
            const personen: Personen = action.personen.reduce(
                (currentPersonen: Personen, person: PdsPerson) => ({
                    ...currentPersonen,
                    [person.personId]: mapPdsPersonToStatePerson(person, state.personen)
                }),
                state.personen
            );

            return {
                ...state,
                personen: personen
            };
        }
        case LADE_ANGEBOT: {
            const personen: Personen = action.angebot.rollen
                ? action.angebot.rollen.reduce(
                      (currentPersonen: Personen, rolle: Rolle) => ({
                          ...currentPersonen,
                          [rolle.reference.personId]: mapPersonMitRollen(rolle.reference.personId, state.personen, action.angebot.rollen)
                      }),
                      // bei Personen die zu keiner Rolle gehoeren die (vorhandenen) Rollen entfernen
                      state.personen
                          ? Object.values(state.personen).reduce(
                                (currentPersonen: Personen, person: Person) => ({
                                    ...currentPersonen,
                                    [person.pdsId]: { ...person, rollen: [] }
                                }),
                                {}
                            )
                          : {}
                  )
                : state.personen;

            let maxNumberVP: number = initialPersonenState.maxNumberVP;

            if (
                action.angebot.rollenWertebereiche &&
                action.angebot.rollenWertebereiche.find((rollenWertebereich: Rollenwertebereich) => rollenWertebereich.rollentyp === Rollentyp.VERSICHERTE_PERSON)
            ) {
                maxNumberVP = action.angebot.rollenWertebereiche.find((rollenWertebereich: Rollenwertebereich) => rollenWertebereich.rollentyp === Rollentyp.VERSICHERTE_PERSON)!
                    .max;
            }

            return {
                ...state,
                personen: personen,
                maxNumberVP: maxNumberVP
            };
        }
        case PERSON_SPEICHERN: {
            const mappedPerson = mapPdsPersonToStatePerson(action.person, state.personen);
            const person: Person | undefined = Object.values(state.personen).find((person: Person) => person.kundennummerParis === mappedPerson.kundennummerParis);

            if (!person) {
                return {
                    ...state,
                    personen: {
                        ...state.personen,
                        [mappedPerson.pdsId]: mappedPerson
                    }
                };
            }

            const personenCopy: Person[] = Object.values(state.personen).map(person => (person.kundennummerParis !== mappedPerson.kundennummerParis ? person : mappedPerson));

            const personen: Personen = personenCopy.reduce(
                (currentPersonen: Personen, person: Person) => ({
                    ...currentPersonen,
                    [person.pdsId]: person
                }),
                {}
            );

            return {
                ...state,
                personen: personen
            };
        }
        case PERSON_LOESCHEN: {
            return {
                ...state,
                personen: Object.keys(state.personen)
                    .filter((key: string) => key !== action.pdsId)
                    .reduce((currentPersonen: Personen, currentKey: string) => {
                        currentPersonen[currentKey] = state.personen[currentKey];
                        return currentPersonen;
                    }, {})
            };
        }
        case ROLLE_HINZUFUEGEN: {
            return {
                ...state,
                personen: {
                    ...state.personen,
                    [action.payload.pdsId]: {
                        ...state.personen[action.payload.pdsId],
                        rollen: state.personen[action.payload.pdsId].rollen.concat([action.payload.rollentyp])
                    }
                }
            };
        }
        case ROLLE_LOESCHEN: {
            return {
                ...state,
                personen: {
                    ...state.personen,
                    [action.payload.pdsId]: {
                        ...state.personen[action.payload.pdsId],
                        rollen: state.personen[action.payload.pdsId].rollen.filter((rolle: Rollentyp) => rolle !== action.payload.rollentyp)
                    }
                }
            };
        }
        case ZEIGE_SAVINGSPINNER:
        case ZEIGE_LOADINGSPINNER_PERSON: {
            return {
                ...state,
                showPersonLoadingSpinner: action.showSpinner
            };
        }
        case ZEIGE_MAXNUMBER_VP: {
            return {
                ...state,
                showModalMaxNumberVP: action.displayModal
            };
        }
        default:
            return state;
    }
};

const mapPersonMitRollen = (personId: string, personen: Personen, rollen: Rolle[]): Person => {
    return {
        ...personen[personId],
        kundennummerParis: personen[personId] && personen[personId].kundennummerParis ? personen[personId].kundennummerParis : null,
        pdsId: personId,
        geburtsdatum: personen[personId] && personen[personId].geburtsdatum,
        anrede: personen[personId] && personen[personId].anrede ? personen[personId].anrede : Anrede.Frau,
        nachname: personen[personId] && personen[personId].nachname ? personen[personId].nachname : '',
        vorname: personen[personId] && personen[personId].vorname ? personen[personId].vorname : '',
        titel: personen[personId] && personen[personId].titel ? personen[personId].titel : '',
        mailAdressen: personen[personId] && personen[personId].mailAdressen ? personen[personId].mailAdressen : [],
        adresse: personen[personId] && personen[personId].adresse ? personen[personId].adresse : getDefaultAddress(),
        bankverbindungen: personen[personId] && personen[personId].bankverbindungen ? personen[personId].bankverbindungen : [],
        rollen: mapRollen(rollen, personId),
        kennzeichen: personen[personId] && personen[personId].kennzeichen ? personen[personId].kennzeichen : []
    };
};

const getDefaultAddress = () => ({
    adressZusatz: '',
    hausnummer: '',
    hausnummerZusatz: '',
    land: '',
    ort: '',
    ortZusatz: '',
    postleitzahl: '',
    strasse: ''
});

const mapRollen = (rollen: Rolle[], pdsId: string): Rollentyp[] => {
    let result: Rollentyp[] = [];

    const rollenPdsId: Rolle[] = rollen.filter((rolle: Rolle) => rolle.reference.personId === pdsId);

    if (rollenPdsId && rollenPdsId.length > 0) {
        return rollenPdsId.map((rolle: Rolle) => {
            return rolle.rollentyp;
        });
    }
    return result;
};
