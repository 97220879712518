import { Dispatch } from 'redux';
import { changeBankdatenHttpRequest, getBankdatenHttpRequest } from '../util/fetch/bankdaten/BankdatenController';
import { FrontendResponse } from '../util/fetch/client/FrontendResponse';
import { BankdatenDto } from '../util/fetch/bankdaten/BankdatenDto';
import { OfferEngineBankverbindungRequestDto } from '../util/fetch/offerengine/OfferEngineBankverbindungRequestDto';
import { updateBankverbindungHttpRequest, updateAbbuchungstagHttpRequest } from '../util/fetch/offerengine/OfferEngineController';
import { onRejectedStoreException } from '../exceptions/ExceptionsAction';
import { onFulfilledStoreOffer } from '../app/AppAction';
import { Person } from '../personen/PersonenReducer';
import { AbbuchungstagImMonat, Verkaufsprozessart } from '../util/fetch/offerengine/OfferEngineAngebotDto';
import { UpdateAbbuchungstagRequestDto } from '../util/fetch/offerengine/UpdateAbbuchungstagRequestDto';
import { IAppState } from '../app/IAppState';
import { getSpcsPersonMappedResponse } from '../util/spcsResponse';
import { normalizeIban } from '@eg/elements/utils/validation/iban';
import { MailAdresse } from '../util/fetch/personen/PersonDto';

export const AKTUALISIERE_VERSICHERUNGSNEHMER: string = 'AKTUALISIERE_VERSICHERUNGSNEHMER';
export const AKTUALISIERE_MAIL_ADRESSE: string = 'AKTUALISIERE_MAIL_ADRESSE';
export const AKTUALISIERE_IBAN: string = 'AKTUALISIERE_IBAN';
export const AENDERE_IBAN_ENTRY: string = 'AENDERE_IBAN_ENTRY';
export const AENDERE_AKTIVIERUNG_IBAN_FELD: string = 'AENDERE_AKTIVIERUNG_IBAN_FELD';
export const ZEIGE_BANKVERBINDUNG_WIRD_GELADEN_SPINNER: string = 'ZEIGE_BANKVERBINDUNG_WIRD_GELADEN_SPINNER';
export const SETZE_IBAN_NICHT_VALIDE: string = 'SETZE_IBAN_NICHT_VALIDE';
export const AENDERE_ABBUCHUNGSTAG: string = 'AENDERE_ABBUCHUNGSTAG';
export const SETZE_IBAN_MELDUNG: string = 'SETZE_IBAN_MELDUNG';

export enum IbanVerificationStatus {
    NOT_VERIFIED = 'NOT_VERIFIED',
    SUCCESSFULLY_VERIFIED = 'SUCCESSFULLY_VERIFIED',
    NOT_SUCCESSFULLY_VERIFIED = 'NOT_SUCCESSFULLY_VERIFIED'
}

export interface SaveNewVersicherungsnehmerAction {
    type: typeof AKTUALISIERE_VERSICHERUNGSNEHMER;
    versicherungsnehmer: Person | null;
}
export const saveNewVersicherungsnehmerSync = (versicherungsnehmer: Person | null): SaveNewVersicherungsnehmerAction => {
    return {
        type: AKTUALISIERE_VERSICHERUNGSNEHMER,
        versicherungsnehmer: versicherungsnehmer
    };
};

export interface UpdateVersicherungsnehmerEmailAction {
    type: typeof AKTUALISIERE_MAIL_ADRESSE;
    mailAdressen: MailAdresse[];
}
export const updateVersicherungsnehmerEmailSync = (mailAdressen: MailAdresse[]): UpdateVersicherungsnehmerEmailAction => {
    return {
        type: AKTUALISIERE_MAIL_ADRESSE,
        mailAdressen: mailAdressen
    };
};

export interface ChangeSelectedIbanRowEntryAction {
    type: typeof AKTUALISIERE_IBAN;
    iban: string;
    verkaufsprozessart: Verkaufsprozessart | null;
}

export const changeSelectedRowEntrySync = (iban: string, verkaufsprozessart: Verkaufsprozessart | null): ChangeSelectedIbanRowEntryAction => {
    return {
        type: AKTUALISIERE_IBAN,
        iban: iban,
        verkaufsprozessart: verkaufsprozessart
    };
};

export interface UpdateIbanEntryAction {
    type: typeof AENDERE_IBAN_ENTRY;
    iban: string;
}
export const updateIbanEntrySync = (iban: string): UpdateIbanEntryAction => {
    return {
        type: AENDERE_IBAN_ENTRY,
        iban: iban
    };
};

export interface ChangeActivationIbanFieldAction {
    type: typeof AENDERE_AKTIVIERUNG_IBAN_FELD;
    isIbanUnderVerification: boolean;
}
export const changeActivationIbanFieldSync = (isIbanUnderVerification: boolean): ChangeActivationIbanFieldAction => {
    return {
        type: AENDERE_AKTIVIERUNG_IBAN_FELD,
        isIbanUnderVerification: isIbanUnderVerification
    };
};

export interface ShowBankverbindungIsLoadingSpinnerAction {
    type: typeof ZEIGE_BANKVERBINDUNG_WIRD_GELADEN_SPINNER;
    showBankverbindungIsLoadingSpinner: boolean;
}
export const showBankverbindungIsLoadingSpinnerSync = (showBankverbindungIsLoadingSpinner: boolean): ShowBankverbindungIsLoadingSpinnerAction => {
    return {
        type: ZEIGE_BANKVERBINDUNG_WIRD_GELADEN_SPINNER,
        showBankverbindungIsLoadingSpinner: showBankverbindungIsLoadingSpinner
    };
};

export interface IbanNotValidAction {
    type: typeof SETZE_IBAN_NICHT_VALIDE;
}
export const updateIbanNotValidSync = (): IbanNotValidAction => {
    return {
        type: SETZE_IBAN_NICHT_VALIDE
    };
};

export interface SetzeIbanMeldungAction {
    type: typeof SETZE_IBAN_MELDUNG;
    payload: {
        iban: string;
        verkaufsprozessart: Verkaufsprozessart | null;
    };
}

export const setzeIbanMeldungSync = (iban: string, verkaufsprozessart: Verkaufsprozessart | null): SetzeIbanMeldungAction => {
    return {
        type: SETZE_IBAN_MELDUNG,
        payload: {
            iban,
            verkaufsprozessart
        }
    };
};

export const verifyAndUpdateBankverbindungAsync = (iban: string) => {
    return (dispatch: Dispatch, getState: () => IAppState) => {
        dispatch(changeActivationIbanFieldSync(true));
        let waitForBankdatenValidation = setTimeout(() => {
            dispatch(showBankverbindungIsLoadingSpinnerSync(true));
        }, 300);

        const businessId = getState().basisdaten.businessId;
        const personId = getState().bankverbindung.versicherungsnehmer?.pdsId;
        const kontoinhaber = getState().bankverbindung.kontoinhaber;
        return getBankdatenHttpRequest(businessId, iban, dispatch, personId)
            .then((response: FrontendResponse<BankdatenDto>) => {
                clearTimeout(waitForBankdatenValidation);
                if (response.payload && response.payload.bankdaten) {
                    const request: OfferEngineBankverbindungRequestDto = {
                        bankverbindung: {
                            kontoinhaber: kontoinhaber,
                            iban: response.payload.bankdaten.iban,
                            bic: response.payload.bankdaten.bic,
                            bankname: response.payload.bankdaten.bank.participantName
                        }
                    };
                    updateBankverbindungHttpRequest(businessId, request, dispatch)
                        .then(response =>  getSpcsPersonMappedResponse(response))
                        .then(response => onFulfilledStoreOffer(response, dispatch))
                        .catch(e => onRejectedStoreException(e, dispatch));
                } else {
                    dispatch(updateIbanNotValidSync());
                    dispatch(setzeIbanMeldungSync('', getState().basisdaten.verkaufsprozessart));
                }
            })
            .catch((e: Error) => {
                clearTimeout(waitForBankdatenValidation);
                onRejectedStoreException(e, dispatch);
            })
            .finally(() => {
                dispatch(showBankverbindungIsLoadingSpinnerSync(false));
                dispatch(changeActivationIbanFieldSync(false));
            });
    };
};

export const changeBankverbindungAsync = (iban: string) => {
    return (dispatch: Dispatch, getState: () => IAppState) => {
        dispatch(changeActivationIbanFieldSync(true));
        let waitForBankdatenValidation = setTimeout(() => {
            dispatch(showBankverbindungIsLoadingSpinnerSync(true));
        }, 300);

        const businessId = getState().basisdaten.businessId;
        const personId = getState().bankverbindung.versicherungsnehmer?.pdsId;
        const paymentId = getState().bankverbindung.paymentId
        return changeBankdatenHttpRequest(businessId, normalizeIban(iban), dispatch, personId, paymentId)
            .then((response: FrontendResponse<BankdatenDto>) => {
                clearTimeout(waitForBankdatenValidation);
            })
            .catch((e: Error) => {
                clearTimeout(waitForBankdatenValidation);
                onRejectedStoreException(e, dispatch);
            })
            .finally(() => {
                dispatch(showBankverbindungIsLoadingSpinnerSync(false));
                dispatch(changeActivationIbanFieldSync(false));
            });
    };
};

export interface ChangeAbbuchungstagAction {
    type: typeof AENDERE_ABBUCHUNGSTAG;
    abbuchungstagImMonat: AbbuchungstagImMonat;
}

const changeAbbuchungstagSync = (abbuchungstagImMonat: AbbuchungstagImMonat): ChangeAbbuchungstagAction => {
    return {
        type: AENDERE_ABBUCHUNGSTAG,
        abbuchungstagImMonat
    };
};

export const updateAbbuchungstagAsync = (businessId: string, abbuchungstagImMonat: AbbuchungstagImMonat) => {
    return (dispatch: Dispatch) => {
        dispatch(changeAbbuchungstagSync(abbuchungstagImMonat));
        const request: UpdateAbbuchungstagRequestDto = {
            abbuchungstagImMonat
        };

        return updateAbbuchungstagHttpRequest(businessId, request, dispatch)
            .then(response => onFulfilledStoreOffer(response, dispatch))
            .catch(e => onRejectedStoreException(e, dispatch));
    };
};
