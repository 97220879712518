/*
 * Copyright der ERGO Direkt . All Rights reserved.
 * Projekt: telefon-kranken-frontend
 * Informationsklassifikation: vertraulich
 * Dateiname: meldungenReducer.ts
 * User: miarnedo
 * Zuletzt bearbeitet: 13.03.18 12:53
 */

import { IUnauthenticated, UNAUTHENTICATED } from './unauthenticatedTypes'

export const initialUnauthenticatedState: boolean = false;

function unauthenticatedReducer(state: boolean = initialUnauthenticatedState, action: IUnauthenticated) {
    switch (action.type) {
        case UNAUTHENTICATED:
            return action.payload;
        default:
            return state;
    }
}

export default unauthenticatedReducer;
