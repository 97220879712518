import { ErrorObject, Ursprung } from '../util/fetch/client/ErrorObject';
import { Dispatch } from 'redux';

export const SPEICHERE_EXCEPTION: string = 'SPEICHERE_EXCEPTION';
export type SPEICHERE_EXCEPTION = typeof SPEICHERE_EXCEPTION;

export interface StoreExceptionAction {
    type: SPEICHERE_EXCEPTION;
    errorObject: ErrorObject;
}

export const storeExceptionSync = (errorObject: ErrorObject): StoreExceptionAction => {
    return {
        type: SPEICHERE_EXCEPTION,
        errorObject: errorObject
    };
};

export function onRejectedStoreException(e: Error, dispatch: Dispatch, className?: string): void {
    const errorObject: ErrorObject = {
        className,
        causeClassName: e.name,
        message: e.message,
        ursprung: Ursprung.URSPRUNG_RECEIVED_PROMISE
    };
    dispatch(storeExceptionSync(errorObject));
}
