import React from 'react';
import { IAppState } from '../app/IAppState';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import Panel from '@eg/elements/Panel';
import { Person } from './PersonenReducer';
import { MailAdresse } from '../util/fetch/personen/PersonDto';
import Button from '@eg/elements/Button';
import { Rollentyp } from '../util/fetch/offerengine/OfferEngineAngebotDto';
import { getWertebereichElementName, WertebereichLokal } from '../util/WertebereicheHelper';
import { wertebereichMailTyp, wertebereichRollen } from './PersonenWertebereiche';
import CloseIcon from '@eg/elements/components/Icons/CloseIcon';
import { addRolleAsync, deletePersonAsync, deleteRolleVPAsync, showMaxNumberVPSync } from './PersonenAction';
import LoadingSpinner from '@eg/elements/LoadingSpinner';
import { datumToString } from '../util/DateFormattingHelper';
import Modal from '@eg/elements/Modal';
import { UIMouseEvent } from '../util/UiEventTypes';

interface PersonenPresentationProps extends PersonenProps, PersonenDispatchProps {}

interface PersonenProps {
    personen: Person[];
    showPersonLoadingSpinner: boolean;
    showModalMaxNumberVP: boolean;
    oberflaecheSperren: boolean;
}

interface PersonenDispatchProps {
    handleDeletePerson: (personToDelete: Person) => void;
    handleAddRolle: (personToAddRolle: Person, rolle: Rollentyp) => void;
    handleRemoveRolleVP: (personPdsIdToRemoveRolle: string) => void;
    handleDismissModalMaxNumberVP: (e: UIMouseEvent | undefined) => void;
}

export class PersonenPresentation extends React.Component<PersonenPresentationProps, {}> {
    showRollWerber = (rolle: Rollentyp, person: Person) =>
    <div key={person.pdsId + rolle} className="rolle">
        <Button
            variant="tertiary"
            disabled={true}
        >
            {Rollentyp.WERBER}
        </Button>
    </div>

    showRolle = (rolle: Rollentyp, person: Person) =>
        person.rollen && person.rollen.includes(rolle) ? (
            <div key={person.pdsId + rolle} className="rolle">
                <Button
                    variant="primary"
                    disabled={this.props.oberflaecheSperren || rolle === Rollentyp.VERSICHERUNGSNEHMER}
                    onClick={() => this.props.handleRemoveRolleVP(person.pdsId)}
                >
                    {getWertebereichElementName(wertebereichRollen, rolle)}
                </Button>
            </div>
        ) : (
            <div key={person.pdsId + rolle} className="rolle">
                <Button
                    variant="tertiary"
                    disabled={this.props.oberflaecheSperren || person.rollen.includes(Rollentyp.VERSICHERUNGSNEHMER)}
                    onClick={() => this.props.handleAddRolle(person, rolle)}
                >
                    {getWertebereichElementName(wertebereichRollen, rolle)}
                </Button>
            </div>
        );

    render() {
        const { personen, showPersonLoadingSpinner, oberflaecheSperren, handleDeletePerson, showModalMaxNumberVP, handleDismissModalMaxNumberVP } = this.props;

        return (
            <div className="personen-block">
                <div className="esc_grid">
                    <div className="esc_grid__wrapper">
                        <div className="esc_col esc_col-s-12">
                            <Panel>
                                <h2 className="esc_h4">Personen</h2>
                            </Panel>
                        </div>
                    </div>
                </div>
                <div className="esc_grid component-content">
                    <div className="esc_grid__wrapper">
                        <div
                            className="esc_col esc_col-s-12"
                            style={{
                                position: 'relative'
                            }}
                        >
                            <div className="esc_simple-table">
                                <table>
                                    <thead>
                                        <tr>
                                            <th />
                                            <th>Name, Kdnr.</th>
                                            <th>Geburtsdatum</th>
                                            <th>Adresse</th>
                                            <th>E-Mail</th>
                                            <th>Rollen</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {personen.map((person: Person) => {
                                            return (
                                                <tr key={person.pdsId}>
                                                    <td className="personen-block-delete">
                                                        {person.rollen && !person.rollen.includes(Rollentyp.VERSICHERUNGSNEHMER) && !person.rollen.includes(Rollentyp.WERBER) && (
                                                            <Button id="delete-button" disabled={oberflaecheSperren} variant="primary" width="auto" style={{borderRadius: '50%', padding: 0}} onClick={() => handleDeletePerson(person)}>
                                                                <div id="delete-button-content"><CloseIcon height={30} width={30} /></div>
                                                            </Button>
                                                        )}
                                                    </td>
                                                    <td>
                                                        {person.nachname}, {person.vorname} {person.titel ? `, ${person.titel}` : ''}
                                                        <br />
                                                        {person.kundennummerParis}
                                                    </td>
                                                    <td>{datumToString(person.geburtsdatum)}</td>
                                                    <td>
                                                        {person.adresse.strasse} {person.adresse.hausnummer}
                                                        {person.adresse.hausnummerZusatz}
                                                        <br />
                                                        {person.adresse.postleitzahl} {person.adresse.ort}
                                                    </td>
                                                    <td>
                                                        {person.mailAdressen.map((mailadresse: MailAdresse) => (
                                                            <p key={mailadresse.adresse}>
                                                                {getWertebereichElementName(wertebereichMailTyp, mailadresse.typ)}: {mailadresse.adresse}
                                                            </p>
                                                        ))}
                                                    </td>
                                                    <td>
                                                    {person.rollen.includes(Rollentyp.WERBER) ? this.showRollWerber(Rollentyp.WERBER, person):
                                                        wertebereichRollen.map((entry: WertebereichLokal) => {
                                                           return this.showRolle(entry.value as Rollentyp, person) ;
                                                        })}
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            </div>
                            <Modal open={showModalMaxNumberVP} dismissible backdropDismissesModal onDismiss={handleDismissModalMaxNumberVP}>
                                Es wurde bereits die maximale Anzahl an versicherten Personen erreicht.
                            </Modal>
                            <LoadingSpinner viewport="relative" show={showPersonLoadingSpinner} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: IAppState): PersonenProps => ({
    personen: Object.values(state.personen.personen),
    showPersonLoadingSpinner: state.personen.showPersonLoadingSpinner,
    showModalMaxNumberVP: state.personen.showModalMaxNumberVP,
    oberflaecheSperren: state.abschluss.abschlussErfolgreich
});

const mapDispatchToProps = (dispatch: Dispatch): PersonenDispatchProps => ({
    handleDeletePerson: (personToDelete: Person) => {
        //@ts-ignore
        dispatch(deletePersonAsync(personToDelete));
    },
    handleAddRolle: (personToAddRolle: Person, rolle: Rollentyp) => {
        //Person ist nicht VN -> dann kann neue Rolle gesetzt werden
        //@ts-ignore
        !personToAddRolle.rollen.includes(Rollentyp.VERSICHERUNGSNEHMER) && dispatch(addRolleAsync(personToAddRolle, rolle));
    },
    handleRemoveRolleVP: (personPdsIdToRemoveRolle: string) => {
        //@ts-ignore
        dispatch(deleteRolleVPAsync(personPdsIdToRemoveRolle));
    },
    handleDismissModalMaxNumberVP: (e: UIMouseEvent | undefined) => {
        dispatch(showMaxNumberVPSync(false));
    }
});

export const Personen = connect(mapStateToProps, mapDispatchToProps)(PersonenPresentation);
