import * as React from 'react';

const START_SEARCH_FROM_DEFAULT = 3;
const DEBOUNCE = 500;
const START_SEARCH_FROM = 3;

const autocomplete = (suggestionsList: string[], setSuggestions: (arg: string[]) => void, startSearch: number = START_SEARCH_FROM_DEFAULT) => (value: string) => {
    if (value.length < startSearch) {
        return;
    }

    setSuggestions(suggestionsList.filter(item => item.toLowerCase().includes(value.toLowerCase())));
};

const debounce = <F extends (...args: any[]) => any>(func: F, waitFor: number) => {
    let timeout: ReturnType<typeof setTimeout> | null = null;

    const debounced = (...args: Parameters<F>) => {
        if (timeout !== null) {
            clearTimeout(timeout);
            timeout = null;
        }
        timeout = setTimeout(() => func(...args), waitFor);
    };

    return debounced as (...args: Parameters<F>) => ReturnType<F>;
};

export const autoCompleteWithDebounce = (fullRelativesList: string[], setRelatives: (value: React.SetStateAction<string[]>) => void) =>
    debounce(autocomplete(fullRelativesList, setRelatives, START_SEARCH_FROM), DEBOUNCE);
