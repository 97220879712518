import { OfferEngineAngebotDto } from '../offerengine/OfferEngineAngebotDto';
import { FrontendResponse } from '../client/FrontendResponse';
import { Dispatch } from 'redux';
import { sendPostRequest, sendPutRequest } from '../client/FetchBaseClient';
import { PersonDto } from './PersonDto';
import { UpdateMailAdressenRequestDto } from './UpdateMailAdressenRequestDto';
import { AddRolleRequestDto, AddRolleResponseDto, ChangeRolleRequestDto, DeletePersonRequestDto } from '../personensuche/PersonRollenDto';

export const deletePersonHttpRequest = (businessId: string, payload: DeletePersonRequestDto, dispatch: Dispatch): Promise<FrontendResponse<OfferEngineAngebotDto>> => {
    return sendPostRequest(`/kfo/personen/${businessId}/loesche/person`, payload, dispatch);
};

export const updateMailAdressenHttpRequest = (businessId: string, payload: UpdateMailAdressenRequestDto, dispatch: Dispatch): Promise<FrontendResponse<PersonDto>> => {
    return sendPutRequest(`/kfo/personen/${businessId}/aktualisiere/mailadressen/`, payload, dispatch);
};

export const deleteRolleVPHttpRequest = (businessId: string, payload: ChangeRolleRequestDto, dispatch: Dispatch): Promise<FrontendResponse<OfferEngineAngebotDto>> => {
    return sendPostRequest(`/kfo/personen/${businessId}/loesche/rolle`, payload, dispatch);
};

export const addRolleHttpRequest = (businessId: string, payload: AddRolleRequestDto, dispatch: Dispatch): Promise<FrontendResponse<AddRolleResponseDto>> => {
    return sendPostRequest(`/kfo/personen/${businessId}/put/rolle`, payload, dispatch);
};
