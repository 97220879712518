import { Dispatch } from 'redux';
import { storeExceptionSync } from '../../../exceptions/ExceptionsAction';
import { storeMeldungenSync } from '../../../meldungen/MeldungenAction';
import { isAbsent } from '../../validate';
import { ErrorObject, Ursprung } from './ErrorObject';
import { FrontendResponse } from './FrontendResponse';
import { unauthenticatedAction } from '../../../auth/unauthenticatedAction'

export const sendGetRequest = <T>(endpoint: string, dispatch: Dispatch): Promise<FrontendResponse<T>> => {
    return sendHttpRequest(
        endpoint,
        {
            method: 'GET',
            cache: 'no-cache',
            mode: 'cors',
            // credentials: 'same-origin', // include, same-origin, *omit
            headers: {
                'Content-Type': 'application/json; charset=utf-8'
            }
        },
        dispatch
    );
};

export const sendPostRequest = <T>(endpoint: string, payload: any, dispatch: Dispatch, signal?: AbortSignal): Promise<FrontendResponse<T>> => {
    return sendHttpRequest(
        endpoint,
        {
            method: 'POST',
            cache: 'no-cache',
            mode: 'cors',
            // credentials: 'same-origin', // include, same-origin, *omit
            headers: {
                'Content-Type': 'application/json; charset=utf-8'
            },
            body: JSON.stringify(payload),
            signal
        },
        dispatch
    );
};

export const sendPutRequest = <T>(endpoint: string, payload: any, dispatch: Dispatch): Promise<FrontendResponse<T>> => {
    return sendHttpRequest(
        endpoint,
        {
            method: 'PUT',
            cache: 'no-cache',
            mode: 'cors',
            // credentials: 'same-origin', // include, same-origin, *omit
            headers: {
                'Content-Type': 'application/json; charset=utf-8'
            },
            body: JSON.stringify(payload)
        },
        dispatch
    );
};

export const sendDeleteRequest = <T>(endpoint: string, dispatch: Dispatch): Promise<FrontendResponse<T>> => {
    return sendHttpRequest(
        endpoint,
        {
            method: 'DELETE',
            cache: 'no-cache',
            mode: 'cors',
            // credentials: 'same-origin', // include, same-origin, *omit
            headers: {
                'Content-Type': 'application/json; charset=utf-8'
            }
        },
        dispatch
    );
};

export const getUrl = (endpoint: string): string => {
    let port: string = '';
    if (window.location.hostname === 'localhost') {
        port = ':3000';
    }

    let url = `${window.location.protocol}//${window.location.hostname}${port}/api/${endpoint}`;
    url = normalizeLink(url);
    return url;
};

const sendHttpRequest = async <T>(endpoint: string, requestInit: RequestInit, dispatch: Dispatch): Promise<FrontendResponse<T>> => {
    let response: Response;
    const url = getUrl(endpoint);
    /*
    if (process.env.NODE_ENV === 'development') {
        console.log(`${requestInit.method} ${url}`, requestInit.body);
    }
    */

    try {
        response = await fetch(url, requestInit);
        if (response.status < 400) {
            const frontendResponse: FrontendResponse<T> = await response.json();
            dispatch(storeMeldungenSync(frontendResponse.meldungen ? frontendResponse.meldungen : []));
            if (frontendResponse.angebot) {
                return {angebot: frontendResponse.angebot};
            } else {
                return {payload: frontendResponse.payload};
            }
        } else if (response.status === 401 || response.status === 403) {
            dispatch(unauthenticatedAction(true));
            return {}
        } else if (response.status === 404) {
            const errorObject: ErrorObject = {
                url: url,
                message: 'Die Anfrage der Ressource war nicht möglich.',
                httpStatusCode: response.status,
                httpStatusPhrase: 'Not Found',
                ursprung: Ursprung.URSPRUNG_FETCH_CLIENT
            };
            dispatch(storeExceptionSync(errorObject));
            return {
                errorObject
            };
        } else {
            const frontendResponse:any = await response.json();
            if (frontendResponse && frontendResponse.errorObject) {
                const errorObject: ErrorObject = frontendResponse.errorObject;
                errorObject.ursprung = Ursprung.URSPRUNG_BFF;
                dispatch(storeExceptionSync(errorObject));
                return {
                    errorObject
                };
            } else {
                let errorObject: ErrorObject;
                //In case of any specific error message returned from backend, show that error message on frontend
                if(frontendResponse && frontendResponse[0] && frontendResponse[0].schweregrad === "ERROR")
                {
                    errorObject = {
                        url: url,
                        message:frontendResponse[0].code,
                        causeMessage: frontendResponse[0].text,
                        httpStatusCode: response.status,
                        httpStatusPhrase: response.statusText,
                        ursprung: Ursprung.URSPRUNG_FETCH_CLIENT
                    };
                }
                else {
                    errorObject = {
                        url: url,
                        message: 'Die Anfrage der Ressource war nicht möglich.',
                        httpStatusCode: response.status,
                        httpStatusPhrase: response.statusText,
                        ursprung: Ursprung.URSPRUNG_FETCH_CLIENT
                };
            }
                dispatch(storeExceptionSync(errorObject));
                return {
                    errorObject
                };   
            }
        }
    } catch (e) {
        let errorObject: ErrorObject = {
            url: url,
            message: '',
            ursprung: Ursprung.URSPRUNG_FETCH_CLIENT
        };
        if (e instanceof Error) {
            errorObject.message =  e.message
    
            if ('AbortError' !== e.name) {
                dispatch(storeExceptionSync(errorObject));
            }
            
         }
        
         return {
            errorObject
        };
    }
};

export const normalizeLink = (url: string): string => {
    if (isAbsent(url)) {
        throw new Error('Link kann nicht normalisiert werden! Link hat keinen Wert!');
    }
    const urlSplitted: RegExpMatchArray | null = url.match(/(^https?:\/\/)(.*)/);
    // @ts-ignore => TS is wrong => urlSplitted cannot be null or undefined at this position
    if (isAbsent(urlSplitted) || urlSplitted.length < 2) {
        throw new Error('Link kann nicht normalisiert werden! Link ist keine valide URL: [' + url + ']');
    }

    // @ts-ignore => TS is wrong => urlSplitted cannot be null or undefined at this position
    return urlSplitted[1] + urlSplitted[2].replace(/\/{2,}/g, '/');
};
