import React from 'react';
import { Verkaufsprozessart } from '../util/fetch/offerengine/OfferEngineAngebotDto';
import { IAppState } from '../app/IAppState';
import { bindActionCreators, Dispatch } from 'redux';
import { updateVerkaufsprozessartAsync } from './AbschlusswunschAction';
import Panel from '@eg/elements/Panel';
import RadioGroupRow from '@eg/elements/RadioGroupRow';
import { createRadioButtonsFromWertebereichAngebot } from '../util/WertebereicheHelper';
import { RadioEvent } from '../util/UiEventTypes';
import { WertebereichVerkaufsprozessartenMapping } from './AbschlusswunschWertebereich';
import { connect } from 'react-redux';
import { ABSCHLUSSWUNSCH_RADIOGROUP } from '../util/RanorexIds';

interface AbschlusswunschPresentationProps extends AbschlusswunschProps, AbschlusswunschDispatchProps {}

interface AbschlusswunschProps {
    businessId: string;
    verkaufsprozessart: Verkaufsprozessart | null;
    werteVerkaufsprozessart: string[];
    oberflaecheSperren: boolean;
}

interface AbschlusswunschDispatchProps {
    handleChangeVerkaufsprozessart: (businessId: string, verkaufsprozessart: Verkaufsprozessart) => void;
}

export class AbschlusswunschPresentation extends React.Component<AbschlusswunschPresentationProps, {}> {
    render() {
        const { businessId, oberflaecheSperren, verkaufsprozessart, werteVerkaufsprozessart, handleChangeVerkaufsprozessart } = this.props;

        return (
            <div>
                <div className="esc_grid">
                    <div className="esc_grid__wrapper">
                        <div className="esc_col esc_col-s-12">
                            <Panel>
                                <h2 className="esc_h4">Abschlusswunsch</h2>
                            </Panel>
                        </div>
                    </div>
                </div>
                <div className="esc_grid component-content">
                    <div
                        className="esc_grid__wrapper"
                        style={{
                            marginBottom: -60
                        }}
                    >
                        <div className="esc_col esc_col-s-2">
                            <RadioGroupRow
                                label=""
                                disabled={oberflaecheSperren}
                                name="Abschlusswunsch"
                                style={{
                                    marginTop: -20
                                }}
                                value={verkaufsprozessart || ''}
                                onChange={(e: RadioEvent) => handleChangeVerkaufsprozessart(businessId, e.target.value as Verkaufsprozessart)}
                                ranorex-id={ABSCHLUSSWUNSCH_RADIOGROUP}
                            >
                                {createRadioButtonsFromWertebereichAngebot(werteVerkaufsprozessart, WertebereichVerkaufsprozessartenMapping)}
                            </RadioGroupRow>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: IAppState): AbschlusswunschProps => ({
    businessId: state.basisdaten.businessId,
    verkaufsprozessart: state.basisdaten.verkaufsprozessart,
    werteVerkaufsprozessart: state.basisdaten.werteVerkaufsprozessart,
    oberflaecheSperren: state.abschluss.abschlussErfolgreich
});

const mapDispatchToProps = (dispatch: Dispatch): AbschlusswunschDispatchProps =>
    bindActionCreators(
        {
            handleChangeVerkaufsprozessart: updateVerkaufsprozessartAsync
        },
        dispatch
    );

export const Abschlusswunsch = connect(mapStateToProps, mapDispatchToProps)(AbschlusswunschPresentation);
