import { WertebereichLokal } from '../util/WertebereicheHelper';
import { AbbuchungstagImMonat } from '../util/fetch/offerengine/OfferEngineAngebotDto';

export const erlaubteAbbuchungstage: WertebereichLokal[] = [
    {
        value: AbbuchungstagImMonat.TAG_1,
        name: '1. des Fälligkeitmonats'
    },
    {
        value: AbbuchungstagImMonat.TAG_16,
        name: '16. des Fälligkeitsmonats'
    }
];
